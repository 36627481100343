import { Drawer, ListSubheader } from '@mui/material'

import SideLoanManagementMenu from './SideLoanManagementMenu'
import SideTopMenu from './SideTopMenu'

type Props = {
  sideMenu: { loanCount: number; missedArrears: string; missedReaged: string }
}

const Home = ({ sideMenu }: Props): JSX.Element => {
  return (
    <>
      {/* Display sticky left menu  */}
      <Drawer variant="permanent" className={`MainApp-menuNavigationDrawerPaper`}>
        <SideTopMenu />
        {sideMenu.loanCount > 0 && (
          <>
            <ListSubheader className="App-listSubheaderGutters">LOAN MANAGEMENT</ListSubheader>
            <SideLoanManagementMenu sideMenu={sideMenu} />
          </>
        )}
      </Drawer>
    </>
  )
}

export default Home
