import { ThemeProvider } from '@mui/material/styles'
import theme from 'components/theme'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from 'view/portal/common/ScrollToTop'
import App from './App'

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </ThemeProvider>,
)
