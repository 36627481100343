import './_apiResponseWrapper.scss'

import * as paths from 'utils/Links'
import * as variable from 'utils/Variable'

import { Box, Button, CssBaseline, Divider, Grid, Typography } from '@mui/material'
import EmailIcon from 'assets/images/icons/email.svg'
import PhoneIcon from 'assets/images/icons/phone.svg'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import LayoutWrapper from '../../../public/layout/LayoutWrapper'

type Props = {
  children: React.ReactNode
  title: string
  text: string
  response: string
}

const UserResponseWrapper = ({ children, title, text, response }: Props): JSX.Element => {
  const navigate = useNavigate()

  const goToDashboard = () => {
    navigate(paths.getDashboard())
  }
  return (
    <LayoutWrapper>
      <CssBaseline />
      <Grid container className="api-response-container" data-test="api-response-container">
        <Grid item xs={12}>
          <Box mb={6} className="Card-shadow">
            <div className="Header-content" data-test="Header-content">
              {response === 'success' && (
                <span
                  className="lw-system-icons-checkmark icons-fs-48 icons-bg-colorAquamarineLight icons-colorAquamarineDark icons-bg-round icons-font-bold"
                  data-test="checkmark-icon"
                />
              )}
              {response === 'error' && (
                <span
                  className="lw-system-icons-close icons-fs-48 icons-bg-colorCrimsonLight icons-colorErrorDark icons-bg-round icons-font-bold"
                  data-test="checkmark-icon"
                />
              )}
              {response === 'warning' && (
                <span
                  className="lw-system-icons-alert icons-fs-48 icons-bg-colorAmberLight icons-colorAmberDark icons-bg-round icons-font-bold"
                  data-test="checkmark-icon"
                />
              )}
              <Typography variant="h1" data-test="Header-title">
                {title}
              </Typography>
              <Typography variant="body1" data-test="Header-text">
                {text}
              </Typography>
            </div>
            <Divider className="solid-divider" />
            <div className="content">{children}</div>
            {(response === 'error' || response === 'warning') && (
              <div className="contact-info-wrapper">
                <div className="contact-info" data-test="customer-service-number">
                  <img src={PhoneIcon} alt="" data-test="phone-icon" />
                  <a
                    href={`tel:${variable.customerServiceNumberCall}`}
                    title="Fluro contact number"
                    data-test="customer-service-number-cta"
                  >
                    {variable.customerServiceNumber}
                  </a>
                </div>
                <div className="contact-info" data-test="customer-service-email">
                  <img src={EmailIcon} alt="" data-test="email-icon" />
                  <a
                    href={`mailto:${variable.customerServiceEmail}`}
                    title="Fluro contact email"
                    data-test="customer-service-email-cta"
                  >
                    {variable.customerServiceEmail}
                  </a>
                </div>
                <div className="contact-times" data-test="customer-service-hours">
                  <Typography variant="body2">{variable.openingHours}</Typography>
                </div>
              </div>
            )}
            {response === 'success' && (
              <div className="cta-btn">
                <Button
                  className="continueButton"
                  variant="contained"
                  color="primary"
                  onClick={goToDashboard}
                  data-test="accept-repayment-button"
                >
                  Back to home
                </Button>
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
    </LayoutWrapper>
  )
}

export default UserResponseWrapper
