import React from 'react'
import UserAuthentication from '../components/userAuthentication/Home'

const ResetPassword = (): JSX.Element => {
  const ResetPasswordSuccessContentWrapper = () => {
    return (
      <>
        Didn’t get the email? Check your spam folder or <a href="/reset-password">resend</a>.
      </>
    )
  }

  return (
    <UserAuthentication
      title={'Reset your password'}
      message={
        'Enter the email address you used to create your Fluro account below and we will email you a password reset link.'
      }
      buttonText={'Send email'}
      confirmationTitle={'Thanks, a password reset email has been sent to your inbox.'}
      confirmationMessage={<ResetPasswordSuccessContentWrapper />}
      type={'ResetPassword'}
    />
  )
}

export default ResetPassword
