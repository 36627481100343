import './_home.scss'

import * as links from 'utils/Links'
import * as messages from 'utils/Messages'

import { Box, Button } from '@mui/material'
import { useCallback, useState } from 'react'

import FeedbackIndicatorsComponent from 'components/FeedbackIndicators/FeedbackIndicators'
import HelperContainer from '../../common/shared/HelperContainer'
import Loader from 'components/Loader/Loader'
import SignInAndCreateAccountForm from '../../common/shared/SignInAndCreateAccountForm'
import SignInAndCreateAccountHeader from '../../common/shared/SignInAndCreateAccountHeader'
import UseLoanApiRequest from '../../common/api/UseLoanApiRequest'
import useOktaRequest from '../../common/api/UseOktaRequest'

type LoginSignupProps = {
  acceptedTerms: boolean
  reference: string | undefined
  hasSubmittedLoanApplication: boolean | undefined
  email: string
  accountStatus: string | undefined
}

const LoginSignup = ({
  acceptedTerms,
  reference,
  hasSubmittedLoanApplication,
  email,
  accountStatus,
}: LoginSignupProps): JSX.Element => {
  const { oktaErrorMessages, postSignInWithCredentials } = useOktaRequest()

  const { postRegisterCustomerWithEmail, apiResponseSuccessMessages, apiResponseErrorMessage } = UseLoanApiRequest()

  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [newPassword, setNewPassword] = useState<string>('')
  const [passwordError, setPasswordError] = useState<boolean>(false)

  const newUser = accountStatus === 'CreatePassword'
  const userAccountLockedOut = accountStatus === 'LockedOut'
  const userPasswordExpired = accountStatus === 'ExpiredPassword'
  const userPasswordRecovery = accountStatus === 'Recovery'
  const accountStatusExpiredOrRecovery = userPasswordExpired || userPasswordRecovery

  const submittedApplication = hasSubmittedLoanApplication

  const redirectUrl = submittedApplication ? links.getDashboard() : links.getLoanApplicationForm(reference)

  const registeredUser = apiResponseSuccessMessages !== '' || false
  const unRegisteredUser = newUser && !registeredUser
  const newUserPasswordSuccess = apiResponseSuccessMessages && oktaErrorMessages === ''

  const callback = useCallback((password: string) => {
    setNewPassword(password)
  }, [])

  const submitExistingUser = useCallback(() => {
    setIsSubmitting(true)
    postSignInWithCredentials(email, newPassword, setIsSubmitting, redirectUrl)

    if (oktaErrorMessages) {
      setIsOpen(true)
    }
  }, [email, newPassword, oktaErrorMessages, postSignInWithCredentials, redirectUrl])

  const handleSubmitExistingUser = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    submitExistingUser()
  }

  const submitNewUser = useCallback(() => {
    setIsSubmitting(true)
    postRegisterCustomerWithEmail(email, newPassword, acceptedTerms, setIsSubmitting)

    if (oktaErrorMessages) {
      setIsOpen(true)
    }
  }, [acceptedTerms, email, newPassword, oktaErrorMessages, postRegisterCustomerWithEmail])

  const handleSubmitNewUser = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    submitNewUser()
  }

  const closeFeedbackIndicatorsComponent = () => {
    setIsOpen(false)
  }

  const OktaErrorMessages = () => {
    return (
      <FeedbackIndicatorsComponent
        className="feedback-indicator"
        severity={`${newUserPasswordSuccess ? 'success' : 'error'}`}
        icon={`${
          newUserPasswordSuccess ? 'lw-system-icons-checkmark icon-bg-success' : 'lw-system-icons-close icon-bg-error'
        }`}
        messages={oktaErrorMessages || apiResponseErrorMessage || apiResponseSuccessMessages}
        isOpen={isOpen}
        closeAction={true}
        parentCallback={closeFeedbackIndicatorsComponent}
      />
    )
  }

  return (
    <div className="loan-login-signup-container">
      <div
        className={`login-container ${unRegisteredUser ? 'margin-bottom24' : ''}`}
        data-test="loan-intro-login-container"
      >
        <SignInAndCreateAccountHeader
          unRegisteredUser={unRegisteredUser}
          newUser={newUser}
          userAccountLockedOut={userAccountLockedOut}
          accountStatusExpiredOrRecovery={accountStatusExpiredOrRecovery}
          step={0}
        />

        {(userAccountLockedOut || accountStatusExpiredOrRecovery) && (
          <Box display="flex">
            <Box className="paragraph-box">
              <FeedbackIndicatorsComponent
                className="feedback-indicator"
                severity="error"
                icon="lw-system-icons-close icon-bg-error"
                messages={
                  accountStatusExpiredOrRecovery ? messages.accountPasswordExpired : messages.accountTemporarilyLocked
                }
                isOpen={isOpen}
                closeAction={false}
              />
            </Box>
          </Box>
        )}

        {(oktaErrorMessages || apiResponseErrorMessage || apiResponseSuccessMessages) && isOpen && (
          <div className="okta-feedback">
            <OktaErrorMessages />
          </div>
        )}

        <SignInAndCreateAccountForm
          email={email}
          parentCallback={callback}
          newUser={newUser}
          helperText={`${unRegisteredUser ? "You'll need this to manage your loan" : ''}`}
          oktaErrorMessages={oktaErrorMessages}
          passwordError={passwordError}
          registeredUser={registeredUser}
          setPasswordError={setPasswordError}
          apiResponseSuccessMessages={apiResponseSuccessMessages}
        />

        {(!newUser || registeredUser) && (
          <>
            <div className="login-btn">
              <Button
                data-test="button-dialogs-submit"
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmitExistingUser}
                disabled={passwordError || isSubmitting || accountStatusExpiredOrRecovery}
              >
                {isSubmitting ? <Loader size="small" color="white" /> : 'Log in and continue'}
              </Button>
            </div>

            <div className="forgot-password-cta">
              <HelperContainer newUser={newUser} email={email} registeredUser={registeredUser} />
            </div>
          </>
        )}
      </div>

      {unRegisteredUser && (
        <div className="continue-btn">
          <Button
            data-test="loan-intro-continue"
            variant="contained"
            color="primary"
            onClick={handleSubmitNewUser}
            disabled={passwordError || isSubmitting}
          >
            {isSubmitting ? <Loader size="small" color="white" /> : 'Continue'}
          </Button>
          <div className="credit-score-container">
            <span className="lw-system-icons-credit-search icons-fs-16-normal icons-colorBlack icon-bold" />
            <span>This won't affect your credit score</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default LoginSignup
