import './_loanDetails.scss'

import * as features from 'utils/Features'

import { Box, Grid, Slider, TextField, Typography } from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'

import { List } from 'react-content-loader'
import { NumericFormat } from 'react-number-format'
import SelectReferenceData from 'components/Select/Baseline/SelectReferenceData'
import TextfieldCurrencyBaseline from 'components/Textfield/Baseline/TextfieldCurrencyBaseline'
import useQuoteApiRequest from '../../common/api/UseQuoteApiRequest'

interface LoanDetailsProps {
  loanDetailsError: { loanAmount: boolean; loanDuration: boolean; loanPurpose: boolean; purposeReason: boolean }
  parentCallbackLoanDetails: (
    loanAmount: number,
    loanDuration: string,
    loanPurpose: string,
    purposeReason: string,
  ) => void
}

const ChooseLoan = (props: LoanDetailsProps): JSX.Element => {
  const minValue = 1000
  const maxValue = 25000
  const minMonth = 12
  const maxMonth = 60

  const [sliderAmountValue, setSliderAmountValue] = useState<number>(10000)
  const [sliderDurationValue, setSliderDurationValue] = useState<number>(60)
  const [inputAmountValue, setInputAmountValue] = useState<number>(10000)
  const [purposeSelectValue, setPurposeSelectValue] = useState<string>('')
  const [purposeInputValue, setPurposeInputValue] = useState<string>('')
  const [inputAmountError, setInputAmountError] = useState<boolean>(false)
  const [purposeSelectError, setPurposeSelectError] = useState<boolean>(false)
  const [purposeInputError, setPurposeInputError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isHasLoaded, setHasLoaded] = useState<boolean>(false)

  const { loanDetailsError, parentCallbackLoanDetails } = props

  const { loanPurposeDetails, fetchLoanPurposeDetails } = useQuoteApiRequest()

  const handleSliderAmountChange = (event: Event, newValue: number | number[]) => {
    setSliderAmountValue(newValue as number)
    setInputAmountValue(newValue as number)
  }
  const handleSliderDurationChange = (event: Event, newValue: number | number[]) => {
    setSliderDurationValue(newValue as number)
    passValueToParent('loanDuration', newValue.toString())
  }
  const setMinAmount = () => {
    setSliderAmountValue(1000)
    setInputAmountValue(1000)
    passValueToParent('loanAmount', '1000')
  }
  const setMaxAmount = () => {
    setSliderAmountValue(25000)
    setInputAmountValue(25000)
    passValueToParent('loanAmount', '25000')
  }
  const handleAmountChange = (value: string) => {
    setSliderAmountValue(Number(value))
    setInputAmountValue(Number(value))
    setInputAmountError(false)
  }
  const inputAmountValidation = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = e.target.value

    if (input !== '' && Number(input.replace(/,/g, '')) < 1000) {
      setMinAmount()
    } else if (input !== '' && Number(input.replace(/,/g, '')) > 25000) {
      setMaxAmount()
    } else {
      updateLoanAmountInParent(Number(input))
    }
  }
  const sliderAmountDecrease = () => {
    if (sliderAmountValue >= 1100) {
      const newInputAmount = Number(inputAmountValue) - 100
      setSliderAmountValue((state) => state - 100)
      setInputAmountValue(newInputAmount)
      updateLoanAmountInParent(newInputAmount - 100)
    }
  }
  const sliderAmountIncrease = () => {
    if (sliderAmountValue === 0) {
      const newInputAmount = Number(inputAmountValue) + 1000
      setSliderAmountValue((state) => state + 1000)
      setInputAmountValue(newInputAmount)
      updateLoanAmountInParent(newInputAmount)
    }
    if (sliderAmountValue >= 1000 && sliderAmountValue < 25000) {
      const newInputAmount = Number(inputAmountValue) + 100
      setSliderAmountValue((state) => state + 100)
      setInputAmountValue(newInputAmount)
      updateLoanAmountInParent(newInputAmount)
    }
  }
  const sliderDurationDecrease = () => {
    if (sliderDurationValue > 12) {
      const newSliderDuration = Number(sliderDurationValue) - 1
      setSliderDurationValue((state) => state - 1)
      passValueToParent('loanDuration', newSliderDuration.toString())
    }
  }
  const sliderDurationIncrease = () => {
    if (sliderDurationValue < 60) {
      const newSliderDuration = Number(sliderDurationValue) + 1
      setSliderDurationValue((state) => state + 1)
      passValueToParent('loanDuration', newSliderDuration.toString())
    }
  }
  const handleLoanPurposeOption = (value: string) => {
    setPurposeSelectValue(value)
    passValueToParent('loanPurpose', value)
    setPurposeSelectError(false)
  }
  const handlePurposeReason = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = e.target.value
    setPurposeInputValue(input)
    passValueToParent('purposeReason', input)

    if (purposeSelectValue.toLowerCase() === 'other' && input === '') {
      setPurposeInputError(true)
    } else {
      setPurposeInputError(false)
    }
  }

  const updateLoanAmountInParent = (amount: number) => {
    if (amount >= 1000 && amount <= 25000) {
      passValueToParent('loanAmount', amount.toString())
    }
  }

  const passValueToParent = useCallback(
    (key: string, value: string) => {
      parentCallbackLoanDetails(
        key === 'loanAmount' ? Number(value) : inputAmountValue,
        key === 'loanDuration' ? value : sliderDurationValue.toString(),
        key === 'loanPurpose' ? value : purposeSelectValue,
        key === 'purposeReason' ? value : purposeInputValue,
      )
    },
    [inputAmountValue, sliderDurationValue, purposeSelectValue, purposeInputValue, parentCallbackLoanDetails],
  )

  useEffect(() => {
    if (!isHasLoaded) {
      fetchLoanPurposeDetails(setIsLoading)
      setHasLoaded(true)
    }
  }, [isHasLoaded, fetchLoanPurposeDetails])

  useEffect(() => {
    setInputAmountError(loanDetailsError.loanAmount)
    setPurposeSelectError(loanDetailsError.loanPurpose)
    setPurposeInputError(loanDetailsError.purposeReason)
  }, [loanDetailsError])

  if (isLoading) {
    return (
      <Box mb={3} className="Card-shadow loan-details">
        <List />
      </Box>
    )
  }

  return (
    <Box className="Card-shadow loan-details">
      <Grid item xs={12} className="Account-content line-separate" data-test="loan-details-container">
        <Box display="flex" data-test="loan-details-title">
          <Box className="login-cta">
            <Typography variant="h4">1. Loan details</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} flexDirection="column" className="quote-amount" data-test="quote-amount">
        <Box display="flex" mb={3} alignItems="center" flex="1 1 100%">
          <Box className="quote-text" data-test="input-label">
            <span>I want a loan for</span>
          </Box>
          <Box className="quote-input" data-test="amount-input">
            <NumericFormat
              id="loan-purpose-amount"
              decimalScale={2}
              thousandSeparator={','}
              decimalSeparator={'.'}
              customInput={TextfieldCurrencyBaseline}
              value={inputAmountValue}
              onValueChange={(values) => handleAmountChange(values.value)}
              onBlur={inputAmountValidation}
              error={inputAmountError}
              helperText={inputAmountError ? 'Add an amount' : null}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" flex="1 1 100%">
          <Box className="slider">
            <div className="slider-control amount minus-btn">
              <div data-test="amount-decrease-btn" onClick={sliderAmountDecrease}>
                <span
                  className={`lw-system-icons-substract icon-bold ${sliderAmountValue === minValue ? 'disabled' : ''}`}
                />
              </div>
              <span className="label" data-test="min-amount-label">
                {features.currencyFormat(Number(1000), 0)}
              </span>
            </div>
            <Slider
              aria-label="loan-amount"
              step={100}
              min={minValue}
              max={maxValue}
              value={sliderAmountValue}
              onChange={handleSliderAmountChange}
              data-test="amount-slider"
            />
            <div className="slider-control amount plus-btn">
              <div data-test="amount-increase-btn" onClick={sliderAmountIncrease}>
                <span className={`lw-system-icons-add icon-bold ${sliderAmountValue === maxValue ? 'disabled' : ''}`} />
              </div>
              <span className="label" data-test="max-amount-label">
                {features.currencyFormat(Number(25000), 0)}
              </span>
            </div>
          </Box>
        </Box>
        <Box display="flex" mt={4} mb={3} alignItems="center" flex="1 1 100%" data-test="amount-duration-label">
          <Box className="quote-text">
            <span>And for how long?</span>
          </Box>
          <Box className="quote-input duration" data-test="duration-text">
            <span>
              {sliderDurationValue} <small>months</small>
            </span>
          </Box>
        </Box>
        <Box display="flex" mb={3} alignItems="center" flex="1 1 100%">
          <Box className="slider">
            <div className="slider-control duration minus-btn">
              <div onClick={sliderDurationDecrease} data-test="duration-decrease-btn">
                <span
                  className={`lw-system-icons-substract icon-bold ${
                    sliderDurationValue === minMonth ? 'disabled' : ''
                  }`}
                />
              </div>
              <span className="label" data-test="min-duration-label">
                12 months
              </span>
            </div>
            <Slider
              aria-label="loan-duration"
              step={1}
              min={minMonth}
              max={maxMonth}
              value={sliderDurationValue}
              onChange={handleSliderDurationChange}
              data-test="duration-slider"
            />
            <div className="slider-control duration plus-btn">
              <div onClick={sliderDurationIncrease} data-test="duration-increase-btn">
                <span
                  className={`lw-system-icons-add icon-bold ${sliderDurationValue === maxMonth ? 'disabled' : ''}`}
                />
              </div>
              <span className="label" data-test="max-duration-label">
                60 months
              </span>
            </div>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" flex="1 1 100%">
          <Box className="loan-purpose">
            <SelectReferenceData
              dataTest="purpose-select"
              variant="filled"
              onChange={handleLoanPurposeOption}
              value={purposeSelectValue}
              options={loanPurposeDetails}
              error={purposeSelectError}
              label="Loan purpose"
              helperText={purposeSelectError ? 'Please select a reason' : ''}
            />
          </Box>
        </Box>
        {purposeSelectValue.toLowerCase() === 'other' && (
          <Box display="flex" mt={2} alignItems="center" flex="1 1 100%">
            <Box className="loan-purpose" data-test="loan-purpose">
              <TextField
                multiline
                fullWidth
                rows={3}
                id="email"
                className="Textfield-formControl"
                variant="outlined"
                autoComplete="off"
                type="text"
                value={purposeInputValue}
                label="Please describe"
                data-test="purpose-input"
                error={purposeInputError}
                helperText={purposeInputError ? 'Please add a valid reason' : null}
                onChange={handlePurposeReason}
              />
            </Box>
          </Box>
        )}
      </Grid>
    </Box>
  )
}

export default ChooseLoan
