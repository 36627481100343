import './_addressPicker.scss'

import * as features from 'utils/Features'
import * as messages from 'utils/Messages'

import { Box, Button, TextField } from '@mui/material'
import { ChangeEvent, Dispatch, FormEvent, SetStateAction, useCallback, useEffect, useState } from 'react'

import Loader from 'components/Loader/Loader'
import SelectReferenceData from 'components/Select/Baseline/SelectReferenceData'
import useApiRequest from './UseApiRequest'
import FeedbackIndicatorsComponent from 'components/FeedbackIndicators/FeedbackIndicators'

export interface AddressPickerProps {
  address: string
  addressSetter: Dispatch<SetStateAction<string>>
  previousMonthsOfAddressHistory: number
  monthsOfAddressHistorySetter: Dispatch<SetStateAction<number>>
  parentCallbackUserAddressError: (
    firstAddress: boolean,
    monthsAtFirstAddress: boolean,
    firstAddressMoveInDate: boolean,
    firstAddressPostcode: boolean,
    residentialStatus: boolean,
  ) => void
  addressError: {
    firstAddress: boolean
    monthsAtFirstAddress: boolean
    firstAddressMoveInDate: boolean
    firstAddressPostcode: boolean
    residentialStatus: boolean
  }
  moveInDateCheck: (value: string) => void
  postcodeSetter: (value: string) => void
}

const AddressPicker = (props: AddressPickerProps): JSX.Element => {
  const [postcode, setPostcode] = useState<string>('')
  const [postcodeError, setPostcodeError] = useState<boolean>(false)
  const [postcodeLookupError, setPostcodeLookupError] = useState<boolean>(false)
  const [moveInDateError, setMoveInDateError] = useState<boolean>(false)
  const [postcodeLookupLoading, setPostcodeLookupLoading] = useState<boolean>(false)
  const [selectAddressError, setSelectAddressError] = useState<boolean>(false)
  const [moveInDate, setMoveInDate] = useState<string>('')
  const blockedPostcodes = ['GX11', 'JE', 'GY', 'IM']

  const {
    address,
    addressSetter,
    previousMonthsOfAddressHistory,
    monthsOfAddressHistorySetter,
    addressError,
    moveInDateCheck,
    postcodeSetter,
    parentCallbackUserAddressError,
  } = props

  const { addressList, fetchAddressList } = useApiRequest()

  function isBlockedPostcode() {
    return blockedPostcodes.some((prefix) => postcode.toLowerCase().startsWith(prefix.toLowerCase()))
  }

  const postcodeLookup = () => {
    if (postcode === '') {
      setPostcodeError(true)
    } else if (isBlockedPostcode()) {
      setPostcodeError(true)
    } else if (!postcodeError) {
      setPostcodeError(false)
      fetchAddressList(postcode, setPostcodeLookupLoading, setPostcodeLookupError)
      passValueToParent('firstAddressPostcode', false)
    } else {
      addressSetter('')
      monthsOfAddressHistorySetter(-1)
    }
  }

  const handleMoveInDate = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = e.target.value
    setMoveInDate(input)
    moveInDateCheck(input)
    setMoveInDateError(false)
    if (isValidDateString(e.target.value)) {
      const months = monthDiff(parseDate(e.target.value), new Date())
      // sentinel value for first form
      if (previousMonthsOfAddressHistory !== -1) {
        if (months <= previousMonthsOfAddressHistory) {
          setMoveInDateError(true)
        } else {
          monthsOfAddressHistorySetter(months - previousMonthsOfAddressHistory)
        }
      } else {
        monthsOfAddressHistorySetter(months)
      }
    }
  }

  const handleAddressPicker = (newValue: string) => {
    setSelectAddressError(false)
    if (!postcodeError) {
      addressSetter(newValue)
    }
    if (!newValue) {
      setSelectAddressError(true)
    }
  }

  const parseDate = (date: string): Date => {
    const [month, year] = date.split('/')
    return new Date(Number(year), Number(month) - 1)
  }

  const isValidDateString = (dateString: string): boolean => {
    const [month, year] = dateString.split('/')
    const numericMonth = Number(month)
    const numericYear = Number(year)
    const now = new Date()
    return (
      /^\d{2}\s\/\s\d{4}$/.test(dateString) &&
      numericYear <= now.getFullYear() &&
      numericMonth > 0 &&
      numericMonth <= 12 &&
      (numericMonth - 1 <= now.getMonth() || numericYear < now.getFullYear())
    )
  }

  const monthDiff = (dateFrom: Date, dateTo: Date): number => {
    return Math.max(dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear()), 0)
  }

  const validateMoveInDate = () => {
    if (moveInDate.length > 2 && !isValidDateString(moveInDate)) {
      setMoveInDateError(true)
    } else {
      setMoveInDateError(false)
    }
  }

  const handleMoveInDateFormat = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key

    if (key !== 'Backspace' && key !== 'Delete' && moveInDate.length === 2) {
      setMoveInDate(`${moveInDate} / `)
      moveInDateCheck(`${moveInDate} / `)
    } else if (moveInDate.length === 3) {
      setMoveInDate(`${moveInDate.slice(0, -1)} / ${key}`)
    }
  }

  const validatePostcode = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = e.target.value
    const passesRegex = features.checkPostcode(input)

    if (!passesRegex) {
      setPostcodeError(true)
      addressSetter('')
      monthsOfAddressHistorySetter(-1)
    } else {
      postcodeSetter(input)
      setPostcode(input)
      setPostcodeError(false)
      passValueToParent('firstAddressPostcode', false)
    }
  }

  const checkPostcode = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = e.target.value
    setPostcode(input)
    setPostcodeError(false)
    passValueToParent('firstAddressPostcode', false)
  }

  const handleKeyPostcode = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement
    const key = e.key
    postcodeSetter(input.value)
    setPostcode(input.value)

    if (key === 'Enter') {
      if (features.checkPostcode(input.value)) {
        setPostcodeError(false)
        passValueToParent('firstAddressPostcode', false)
        postcodeLookup()
      } else {
        setPostcodeError(true)
      }
    }
  }

  const allowNumbersInput = (e: FormEvent<HTMLInputElement>) => {
    return ((e.target as HTMLInputElement).value = features.validateNumbersInput((e.target as HTMLInputElement).value))
  }

  const passValueToParent = useCallback(
    (key: string, value: boolean) => {
      parentCallbackUserAddressError(
        key === 'firstAddress' ? value : selectAddressError,
        key === 'monthsAtFirstAddress' ? value : false,
        key === 'firstAddressMoveInDate' ? value : moveInDateError,
        key === 'firstAddressPostcode' ? value : postcodeError,
        key === 'residentialStatus' ? value : false,
      )
    },
    [selectAddressError, moveInDateError, postcodeError, parentCallbackUserAddressError],
  )

  useEffect(() => {
    setPostcodeError(addressError.firstAddressPostcode)
    setMoveInDateError(addressError.firstAddressMoveInDate)
    setSelectAddressError(addressError.firstAddress)
  }, [addressError])

  return (
    <Box className="address-picker">
      {postcodeLookupError && (
        <Box className="lookup-error-container" mt={3} mb={2}>
          <FeedbackIndicatorsComponent
            messages="We were unable to load addresses for the supplied postcode. Please try again."
            className="feedback-indicator"
            severity="error"
            icon="lw-system-icons-close icon-bg-error"
            closeAction={false}
            isOpen={postcodeLookupError}
          ></FeedbackIndicatorsComponent>
        </Box>
      )}
      <Box className="postcode-selection">
        <Box mb={2} className="postcode-input-container">
          <TextField
            fullWidth
            value={postcode}
            onChange={checkPostcode}
            onBlur={validatePostcode}
            onKeyUp={handleKeyPostcode}
            data-test="postcode-input"
            label="Postcode"
            error={postcodeError}
            helperText={postcodeError ? 'Please enter a valid UK postcode' : null}
          />
        </Box>
        <Box mb={2} className="postcode-button-container">
          <Button
            className="postcode-button"
            variant="outlined"
            color="inherit"
            size="small"
            data-test="postcode-button"
            onClick={postcodeLookup}
          >
            {postcodeLookupLoading ? <Loader size="x-small" color="black" /> : 'Find address'}
          </Button>
        </Box>
      </Box>
      <Box className="further-info-container">
        {addressList.length > 0 && (
          <Box mb={2} data-test="address-picker-container">
            <SelectReferenceData
              dataTest="address-picker"
              variant="filled"
              onChange={handleAddressPicker}
              options={addressList}
              value={address}
              label="Select address"
              error={selectAddressError}
              helperText={selectAddressError ? messages.selectAddress : ''}
            />
          </Box>
        )}
        {address !== '' && (
          <Box data-test="first-address-details">
            <Box mb={2}>
              <TextField
                data-test="move-in-date"
                value={moveInDate}
                label="When did you move in?"
                placeholder="MM / YYYY"
                fullWidth
                onChange={handleMoveInDate}
                onInput={allowNumbersInput}
                onKeyUp={handleMoveInDateFormat}
                onBlur={validateMoveInDate}
                inputProps={{ maxLength: 9 }}
                helperText={moveInDateError ? messages.dateMovedInAddress : 'E.g. 01 / 2022'}
                error={moveInDateError}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default AddressPicker
