import { borrowerApiClient } from '../../../../../api/BorrowerApiClient'
import { logError } from '../../../../../utils/Logging'

interface RequestPhoneCallProps {
  requestOpenBankingCall: (reference: string) => void
}

const MandatoryOpenBankingApiRequest = (): RequestPhoneCallProps => {
  const borrowerApi = borrowerApiClient()

  const alreadyEmailedPrefix = 'openBankingCallback'

  const requestOpenBankingCallback = (reference: string) => {
    if (sessionStorage.getItem(alreadyEmailedPrefix + reference) === 'true') {
      return
    }
    borrowerApi.postOpenBankingPhoneCallRequest({ reference: reference }).catch((error) => {
      logError('Failed to request open banking callback', error)
    })
    sessionStorage.setItem(alreadyEmailedPrefix + reference, 'true')
  }

  return { requestOpenBankingCall: requestOpenBankingCallback }
}

export default MandatoryOpenBankingApiRequest
