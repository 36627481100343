import './_linkInvalid.scss'

import * as links from 'utils/Links'
import * as variable from 'utils/Variable'

import { Box, Typography } from '@mui/material'

const LinkInvalid = (): JSX.Element => {
  return (
    <div className="invalid-url-container" data-test="invalid-url-container">
      <div className="container-top-section">
        <Box display="flex">
          <Box className="error-icon">
            <span className="lw-system-icons-close icons-fs-55 icons-colorError" data-test="close-icon" />
          </Box>
        </Box>
        <Box display="flex">
          <Box className="error-title" data-test="error-title">
            <Typography variant="h3" component="h3">
              Oops! This link is not valid.
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Box className="error-paragraph" data-test="error-paragraph">
            <p>
              Please double check the link and try again <a href={links.getLoanQuote()}>or get another quote</a>.
            </p>
          </Box>
        </Box>
      </div>
      <hr className="DottedLine" />
      <div className="container-bottom-section">
        <Box display="flex">
          <Box className="contact-paragraph">
            <p>If you have further issues, please don’t hesitate to contact our team.</p>
            <p data-test="customer-service-number">
              Tel: <a href={`tel: ${variable.customerServiceNumberCall}`}>{variable.customerServiceNumber}</a>
            </p>
            <p data-test="customer-service-email">
              Email: <a href={`mailto: ${variable.customerServiceEmail}`}>{variable.customerServiceEmail}</a>
            </p>
          </Box>
        </Box>
      </div>
    </div>
  )
}

export default LinkInvalid
