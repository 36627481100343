import './_timeout.scss'

import * as paths from 'utils/Links'

import { Box, Button, CssBaseline, Divider, Grid, Typography } from '@mui/material'

import { useNavigate } from 'react-router-dom'
import LayoutWrapper from '../../../public/layout/LayoutWrapper'

const Timeout = (): JSX.Element => {
  const navigate = useNavigate()

  const dashboard = () => {
    navigate(paths.getDashboard())
  }

  return (
    <LayoutWrapper className="PublicLoanContainer-timeout-container">
      <CssBaseline />
      <Grid container className="timeout-container" data-test="timeout-container">
        <Grid item xs={12}>
          <Box mb={6} className="Card-shadow Headers lines">
            <Typography variant="h3" className="Header-content top-shadow" data-test="timeout-title">
              Oops! We couldn't reach a decision.
            </Typography>

            <Divider className="dashed-divider" />

            <Box className="reasons" mt={3} mb={3}>
              <Typography data-test="timeout-paragraph">
                There was a problem with our system while processing your application and we couldn't reach a decision.
                Please try to apply again later by going to your dashboard.
              </Typography>

              <Typography className="goto-dashboard-btn">
                <Button data-test="error-goto-dashboard-btn" variant="contained" color="primary" onClick={dashboard}>
                  Go to dashboard
                </Button>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </LayoutWrapper>
  )
}

export default Timeout
