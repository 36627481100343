import './_continueButton.scss'

import * as links from 'utils/Links'

import { Box, Button } from '@mui/material'

import Loader from 'components/Loader/Loader'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  reference: string | undefined
}

const Continue = ({ reference }: Props): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleSubmitNewUser = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    setIsSubmitting(true)

    navigate(links.getLoanApplicationForm(reference))
  }

  return (
    <div className="continue-component">
      <p>
        If you wish to change your loan amount or term, please get another{' '}
        <a className="text-colorPrimary" href={links.getLoanQuote()}>
          loan quote
        </a>
        .
      </p>
      <div className="continue-btn">
        <Button data-test="loan-intro-continue" variant="contained" color="primary" onClick={handleSubmitNewUser}>
          {isSubmitting ? <Loader size="small" color="white" /> : 'Continue'}
        </Button>
        <Box mt={2} className="credit-score-container">
          <span className="lw-system-icons-credit-search icons-fs-16-normal icons-colorBlack icon-bold" />
          <span>This won't affect your credit score</span>
        </Box>
      </div>
    </div>
  )
}

export default Continue
