import '../../partnerIntroduction/_partnerIntroduction.scss'

import * as links from 'utils/Links'

import { MouseEvent, useState } from 'react'

import ForgotYourPassword from 'components/Modals/ForgotYourPassword'

type Props = {
  newUser: boolean
  registeredUser?: boolean
  email: string
}

const HelperContainer = ({ newUser, email, registeredUser }: Props): JSX.Element => {
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const handleDialogOpen = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setOpenDialog(true)
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  return (
    <>
      {newUser && !registeredUser ? (
        <div className="Helpertext" data-test="terms-conditions">
          As a reminder, here is a link to our{' '}
          <a
            href={links.termsAndConditions}
            className="web-link text-colorPrimary"
            target="_blank"
            rel="noreferrer"
            data-test="terms-conditions-cta"
          >
            Borrower Platform Terms and Conditions
          </a>{' '}
          that you signed up to when you applied for your loan.
        </div>
      ) : (
        <>
          <a
            href="/#"
            onClick={handleDialogOpen}
            className="Links-forgotPassword text-colorPrimary"
            data-test="forgot-passowrd-cta"
          >
            Forgot your password?
          </a>
          {openDialog && <ForgotYourPassword open={openDialog} email={email} handleDialogClose={handleDialogClose} />}
        </>
      )}
    </>
  )
}

export default HelperContainer
