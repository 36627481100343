import './_sideMenu.scss'

import { List } from '@mui/material'
import { sideLoanManagementMenuItems } from 'utils/LinksMenu'
import SideMenuItem from './SideMenuItem'

const SideLoanManagementMenu = (props: SideMenuProp): JSX.Element => {
  return (
    <List component="nav" disablePadding>
      {sideLoanManagementMenuItems.map((item, index) => (
        <SideMenuItem {...item} key={index} {...props} />
      ))}
    </List>
  )
}

export interface SideMenuProp {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  sideMenu?: { loanCount: number; missedArrears: string; missedReaged: string }
}
export default SideLoanManagementMenu
