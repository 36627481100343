/* eslint-disable @typescript-eslint/no-explicit-any */
import * as messages from 'utils/Messages'

import { SetStateAction, useState } from 'react'

interface T {
  data: {
    stateToken: SetStateAction<string>
    _embedded: { user: { profile: { login: SetStateAction<string> } } }
  }
  status: string
  sessionToken: string
}
interface UseOktaRequestProps {
  email: string
  resetPassword: {
    successMessages: boolean
    email: string
  }
  errorMessages: string
  oktaErrorMessages: string
  fetchValidatesRecoveryToken: (
    token: string,
    authClient: {
      verifyRecoveryToken: (arg0: { recoveryToken: string }) => Promise<any>
      session: { setCookieAndRedirect: (arg0: string) => void }
    },
    setLoading: (loading: boolean) => void,
  ) => void
  postResetPassword: (
    token: string,
    newPasswordVal: string,
    authClient: { verifyRecoveryToken: (arg0: { recoveryToken: string }) => Promise<any> },
    setIsSubmitting: (loading: boolean) => void,
  ) => void
  updateStateSetOktaErrorMessages: () => void
}

const useOktaRequest = (): UseOktaRequestProps => {
  const [email, setEmail] = useState('')
  const [errorMessages, setErrorMessages] = useState('')
  const [resetPassword, setResetPassword] = useState({
    successMessages: false,
    email: '',
  })
  const [oktaErrorMessages, setOktaErrorMessages] = useState('')

  const fetchValidatesRecoveryToken = (
    token: string,
    authClient: {
      verifyRecoveryToken: (arg0: { recoveryToken: string }) => Promise<T>
      session: { setCookieAndRedirect: (arg0: string) => void }
    },
    setLoading: (loading: boolean) => void,
  ) => {
    authClient
      .verifyRecoveryToken({
        recoveryToken: token,
      })
      .then(function (transaction) {
        setEmail(transaction.data._embedded.user.profile.login)
        if (transaction.status === 'SUCCESS') {
          authClient.session.setCookieAndRedirect(transaction.sessionToken)
        } else {
          throw Error(`We cannot handle the ${transaction.status} status`)
        }
      })
      .catch(function (error) {
        setErrorMessages(error.errorSummary)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const postResetPassword = (
    token: string,
    newPasswordVal: string,
    authClient: { verifyRecoveryToken: (arg0: { recoveryToken: string }) => Promise<any> },
    setIsSubmitting: (loading: boolean) => void,
  ) => {
    authClient
      .verifyRecoveryToken({
        recoveryToken: token,
      })
      .then(function (transaction: {
        status: string
        resetPassword: (arg0: { newPassword: string }) => Promise<any>
        data: { _embedded: { user: { profile: { login: string } } } }
      }) {
        if (transaction.status === 'PASSWORD_RESET') {
          transaction
            .resetPassword({
              newPassword: newPasswordVal,
            })
            .then(() => {
              setResetPassword({
                email: transaction.data._embedded.user.profile.login,
                successMessages: true,
              })
            })
            .catch((error: { errorCauses: { errorSummary: SetStateAction<string> }[] }) => {
              if (error.errorCauses[0] && error.errorCauses[0].errorSummary) {
                setOktaErrorMessages(error.errorCauses[0].errorSummary)
              }
            })
            .finally(() => {
              setIsSubmitting(false)
            })
        }
      })
      .catch(function (error: { response: { data: string } }) {
        console.error(error)
        if (error.response && error.response.data) {
          setOktaErrorMessages(messages.passwordNotMeetRequirement)
        }
      })
  }

  const updateStateSetOktaErrorMessages = () => {
    setOktaErrorMessages('')
  }

  return {
    email,
    resetPassword,
    errorMessages,
    oktaErrorMessages,
    fetchValidatesRecoveryToken,
    postResetPassword,
    updateStateSetOktaErrorMessages,
  }
}

export default useOktaRequest
