import { useRef, useState } from 'react'

import { ListSubheader } from '@mui/material'
import SideLoanManagementMenu from '../sideMenu/SideLoanManagementMenu'
import SideTopMenu from '../sideMenu/SideTopMenu'
import { StyledMenu } from './AppMenuMobile.styled'
import Hamburger from './Hamburger'

type Props = {
  sideMenu: { loanCount: number; missedArrears: string; missedReaged: string }
}

const AppMenuMobile = ({ sideMenu }: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  const node = useRef<HTMLDivElement>(null)

  const close = () => {
    setOpen(false)
  }

  return (
    <div className="MobileAppMenu" ref={node} data-test="mobile-app-menu">
      <div className={`backdrop ${open && 'backdrop-active'}`} onClick={() => close()}></div>

      <StyledMenu open={open}>
        <div className="mobileTopMenu">Close button</div>
        <div onClick={() => close()}>
          <SideTopMenu />
        </div>

        {sideMenu.loanCount > 0 && (
          <>
            <ListSubheader className="App-listSubheaderGutters">LOAN MANAGEMENT</ListSubheader>
            <div onClick={() => close()}>
              <SideLoanManagementMenu sideMenu={sideMenu} />
            </div>
          </>
        )}
      </StyledMenu>
      <Hamburger open={open} setOpen={setOpen} />
    </div>
  )
}

export default AppMenuMobile
