import * as config from 'api/config'
import * as loanStatus from 'utils/LoanStatus'

import { AccessToken, OktaAuth } from '@okta/okta-auth-js'
import { AxiosRequestConfig, ResponseType } from 'axios'

import { BorrowerApiContract } from './BorrowerApiContract'
import { Loan } from './BorrowerApiSchemas'
import { ErrorResponse } from './ErrorResponse'
import { oktaWrapper } from './OktaConfig'
import * as paths from '../utils/Links'

export abstract class BorrowerApi extends BorrowerApiContract {
  abstract fetchMultiple<T>(urls: string[]): Promise<T[]>

  getLoans(): Promise<Loan[]> {
    return this.getLoanSummaryList()
      .then((res) => {
        const urls = res.map((l) => `/loans/${l.reference}`)
        return this.fetchMultiple<Loan>(urls)
      })
      .then((loans) => {
        // Display order: Active loan most recent, Active loan oldest, Written-off
        const priority = loanStatus.PriorityLoanLists
        loans = loans.sort((a, b) => {
          return priority.indexOf(a.plan.status) - priority.indexOf(b.plan.status)
        })

        return loans.sort((a, b) => {
          return a.plan.endDate > b.plan.endDate ? -1 : 1
        })
      })
  }
}

class AxiosBorrowerApi extends BorrowerApi {
  private accessToken: () => Promise<string>

  constructor(tokenManager: () => Promise<string>) {
    super()
    this.accessToken = tokenManager
  }

  fetchObject<T>(url: string, responseType: ResponseType, searchParams: URLSearchParams | null = null): Promise<T> {
    return this.requestConfig(undefined, responseType, searchParams).then((requestConfig) =>
      config.httpProxy
        .get(url, requestConfig)
        .then((res) => {
          return res.data as T
        })
        .catch((error) => {
          if (error.response !== undefined) {
            return Promise.reject(new ErrorResponse(error.response.status, error.response.data))
          } else {
            return Promise.reject(error)
          }
        }),
    )
  }

  postObject<T>(url: string, body: unknown, contentType: string): Promise<T> {
    return this.requestConfig(contentType, 'json')
      .then((requestConfig) =>
        config.httpProxy.post(url, body, requestConfig).then((res) => {
          return res.data as T
        }),
      )
      .catch((error) => {
        return Promise.reject(new ErrorResponse(error.response.status, error.response.data))
      })
  }

  fetchMultiple<T>(urls: string[]): Promise<T[]> {
    return this.requestConfig(undefined, 'json').then((requestConfig) =>
      Promise.all(
        urls.map((url) => {
          return config.httpProxy
            .get(url, requestConfig)
            .then((res) => {
              return res.data as T
            })
            .catch((error) => {
              return Promise.reject(new ErrorResponse(error.response.status, error.response.data))
            })
        }),
      ),
    )
  }

  private requestConfig(
    contentType = 'application/json',
    responseType: ResponseType,
    searchParams: URLSearchParams | null = null,
  ): Promise<AxiosRequestConfig> {
    return this.accessToken().then((token) => {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': contentType,
        },
        responseType: responseType,
        params: filterEmptyParams(searchParams),
      }
    })
  }
}

export function filterEmptyParams(searchParams: URLSearchParams | null) {
  if (searchParams === null) {
    return null
  }
  const keysForDel: string[] = []
  searchParams.forEach((value, key) => {
    if (value === '') {
      keysForDel.push(key)
    }
  })

  keysForDel.forEach((key) => {
    searchParams.delete(key)
  })
  return searchParams
}

export function borrowerApiClient(oktaAuth: OktaAuth = oktaWrapper.useOktaAuth().oktaAuth): BorrowerApi {
  function getOrRefreshToken(): Promise<string> {
    return (oktaAuth.tokenManager.get('accessToken') as Promise<AccessToken>).then((token) => {
      if (token && oktaAuth.tokenManager.hasExpired(token)) {
        return oktaAuth.tokenManager.renew('accessToken').then((token) => (token as AccessToken).accessToken)
      } else {
        return Promise.resolve(token.accessToken)
      }
    })
  }

  if (oktaAuth.getAccessToken()) {
    return new AxiosBorrowerApi(getOrRefreshToken)
  } else {
    // redirect page to sign in if dont have valid token
    // Setting a referrer path of /static causes a (nearly) infinite loop
    if (window.location.pathname.includes('static')) {
      localStorage.setItem('referrerPath', paths.getSignIn())
    } else {
      localStorage.setItem('referrerPath', window.location.pathname)
    }
    throw new Error('Unauthorized. authState: ' + JSON.stringify(oktaAuth.authStateManager.getAuthState()))
  }
}
