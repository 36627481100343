interface ErrorData {
  message: string
}

export class ErrorResponse {
  private code: number
  private data: ErrorData

  constructor(code: number, data: ErrorData) {
    this.code = code
    this.data = data
  }
}
