import './_loanApproval.scss'

import * as features from 'utils/Features'
import * as paths from 'utils/Links'

import { Box, Button, CssBaseline, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import PartnersLogo from 'components/LogoPartners/LogoPartners'
import Stepper from 'components/Stepper/Stepper'
import TooltipComponent from 'components/Tooltip/Tooltip'
import { List } from 'react-content-loader'
import LayoutWrapper from '../../../public/layout/LayoutWrapper'
import UseApiLoanApplication from '../api/UseApiLoanApplication'

const LoanApproval = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true)
  const [flag, setFlag] = useState(true)

  const location = useLocation()
  const navigate = useNavigate()

  const { loanQuoteDetails, fetchLoanQuoteDetails } = UseApiLoanApplication()

  const { pathname } = location
  const loanRef = pathname.split('/')[3]
  const sourceName = loanQuoteDetails?.source?.name || ''
  const sourceApi = loanQuoteDetails?.source?.api || ''

  const acceptLoan = () => {
    navigate({
      pathname: paths.getLoanAgreement(loanRef),
    })
  }

  useEffect(() => {
    if (flag) {
      fetchLoanQuoteDetails(loanRef, setLoading)
      setFlag(false)
      features.heapTrack('LoanApprovedComponent')
    }
  }, [flag, fetchLoanQuoteDetails, loanRef])

  if (loading) {
    return (
      <LayoutWrapper>
        <div className="approved-container">
          <List />
        </div>
      </LayoutWrapper>
    )
  }

  return (
    <LayoutWrapper className="PublicLoanContainer-approved-container">
      <CssBaseline />
      <Grid container className="approved-container" data-test="approved-container">
        <Grid item xs={12}>
          {sourceName !== '' && features.brandLogoCheck(sourceApi) && (
            <Grid item xs={12}>
              <Box display="flex">
                <Box className="Logo-container" data-test="partner-logo-container">
                  <PartnersLogo api={sourceApi} />
                </Box>
              </Box>
            </Grid>
          )}

          <Box mb={3} className="Card-shadow Headers">
            <Stepper step1="completed" step2="completed" step3="active" />
          </Box>

          <div className="loan-quote-container">
            <div className="loan-status-container">
              <Box display="flex">
                <Box className="icon-container">
                  <div className="lw-system-icons-checkmark icons-colorThird icons-fs-40" />
                </Box>
              </Box>
              <Box display="flex">
                <Box flexGrow={2} className="title-box" data-test="title-box">
                  <Typography variant="h1">Your loan is approved</Typography>
                </Box>
              </Box>
              <Box display="flex">
                <Box className="paragraph-box" data-test="paragraph-box">
                  <p>Great news! You passed all our checks.</p>
                </Box>
              </Box>
            </div>
            <div className="quote-details-container">
              <Box display="flex">
                <Box flexGrow={2} className="title-box" data-test="subtitle-box">
                  <Typography variant="h3">Loan details</Typography>
                </Box>
              </Box>
              {loanQuoteDetails && (
                <div className="quote-details">
                  <div className="details-alignment" data-test="loan-amount">
                    <span>Loan amount</span>
                    <span className="font-bold">{features.currencyFormat(Number(loanQuoteDetails?.amount), 2)}</span>
                  </div>
                  <div className="details-alignment" data-test="monthly-payment">
                    <span>Monthly payment</span>
                    <span className="font-bold">
                      {features.currencyFormat(Number(loanQuoteDetails?.monthlyCost), 2)}
                    </span>
                  </div>
                  <div className="details-alignment" data-test="loan-duration">
                    <span>Term</span>
                    <span className="font-bold">{loanQuoteDetails?.duration} months</span>
                  </div>
                  <div className="details-alignment" data-test="loan-apr">
                    <span>APR</span>
                    <span className="font-bold">{loanQuoteDetails?.apr}%</span>
                  </div>
                  <div className="details-alignment" data-test="loan-interest">
                    <span>Interest rate</span>
                    <span className="font-bold">{loanQuoteDetails?.interestRate}% p.a. fixed</span>
                  </div>
                  <div className="details-alignment" data-test="loan-fee">
                    <span>
                      Arrangement fee{' '}
                      <TooltipComponent
                        title={`The arrangement fee is included in the monthly payment and APR.`}
                        position="top"
                      />
                    </span>
                    <span className="font-bold">
                      {features.currencyFormat(Number(loanQuoteDetails?.arrangementFee), 2)}
                    </span>
                  </div>
                  <div className="details-alignment total-container" data-test="loan-total">
                    <span>Total payable</span>
                    <span className="font-bold">
                      {features.currencyFormat(Number(loanQuoteDetails?.totalPayable), 2)}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="block-info" data-test="credit-agreement-info">
            <span className="lw-marketing-icons-light-bulb-on icons-colorInkDark icons-bg-colorSkyLight icons-font-bold icons-bg-round icons-fs-18" />
            <p>
              Once you've accepted your loan and signed the credit agreement, we'll transfer your money within one
              working day. If you change your mind after signing the credit agreement, you can still cancel your loan by
              notifying us within the first 14 days.
            </p>
          </div>

          <div className="loan-agreement-info">
            <Button
              data-test="continue-btn"
              variant="contained"
              color="primary"
              onClick={acceptLoan}
              aria-label="Loan Approval - click Accept my loan button"
            >
              Accept my loan
            </Button>
            <div className="secure-process" data-test="secure-info">
              <span className="fluro-icon-lock icons-colorBlack icons-fs-20" />
              <span>Secure, encrypted process</span>
            </div>
          </div>
        </Grid>
      </Grid>
    </LayoutWrapper>
  )
}

export default LoanApproval
