export function serverRedirect(link: string): void {
  window.location.href = link
}

export function hideSideMenu(): string[] {
  return [
    getDirectDebitError(),
    getDirectDebitSuccess(),
    getDebitCardError(),
    getDebitCardSuccess(),
    `loan-application`,
    getLoanApplicationRequiresOpenbanking(),
    getOpenBankingCallback(),
    getDynamicRepaymentPlanUUID(),
    getDynamicRepaymentPlanUUIDUrlConditions(),
    confirmIncome(),
    getNotFound(),
    getLadeEmailLinkIdentityDocumentUpload(),
    getUnauthenticatedOpenBanking(),
  ]
}

export function hideMobileHamburger(): string[] {
  return [
    `loan-application`,
    getLoanApplicationRequiresOpenbanking(),
    getOpenBankingCallback(),
    getDynamicRepaymentPlanUUID(),
    getDynamicRepaymentPlanUUIDUrlConditions(),
    getNotFound(),
  ]
}

export function getDashboard(): string {
  return `/borrowing-centre`
}

export function getNotFound(): string {
  return `/error/404`
}

export function getAllViewLoanStatement(): string {
  return `/your-account/statements`
}

export function getAllViewCreditDocument(): string {
  return `/your-account/documents`
}

export function getAllManagePayments(): string {
  return `/borrowing-centre/manage-your-borrowing/manage-payments`
}

export function getAllChangePaymentDay(): string {
  return `/borrowing-centre/manage-your-borrowing/your-payments`
}

export function getChangePaymentDay(reference: string): string {
  return `/borrowing-centre/manage-your-borrowing/your-payments?ref=${reference}`
}

export function getMakeOverpayment(): string {
  return `/borrowing-centre/manage-your-borrowing/loan/over-repayment`
}

export function getSettleLoan(): string {
  return `/borrowing-centre/manage-your-borrowing/loan/early-repayment`
}

export function getAllPayReAged(): string {
  return `/borrowing-centre/pay-re-aged`
}

export function getPayReAged(): string {
  return `/borrowing-centre/pay-re-aged/:loanId`
}

export function getAllPayArrears(): string {
  return `/borrowing-centre/manage-your-borrowing/loan/pay-arrears`
}

export function getPayArrears(): string {
  return `/borrowing-centre/pay-arrears/:loanId`
}

export function getDirectDebitError(): string {
  return `/borrowing-centre/direct-debit/error`
}

export function getDirectDebitSuccess(): string {
  return `/borrowing-centre/direct-debit/success`
}

export function getDebitCardError(): string {
  return `/borrowing-centre/debit-card/error`
}

export function getDebitCardSuccess(): string {
  return `/borrowing-centre/debit-card/success`
}

export function getUnauthenticatedDebitCardSuccess(): string {
  return `/card-payment-success`
}

export function getUnauthenticatedDebitCardError(): string {
  return `/card-payment-error`
}

export function getLoanQuote(): string {
  return `/borrowing-centre/request-a-loan`
}

export function getNotifications(): string {
  return `/your-account/notifications`
}

export function getAccounts(): string {
  return `/your-account`
}

export function getSignInCallback(): string {
  return `login/callback`
}

export function getCardPaymentRequest(): string {
  return `/card-payment-request/:uuid`
}

export function getSignIn(): string {
  return `/sign-in`
}

export function getPasswordResetCallback(): string {
  return `/sign-in/password-reset/:token`
}

export function getUserCreatePasswordCallback(): string {
  return `/sign-in/create-password/:token`
}

export function getSignUp(): string {
  return '/sign-up'
}

export function getResetPassword(): string {
  return `/reset-password`
}

export function getLogout(): string {
  return `/user/logout`
}

export function getUploadDocuments(): string {
  return `/borrowing-centre/upload-documents`
}

export function getConfirmNewPaymentDay(): string {
  return `/confirm-new-payment-day`
}

export function getLoanApplicationPreapproved(): string {
  return `/loan-application/preapproved`
}

export function getLoanApplicationForm(ref?: string): string {
  return `/loan-application/apply/${ref || ':reference'}`
}

export function getLoanApplicationCreateForm(ref?: string): string {
  return `/loan-application/create-password/${ref || ':reference'}`
}

export function getLoanApplicationRedirect(ref?: string): string {
  return `/loan-application/redirect/${ref || ':reference'}`
}

export function getLoanApplicationApproved(ref?: string): string {
  return `/loan-application/approved/${ref || ':reference'}`
}

export function confirmIncome(): string {
  return `/confirm-income`
}

export function confirmIncomeSuccess(): string {
  return `/confirm-income/success`
}

export function getUnauthenticatedOpenBanking(): string {
  return `/connect-open-banking`
}

export function getUnauthenticatedOpenBankingCallback(): string {
  return `/connect-open-banking/callback`
}

export function getLoanApplicationRequiresOpenbanking(): string {
  return `/open-banking`
}

export function getLoanApplicationRequiresOpenbankingWithSearch(search: string): string {
  return `/open-banking${search}`
}

export function getOpenBankingCallback(): string {
  return `/open-banking/success`
}

export function getLoanApplicationIncomeDocumentUpload(): string {
  return `/loan-application/income-document-upload`
}

export function getLoanApplicationIncomeDocumentUploadWithSearch(search: string): string {
  return `/loan-application/income-document-upload${search}`
}

export function getLoanApplicationIdentityDocumentUpload(): string {
  return `/loan-application/identity-document-upload`
}

export function getLadeEmailLinkIdentityDocumentUpload(): string {
  return `/your-account/uploader`
}

export function getLoanAgreement(ref?: string): string {
  return `/loan-application/agreement/${ref || ':reference'}`
}

export function getDirectDebitInstruction(ref?: string): string {
  return `/loan-application/direct-debit-instruction/${ref || ':reference'}`
}

export function getLoanIntroduction(): string {
  return '/loanintroduction/'
}

export function getLoanIntroductionUUID(): string {
  return '/loanintroduction/:uuid'
}

export function getBrokerLoanIntroduction(): string {
  return '/brokerloanintro/'
}
export function getBrokerLoanIntroductionUUID(): string {
  return '/brokerloanintro/:uuid'
}

export function getPcsLoanIntroduction(): string {
  return '/pcsloanintro/'
}
export function getPcsLoanIntroductionUUID(): string {
  return '/pcsloanintro/:uuid'
}

export function getPartnerIntroduction(): string {
  return `/partnerloanintro/:uuid`
}

export function getRepaymentPlan(path?: string): string {
  return `/borrowing-centre/manage-your-borrowing/repayment-plan/${path || ':uuid'}`
}

export function getRepaymentPlanUrlConditions(path?: string): string {
  return `/borrowing-centre/manage-your-borrowing/repayment-plan/${path || ':uuid'}/conditions`
}

export function getDynamicRepaymentPlanUUID(): string {
  const repaymentPlanUUID = window?.location?.pathname?.split('/')[4]
  return getRepaymentPlan(repaymentPlanUUID)
}

export function getDynamicRepaymentPlanUUIDUrlConditions(): string {
  const repaymentPlanUUID = window?.location?.pathname?.split('/')[4]
  return getRepaymentPlanUrlConditions(repaymentPlanUUID)
}

// few users was come from this page which we should bring them to borrowing-centre
export function getStaticsPage(): string {
  return `/static/`
}
export function getRedundantDrupalPage(): string {
  return `/borrowing-centre/manage-your-borrowing`
}

/*
 * Marketing URL
 */

export const termsAndConditions =
  'https://www.fluro.co.uk/documents/borrower-platform-terms-and-conditions-march-2022.pdf'

export const privacyPolicy = 'https://www.fluro.co.uk/privacy'

export const marketing = {
  peerToPeerLending: {
    overview: {
      url: '/peer-to-peer-lending',
    },
    howItWorks: {
      url: '/peer-to-peer-lending/how-it-works',
    },
  },

  isa: {
    overview: {
      url: '/innovative-finance-isa',
    },
    howItWorks: {
      url: '/innovative-finance-isa/how-it-works',
    },
  },

  personalLoans: {
    overview: {
      url: '/personal-loans',
    },
  },

  aboutUs: {
    overview: {
      url: '/about-us/company-team',
    },
    meetTheTeam: {
      url: '/about-us/meet-the-team',
    },
    riskManagement: {
      url: '/about-us/risk-management',
    },
    theShield: {
      url: '/about-us/the-shield',
    },
    statistics: {
      url: '/about-us/statistics',
    },
    blog: {
      url: '/blog',
    },
  },

  contactUs: {
    url: 'https://www.fluro.co.uk/contact/',
  },

  helpCentre: {
    overview: {
      url: 'https://www.fluro.co.uk/help/',
    },
    lender: {
      url: '/help-centre/lender',
    },
    isa: {
      url: '/innovative-finance-isa/help',
    },
    borrower: {
      url: '/help-centre/borrower',
    },
    financeGuides: {
      url: '/help-centre/finance-guides',
    },
  },
}
