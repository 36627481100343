import * as loanStatus from 'utils/LoanStatus'
import * as variable from 'utils/Variable'

import HeaderNavigationWithLogin from 'view/portal/layout/HeaderNavigationWithLogin'
import LayoutWrapper from '../../../../public/layout/LayoutWrapper'
import LoanApplicationDeclined from '../../../components/declined/Home'
import LoanApproval from '../../approval/Home'
import DocumentUpload from '../../referred/DocumentUpload'
import OpenBanking from '../../referred/OpenBanking'
import Underwriter from '../../referred/Underwriter'

import { SubmitLoanApplicationResponse } from 'api/BorrowerApiSchemas'
import LoanProcessing from 'view/portal/components/checking/Home'

type Props = {
  loanApplicationResponse: SubmitLoanApplicationResponse | undefined
  openBankingReason: string
}

const DisplayDecisionContainer = ({ loanApplicationResponse, openBankingReason }: Props): JSX.Element => {
  if (
    loanApplicationResponse?.decision === loanStatus.StatusReferred &&
    loanApplicationResponse?.referralReason === variable.referredRequiresUnderwriter
  ) {
    return (
      <LayoutWrapper className="create-password-login-wrapper">
        <HeaderNavigationWithLogin logged={true} />
        <Underwriter />
      </LayoutWrapper>
    )
  }

  if (
    loanApplicationResponse?.decision === loanStatus.StatusReferred &&
    loanApplicationResponse?.referralReason === variable.referredRequiresMandatoryOb
  ) {
    return <OpenBanking reference={loanApplicationResponse.reference} mandatoryOpenBanking={true} />
  }

  if (
    loanApplicationResponse?.decision === loanStatus.StatusReferred &&
    loanApplicationResponse?.referralReason === variable.referredRequiresOb
  ) {
    return (
      <OpenBanking
        reason={openBankingReason}
        reference={loanApplicationResponse.reference}
        mandatoryOpenBanking={false}
      />
    )
  }

  if (
    loanApplicationResponse?.decision === loanStatus.StatusReferred &&
    loanApplicationResponse?.referralReason === variable.referredRequiresDocUpload
  ) {
    return <DocumentUpload type={'Identity'} openBankingError={false} />
  }

  if (loanApplicationResponse?.decision === loanStatus.StatusApproved) {
    return <LoanApproval />
  }

  // show declined
  if (loanApplicationResponse?.decision === loanStatus.StatusDeclined) {
    return (
      <div className="loan-status-declined">
        <LayoutWrapper>
          <HeaderNavigationWithLogin logged={true} />
          <LoanApplicationDeclined />
        </LayoutWrapper>
      </div>
    )
  }

  return (
    <div className="loan-status-processing">
      <LoanProcessing {...{ copy: 'We are performing our checks…' }} />
    </div>
  )
}

export default DisplayDecisionContainer
