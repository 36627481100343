import * as features from 'utils/Features'
import * as messages from 'utils/Messages'

import { Box, Grid, TextField } from '@mui/material'

import { LoanApplicationDetails } from 'api/BorrowerApiSchemas'
import { useFormikContext } from 'formik'
import * as Yup from 'yup'
import { LoanApplicationFormValues } from './LoanApplicationForm'

type Props = {
  loanApplicationDetails?: LoanApplicationDetails
  step: number
}

export interface BankDetailsFormValues {
  sortCode: string
  accountNumber: string
}

export const BankDetailsFormValidationSchema = Yup.object({
  sortCode: Yup.string().length(8, messages.sortcodeRequired).required(messages.sortcodeRequired),
  accountNumber: Yup.string().length(8, messages.accountNumberRequired).required(messages.accountNumberRequired),
})

const BankDetails = ({ step }: Props): JSX.Element => {
  const { values, touched, setFieldValue, errors, handleChange, handleBlur } =
    useFormikContext<LoanApplicationFormValues>()

  const handleKeySortCode = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement
    const key = e.key

    if (key !== 'Backspace' && key !== 'Delete') {
      if (input.value.length < 8) {
        setFieldValue('sortCode', features.transformSortCode(input.value))
      }
    }
  }

  return (
    <>
      <Grid item xs={12} className="Account-content line-separate" data-test="bank-details-container">
        <Box>
          <strong>{step}. Bank details</strong>
        </Box>
      </Grid>
      <Grid item xs={12} className="Account-content" data-test="bank-details-input">
        <Box mb={2}>Tell us where you&apos;d like us to pay your loan.</Box>
        <Box mb={2}>
          <TextField
            className="Textfield-formControl"
            variant="outlined"
            autoComplete="off"
            fullWidth
            label="Sort code"
            name="sortCode"
            value={values.sortCode}
            inputProps={{ maxLength: 8 }}
            onChange={handleChange}
            onInput={features.allowNumbersInput}
            onKeyUp={handleKeySortCode}
            onBlur={handleBlur}
            error={touched.sortCode && Boolean(errors.sortCode)}
            helperText={touched.sortCode && Boolean(errors.sortCode) ? errors.sortCode : 'E.g. 12-34-56'}
            data-test="bank-details-sortcode-input"
          />
        </Box>
        <Box>
          <TextField
            className="Textfield-formControl"
            variant="outlined"
            autoComplete="off"
            fullWidth
            label="Account number"
            name="accountNumber"
            value={values.accountNumber}
            inputProps={{ maxLength: 8 }}
            onChange={handleChange}
            onBlur={handleBlur}
            onInput={features.allowNumbersInput}
            error={touched.accountNumber && Boolean(errors.accountNumber)}
            helperText={
              touched.accountNumber && Boolean(errors.accountNumber) ? messages.accountNumberRequired : 'E.g. 01234567'
            }
            data-test="bank-details-account-number-input"
          />
        </Box>
      </Grid>
    </>
  )
}

export default BankDetails
