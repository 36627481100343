import { colours } from './Colour'
import { getLoanAgreement, getLoanApplicationForm } from './Links'
import * as variable from './Variable'

export const StatusMessagesCreated = `You have a quote on this loan. If you wish to apply you need to press 'Apply for this loan'.`

export const StatusMessagesApproved = `Your loan application has been approved. If you wish to accept the loan please press 'Accept loan offer'.`

export const StatusMessagesApplicationInProgress = `You can't apply for this loan right now as you have an application in progress with us.`

export const StatusMessagesExpired =
  'We are no longer able to proceed with your loan application as it has been expired.'

export const StatusMessagesCancelled =
  'We are no longer able to proceed with your loan application as it has been cancelled.'

export const StatusMessagesPendingInfo = `Your loan application is now being reviewed by a member of our team. We'll be in touch within ${variable.underwriterContactTimeframe} if we need any further information.`

export const StatusMessagesPendingBank =
  'Fluro have finalised all checks and the loan application is with a Senior Underwriter for approval. We expect to have a loan decision with you in 2 business hours.'

export const StatusMessagesPendingPayout =
  'The loan application is with our finance team to process the payment. We expect to get the funds into your bank account typically within 1 business day.'

export const StatusMessagesRevised =
  'Fluro have been unable to verify all the information (typically income, rental or mortgage payment information). As a result, the monthly cost of your loan has changed. Should you accept the revised terms of your loan, the funds will normally be paid into your bank account within 1 business day.'

export const StatusMessagesApplied = `Your loan application is now being reviewed by a member of our team. We will be in contact if we require further information within ${variable.underwriterContactTimeframe}.`

export const StatusInitialised = 'init'
export const StatusCreated = 'created'
export const StatusApproved = 'approved'
export const StatusPendingInfo = 'pending-info'
export const StatusRevised = 'revised'
export const StatusDeclined = 'declined'
export const StatusPendingBankInfo = 'pending-bankinfo'
export const StatusSaved = 'saved'
export const StatusApplied = 'applied'
export const StatusCancelled = 'cancelled'
export const StatusAccepted = 'accepted'
export const StatusExpired = 'expired'
export const StatusArchived = 'archived'
export const StatusPendingBank = 'pending-bank'
export const StatusPendingPayout = 'pending-payout'
export const StatusPaid = 'paid'
export const StatusReferred = 'referred'

export const StatusActive = 'active'
export const StatusSettled = 'settled'
export const StatusWriteoff = 'writeoff'

export const PriorityLoanLists = [StatusActive, StatusWriteoff, StatusSettled]

export const LoanRequestStatuses = [
  {
    name: StatusExpired,
    title: 'Expired',
    color: colours.azurite,
    message: StatusMessagesExpired,
    buttonLabel: '',
    buttonAction: '',
    allowCancel: false,
  },
  {
    name: StatusCancelled,
    title: 'Cancelled',
    color: colours.azurite,
    message: StatusMessagesCancelled,
    buttonLabel: '',
    buttonAction: '',
    allowCancel: false,
  },
  {
    name: StatusCreated,
    title: 'Quoted',
    color: colours.azurite,
    message: StatusMessagesCreated,
    buttonLabel: 'Apply for this loan',
    buttonAction: getLoanApplicationForm(),
    allowCancel: true,
  },
  {
    name: StatusApproved,
    title: 'Approved',
    color: colours.azurite,
    message: StatusMessagesApproved,
    buttonLabel: 'Accept loan offer',
    buttonAction: getLoanAgreement(),
    allowCancel: true,
  },
  {
    name: StatusRevised,
    title: 'Revised',
    color: colours.azuriteDarker,
    message: StatusMessagesRevised,
    buttonLabel: 'Accept loan offer',
    buttonAction: getLoanAgreement(),
    allowCancel: true,
  },
  {
    name: StatusApplied,
    title: 'Applied',
    color: colours.azurite,
    message: StatusMessagesApplied,
    buttonLabel: '',
    buttonAction: '',
    allowCancel: true,
  },
  {
    name: StatusPendingBank,
    title: 'Awaiting approval',
    color: colours.amber,
    message: StatusMessagesPendingBank,
    buttonLabel: '',
    buttonAction: '',
    allowCancel: false,
  },
  {
    name: StatusPendingInfo,
    title: 'Pending info',
    color: colours.amber,
    message: StatusMessagesPendingInfo,
    buttonLabel: '',
    buttonAction: '',
    allowCancel: false,
  },
  {
    name: StatusPendingPayout,
    title: 'Processing',
    color: colours.azuriteLight,
    message: StatusMessagesPendingPayout,
    buttonLabel: '',
    buttonAction: '',
    allowCancel: false,
  },
]
