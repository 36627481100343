import './_sideMenu.scss'

import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { SyntheticEvent } from 'react'
import { useNavigate } from 'react-router-dom'

const SideMenuItem = (props: SideMenuItemProp): JSX.Element => {
  const { sideMenu, name, link, icon, testDescription } = props
  const navigate = useNavigate()

  // show the payment menu
  let clxName = ''
  if (name === 'Pay arrears') {
    clxName = sideMenu?.missedArrears === 'yes' ? 'showMenu' : 'hideMenu'
  }
  if (name === 'Pay re-aged') {
    clxName = sideMenu?.missedReaged === 'yes' ? 'showMenu' : 'hideMenu'
  }

  // add active class to current page
  if (window.location.pathname === link) {
    clxName = 'active'
  }

  const sideMenuAction = (e: SyntheticEvent): void => {
    e.preventDefault()
    navigate(link)
  }

  const MenuItemRoot = (
    <a
      className={`MuiLink-withoutUnderline SideMenuItemComponent-menuItem ${clxName}`}
      href="/#"
      data-test={testDescription}
      onClick={sideMenuAction}
    >
      {/* Display an icon if any */}
      {!!icon && (
        <ListItemIcon className="ListItemIcon-menuItemIcon">
          <span className={`SideMenuIcon ${icon}`}></span>
        </ListItemIcon>
      )}
      <ListItemText primary={name} inset={!icon} />
    </a>
  )

  return <>{MenuItemRoot}</>
}

export interface SideMenuItemProp {
  name: string
  link: string
  icon?: string
  testDescription?: string
  sideMenu?: { loanCount?: number; missedArrears?: string; missedReaged?: string }
}

export default SideMenuItem
