import * as helperFunction from 'utils/HelperFunction'
import * as paths from 'utils/Links'

import './_notfound.scss'

import { Box, Button, CssBaseline, Grid, Typography } from '@mui/material'
import { useOktaAuth } from '@okta/okta-react'
import logoWhite from 'assets/images/logo/fluro-logo.svg'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppEnv, appEnv } from '../../../../api/AppEnv'

const NotFound = (): JSX.Element => {
  const navigate = useNavigate()
  const oktaAuth = useOktaAuth()
  const authUser = oktaAuth?.authState?.isAuthenticated
  const redirectPath = localStorage.getItem('referrerPath') || ''
  let fullPath = ''
  const url = () => {
    if (appEnv === AppEnv.Prod) {
      fullPath = 'https://www.fluro.co.uk/'
    } else {
      fullPath = 'https://www.preprod.fluro.co.uk/'
    }

    return fullPath
  }
  const goToDashboard = () => {
    navigate(paths.getDashboard())
  }

  useEffect(() => {
    if (authUser && helperFunction.stringIsDefinedAndNotEmpty(redirectPath)) {
      const timeout = setTimeout(() => {
        localStorage.removeItem('referrerPath')
      }, 100)
      return () => clearTimeout(timeout)
    }
  }, [authUser, redirectPath])

  return (
    <div className="FixedWidthContainer-layout page-not-found">
      <CssBaseline />

      <Grid container className="Layout-MainContainer">
        <Box display="flex" alignItems="center" flexDirection="column">
          <Box className="not-found-logo">
            <a href={url()} title="Fluro" className="logo-cta" data-test="logo-cta">
              <img src={logoWhite} className="Fluro-logo" alt="Fluro Logo" data-test="fluro-logo" />
            </a>
          </Box>
        </Box>
        <Box className="not-found-content">
          <div className="top-half">
            <Typography gutterBottom variant="subtitle1" component="h1" data-test="not-found-h1">
              404
            </Typography>
            <Typography gutterBottom variant="subtitle2" component="h2" data-test="not-found-h2">
              Oops! Page not found.
            </Typography>
            <Typography variant="body2" data-test="not-found-title">
              Wandered a bit far, have we? Let's find another way around.
            </Typography>
          </div>
          <Box className="button-cta">
            <Button
              className="continueButton"
              variant="contained"
              color="primary"
              onClick={goToDashboard}
              data-test="go-to-dashboard-button"
            >
              Go home
            </Button>
          </Box>
        </Box>
      </Grid>
    </div>
  )
}

export default NotFound
