export const heading = {
  h1: {
    fontSize: '40px',
    lineHeight: '46px',
    fontWeight: 500,
  },
  h2: {
    fontSize: '30px',
    lineHeight: '36px',
    fontWeight: 500,
  },
  h3: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 500,
  },
  h4: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 500,
  },
  h5: {
    fontSize: '18px',
    lineHeight: '26px',
    fontWeight: 500,
  },
  small: {
    fontSize: '14px',
    lineHeight: '17px',
  },
  Subheading: {
    fontSize: '22px',
    lineHeight: '30px',
    fontWeight: 500,
  },
}

export const companyName = 'Fluro'
export const companyNameOffical = 'Fluro Platform Limited'
export const customerServicePaymentEmail = 'customerpayments@fluro.co.uk'
export const customerServiceEmail = 'cx@fluro.co.uk'
export const underwriterEmail = 'underwriting@fluro.co.uk'
export const customerServiceNumber = '020 7096 8512'
export const customerServiceNumberCall = customerServiceNumber.replace(/\s/g, '')
export const openingHours = `Mon - Fri, 9:00am - 5:30pm`
export const address = `55 Old Broad Street, London, EC2M 1RX`
export const underwriterContactTimeframe = '2 business days'

export const httpStatusCodes = {
  created: 201,
  ok: 200,
  accepted: 202,
  noContent: 204,
  badRequest: 400,
  unauthorized: 401,
  paymentRequired: 402,
  forbidden: 403,
  notFound: 404,
  conflict: 409,
  unprocessableEntity: 422,
  internalServerError: 500,
  tooManyRequests: 429,
  serviceUnavailable: 503,
}

export const limitPagination = 12 // displays a max of 12 items per page
export const activityTimeout = 1800000 // Activity Timeout in milliseconds. 30 mins = 1800000

export const defaultRef = 'reference'
export const loanAmount = 'loanAmount'

export const paymentDayChanged = 'paymentDayChangedSuccessfully'
export const acceptedCreditDocuments = 'acceptedCreditDocuments'
export const newPayDay = 'newPayDay'
export const existingPayDay = 'existingPayDay'

export const paymentTypeArrears = 'Arrears'
export const paymentTypeReAged = 'ReAged'
export const paymentTypeOverpayment = 'Overpayment'
export const paymentTypeEarlySettlement = 'EarlySettlement'

export const referredRequiresUnderwriter = 'requires_underwriter'
export const referredRequiresDocUpload = 'requires_doc_upload'
export const referredRequiresOb = 'requires_ob'
export const referredRequiresMandatoryOb = 'requires_mandatory_ob'

export const employmentStatusSectorRequired = ['Contract Worker', 'Employed', 'Other', 'Self Employed', 'Temporary'] // 0-Contract Worker, 1-Employed, 3-Other, 5-Self Employed, 7-Temporary

export const OpenBankingConnectionStatusSucceeded = 'Succeeded'
export const OpenBankingConnectionStatusFailed = 'Failed'
export const OpenBankingConnectionStatusUnknown = 'Unknown'
