import 'components/Dialogs/Dialogs.scss'
import './_forgotYourPassword.scss'

import { Button, Dialog, DialogActions, DialogContent, IconButton, Slide } from '@mui/material'
import { forwardRef, useState } from 'react'

import MuiDialogTitle from '@mui/material/DialogTitle'
import { TransitionProps } from '@mui/material/transitions'
import { oktaWrapper } from 'api/OktaConfig'
import useOktaRequest from '../../view/public/common/api/UseOktaRequest'

interface DialogTitleProps {
  id: string
  children: React.ReactNode
  onClose: () => void
}

interface PasswordManagementProps {
  open: boolean
  email: string
  handleDialogClose: () => void
}

const DialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props
  return (
    <MuiDialogTitle {...other}>
      <div>{children}</div>
      {onClose ? (
        <IconButton
          className="IconButton-system-close IconButton-system-closeBackground-info"
          aria-label="close"
          color="inherit"
          onClick={onClose}
          size="large"
          data-test="popup-close-btn"
        >
          <span className="lw-system-icons-close-x icons-fs-16"></span>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<string, string> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ForgotYourPassword = ({ open, email, handleDialogClose }: PasswordManagementProps): JSX.Element => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const okta = oktaWrapper.useOktaAuth()
  const { oktaAuth } = okta
  const { successMessages, postForgotPassword } = useOktaRequest()

  const handleSubmit = () => {
    setLoading(true)

    // call okta API to send the email
    postForgotPassword(email, oktaAuth, setLoading)
  }

  return (
    <Dialog
      className="change-password-dialog"
      onClose={handleDialogClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      TransitionComponent={Transition}
      data-test="forgot-passowrd-popup"
    >
      <DialogTitle id="customized-dialog-title" data-test="popup-title" onClose={handleDialogClose}>
        Forgot your password?
      </DialogTitle>
      <DialogContent className="password-change-container" dividers data-test="popup-content">
        {successMessages !== '' ? (
          <div className="SecondContent">
            <div className="SecondContent-title">Thanks, a password reset email has been sent to your inbox.</div>
            <p>Didn’t get the email on {email}? Please check your spam folder or try again.</p>
          </div>
        ) : (
          <>
            In order to change your password, please click “Send email” button below. We will send you a reset password
            link to the email address you used to create your Fluro account.
          </>
        )}
      </DialogContent>

      {successMessages === '' && (
        <DialogActions>
          <Button
            data-test="button-dialogs-submit"
            size="small"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Send email
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default ForgotYourPassword
