import * as dates from 'utils/Date'
import * as features from 'utils/Features'

import { Box, Grid, LinearProgress, Typography, useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react'

import { useTheme } from '@mui/material/styles'
import { FileDelete } from './FileDelete'

export interface SingleFileUploadWithProgressProps {
  file: File
  onDelete: (file: File) => void
  onUpload: (file: File, url: string) => void
}

export function SingleFileUploadWithProgress({
  file,
  onDelete,
  onUpload,
}: SingleFileUploadWithProgressProps): JSX.Element {
  const [progress, setProgress] = useState(0)
  const [fileProgress, setFileProgress] = useState(0)
  const [flag, setFlag] = useState(true)

  useEffect(() => {
    function upload() {
      let percent = 0
      let uploadSpeed = 0
      const interval = setInterval(() => {
        percent += 10
        setProgress(Math.round(percent))

        uploadSpeed = Math.round((file.size / 100) * percent)
        setFileProgress(uploadSpeed)

        if (percent === 100) {
          clearInterval(interval)
          onUpload(file, 'success')
        }
      }, 100)
    }

    if (flag) {
      upload()
      setFlag(false)
    }
  }, [flag, file, onUpload])

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <Box display="flex" alignItems="center">
      <Box className="file-icon-content">
        <span className="fluro-file file-icon icons-colorInk"></span>
      </Box>
      <Box width="100%" mr={1} className="file-upload-content">
        <Grid justifyContent="space-between" container>
          <Grid item className="uploaded-file-info">
            <Typography variant="caption" color="textPrimary">
              {isMobile ? features.textAbstract(file.name, 20) : features.textAbstract(file.name, 60)}
            </Typography>
          </Grid>
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            <Grid item className="uploaded-file-info">
              <Typography variant="caption" color="textPrimary">
                {dates.getCurrentDateFormat()}
              </Typography>
            </Grid>
          </Box>
        </Grid>

        <LinearProgress variant="buffer" value={progress} valueBuffer={progress} />

        <Grid justifyContent="space-between" container>
          {progress === 100 ? (
            <>
              <Grid item className="uploaded-file-info">
                <Typography variant="caption" color="textPrimary" className="amount-loaded">
                  {features.formatBytes(file.size)} of {features.formatBytes(file.size)}
                </Typography>
              </Grid>

              <Grid item className="uploaded-file-info">
                <Typography variant="caption" color="textPrimary">
                  Ready to send
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item className="uploaded-file-info">
                <Typography variant="caption" color="textPrimary">
                  {features.formatBytes(fileProgress)} of {features.formatBytes(file.size)}
                </Typography>
              </Grid>

              <Grid item className="uploaded-file-info">
                <Typography variant="caption" color="textPrimary">
                  {Math.round(progress)}%
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <Box className="text-right">
        <FileDelete file={file} onDelete={onDelete} className="success" />
      </Box>
    </Box>
  )
}
