import { forwardRef, useEffect, useState } from 'react'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import Loader from 'components/Loader/Loader'

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<string, string> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const LogoutView = (): JSX.Element => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <>
      <Dialog
        className="logout-dialog"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="logout"
      >
        <DialogContent>
          <DialogContentText id="logout">
            <Loader size="large" color="primary" />
            Logging out…
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}
