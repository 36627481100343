export const errorMessagesTitle = `There was a problem`
export const errorCouldNotLoad = `Sorry. We couldn't load this bit.`

export const errorMessagesLoan = `We couldn't load your loan due to a system error. Please try again. If this problem persists, contact us.`

export const errorTitleLoanQuotes = `Sorry. We couldn't load your quotes.`
export const errorMessagesLoanQuotes = `We couldn't load your loan quotes due to a system error. Please try again. If this problem persists, contact us.`

export const errorLoadingMessagesTitle = `Oh snap! We couldn't load this page.`
export const errorMessagesSystem = `There is a problem with our system. Please try again later. If this problem persists, contact us.`

export const errorMessagesSystemError = `We couldn't load this page due to a system error. Please try again. If this problem persists, contact us.`

export const errorMessagesLoading = `There was an error while loading this page. Please refresh the page or contact us if this problem persists.`

export const errorMessagesGetQuoteCancellationReasonList = `There was an error while loading the cancellation reason list. Please refresh the page or contact us if this problem persists.`

export const errorTitleDownloadError = 'Download error'
export const errorMessagesRepaymentPlanDownloadError =
  'There was an error while downloading your repayment plan terms. Please contact us if this message persists.'
export const errorMessagesCreditDocumentsDownloadError = `There was an error while downloading your documents. Please contact us if this message persists.`

export const required = `This field is required`

export const allowCreditCardOnly = `We are unable to accept credit card payments. Please try paying with a debit card.`

export const min10Pound = `Minimum £10`

export const enterAmountMin10 = 'Please enter an amount (minimum £10)'

export const enterAmountHigherThan10 = 'Please enter a higher amount (minimum £10)'

export const addPhoneNumber = 'Please add a phone number'
export const addValidPhoneNumber = 'Please add a valid phone number'
export const addValidMobileNumber = 'Please add a valid mobile number e.g. 07700900123'
export const phoneHelpText = 'E.g. 07700900123. We only use this for customer support.'
export const mobileNumberExample = 'E.g. 07700900123'
export const telNumberExample = 'E.g. 01632960123'

export const errorCurrentPassword = 'Current password is incorrect'

export const newPasswordEmpty = 'Please create your new password below'

export const confirmPasswordEmpty = 'Please create your confirm new password below'

export const passwordNotMeetRequirement = `Sorry! This password doesn't meet the requirements.`

export const enterPassword = 'Please enter your password'

export const changedPassword = 'Password changed succesfully'

export const incorrectPassword = 'Incorrect password'

export const statusAccountLocked =
  'Sorry, your account is temporarly locked. There have been more than 5 failed login attempts.'

export const employmentStatusRequired = 'Please select your employment status'
export const positionRequired = 'Please select your position'
export const employmentSectorRequired = 'Please enter your sector name'
export const employerRequired = 'Please enter your employer'

export const chooseYesOrNo = `Please select "Yes" or "No"`
export const sortcodeRequired = 'Please enter your sort code e.g. 12-34-56'
export const futureChangeOfIncomeReasonRequired = 'Please select your reason'
export const futureChangeOfIncomeSpecificReasonRequired = 'Please provide more details'
export const accountNumberRequired =
  'Please enter your account number e.g. 01234567. If your account number is 7 digits, begin with a 0. '

export const accountTemporarilyLocked =
  'Sorry, your account is temporarily locked. There have been too many failed login attempts. Try again later or reset your password.'

export const accountPasswordExpired =
  'Sorry, your password has expired. Please reset it by clicking "Forgot your password" below. After you set-up a new password, refresh this page to log in and continue.'

export const acceptedLoanRequest = 'Loan request has been accepted'

export const firstNameRequired = 'Please enter your first name'
export const lastNameRequired = 'Please enter your last name'

export const dateOfBirthError = 'Please enter a valid date e.g. 31 12 1980'
export const dateOfBirthToday = 'Happy Birthday!'
export const dateOfBirthHelpText = 'E.g. 31 12 1980. You must be over 18.'
export const dateOfBirthAgeMin = 'You must be at least 18'
export const dateOfBirthAgeMax = `Invalid year (unless you're the world's oldest person)`
export const dateOfBirthAgeFuture = `Date is in the future (we can't lend to time travellers)`

export const emailError = 'Please enter a valid email address'
export const emailHelpText = `We'll use this email to send your quote`

export const selectAddress = 'Please select your address'
export const dateMovedInAddress = 'Please enter a valid month. E.g. 01 / 2022'
export const residentialStatusRequired = 'Please select your current residential status'

export const welcomeDashboard =
  'Your nominated bank account should be credited in the next few minutes, although it may take a bit longer with certain banks. Once your loan is active, you can start doing things like change your payment day, make overpayments, settle early and so on. In the meantime, should you have any questions, please do not hesitate to contact us.'

export const overpaymentDDInfo =
  'Please note that overpayments do not replace your scheduled monthly payments. Missing your scheduled monthly payment will result in arrears.'

export const considerApplicationMessage =
  'When considering your loan application we assess your credit score, your ability to repay the loan and other information. By applying for this loan, a search ‘footprint’ will be left on your credit file, which may affect future borrowing requests. After this search is complete, we may use Open Banking to confirm your information. If so, you will be presented with a link to provide Open Banking access on the next screen.'
