import './_openBankingOrDocUploadSuccess.scss'

import * as paths from 'utils/Links'

import { Box, Button, CssBaseline, Grid, Typography } from '@mui/material'

import LayoutWrapper from '../../../public/layout/LayoutWrapper'

const handleClick = () => {
  window.location.href = paths.getDashboard()
}

interface SuccessProps {
  mandatoryOpenBanking: boolean
}

const OpenBankingOrDocUploadSuccess = (props: SuccessProps): JSX.Element => {
  const dataTest = props.mandatoryOpenBanking ? 'mandatory-open-banking-success' : 'normal-success'
  const extraCopy = props.mandatoryOpenBanking
    ? 'We successfully connected to your bank account and received your information.'
    : ''
  return (
    <LayoutWrapper className="openbanking-or-doc-upload-container">
      <CssBaseline />
      <Grid container className="openbanking-or-doc-upload-success-container">
        <Grid item xs={12}>
          <Box mb={6} mt={12} className="Card-shadow Headers lines" data-test={dataTest}>
            <div className="success-container-top" data-test="openbanking-doc-upload-success">
              <Box display="flex">
                <Box className="icon-container">
                  <div className="lw-system-icons-checkmark icon-bg-azurite icons-fs-64 icon-bold" />
                </Box>
              </Box>
              <Box display="flex">
                <Box flexGrow={2} className="title-box" data-test="title-box">
                  <Typography variant="h1">That&rsquo;s all done!</Typography>
                  <p>We received your {props.mandatoryOpenBanking ? 'information' : 'documents'}.</p>
                </Box>
              </Box>
            </div>
            <div className="success-container-bottom">
              <Box display="flex">
                <Box className="paragraph-box" data-test="paragraph-box">
                  <p>
                    {extraCopy} We&rsquo;ll be in touch shortly. You can check the status of your application by going
                    to your loan dashboard.
                  </p>
                  <Button className="dashboard-button" variant="contained" color="primary" onClick={handleClick}>
                    Go to dashboard
                  </Button>
                  <div className="secure-process">
                    <span className="fluro-icon-lock icons-colorInkDark icons-fs-20" />
                    <span>Secure, encrypted process</span>
                  </div>
                </Box>
              </Box>
            </div>
          </Box>
        </Grid>
      </Grid>
    </LayoutWrapper>
  )
}

export default OpenBankingOrDocUploadSuccess
