import * as features from 'utils/Features'
import * as seoTitle from 'utils/SeoTitle'

import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import Checking from 'view/portal/components/checking/Home'
import Underwriter from 'view/portal/loanApplication/referred/Underwriter'

import OpenBankingApi from './OpenBankingApi'
import UnauthenticatedOpenBanking from './UnauthenticatedOpenBanking'

const UnauthenticatedOpenBankingProcessing = (): JSX.Element => {
  const [flag, setFlag] = useState(true)
  const { connectionStatus, checkOpenBankingConnectionStatus, connectionCheckError } = OpenBankingApi()

  const { search } = useLocation()
  const queryParams = queryString.parse(search)
  const taskId = (queryParams?.task_id as string) ?? ''
  const reference = (queryParams?.reference as string) ?? ''
  const userId = (queryParams?.userId as string) ?? ''
  const parsedUserId = Number.parseInt(userId)

  useEffect(() => {
    features.getBrowserHiddenProps(seoTitle.loanApplicationSeoTitle, seoTitle.loanApplicationChangeSeoTitle)

    if (flag && connectionStatus === null) {
      checkOpenBankingConnectionStatus(taskId, reference, parsedUserId)
      setFlag(false)
    }
  }, [checkOpenBankingConnectionStatus, connectionStatus, flag, taskId, reference, parsedUserId])

  return (
    <div className="open-banking-processing">
      {connectionStatus === null && <Checking {...{ copy: "We're fetching your open banking data." }} />}
      {((connectionStatus !== null && connectionStatus !== 'Succeeded') || connectionCheckError) && (
        <UnauthenticatedOpenBanking previouslyErrored={true} />
      )}
      {((connectionStatus !== null && connectionStatus === 'Succeeded') || connectionCheckError) && <Underwriter />}
    </div>
  )
}

export default UnauthenticatedOpenBankingProcessing
