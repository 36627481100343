import './SystemErrorMessage.scss'

import { Box, Paper, Typography } from '@mui/material'

interface SystemErrorMessageProps {
  title: string
  message: string
}

const SystemErrorMessage = ({ title, message }: SystemErrorMessageProps): JSX.Element => {
  return (
    <Box mt={3} className="SystemErrorMessage" data-test="system-error-message">
      <Paper>
        <Box pt={1} pb={2}>
          <div className="lw-system-icons-alert icons-fs-50 icons-colorAmberDark"></div>
          <Typography variant="h5">{title}</Typography>
          <Typography>{message}</Typography>
        </Box>
      </Paper>
    </Box>
  )
}

export default SystemErrorMessage
