import 'components/PasswordTextfield/PasswordTextfield.scss'

import * as features from 'utils/Features'

import { IconButton, InputAdornment, TextField } from '@mui/material'

import { useEffect, useState } from 'react'

type Props = {
  parentCallback: (newPasswordVal: string) => void
  errorMessages?: { newPassword?: string }
  email: string
  helperText?: string
  newUser?: boolean
  passwordError: boolean
  registeredUser: boolean
  apiResponseSuccessMessages: string
  setPasswordError: (password: boolean) => void
}

const Password = ({
  parentCallback,
  errorMessages,
  email,
  helperText,
  newUser,
  passwordError,
  setPasswordError,
  registeredUser,
  apiResponseSuccessMessages,
}: Props): JSX.Element => {
  const iconCheckmark = 'lw-marketing-icons-checkmark icons-colorPrimary'
  const iconsClose = 'lw-system-icons-close-x icons-colorInk'
  const colorPrimary = 'icons-colorPrimary'
  const unregisteredUser = newUser && !registeredUser

  const [newPasswordValue, setNewPasswordValue] = useState<string>('')

  //  new password
  const [showPassword, setShowPassword] = useState({
    newPassword: false,
  })

  const [showPasswordValidationContent, setShowPasswordValidationContent] = useState({
    newPassword: 'hide',
  })

  const [passwordValidation, setPasswordValidation] = useState({
    newPassword: {
      email: false,
      capital: false,
      lowercase: false,
      digit: false,
      special: false,
      length: false,
      full: false,
    },
  })

  const handleClickShowNewPassword = () =>
    setShowPassword({
      ...showPassword,
      newPassword: !showPassword.newPassword,
    })

  const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
  }

  const handleChangeNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value as string
    setPasswordValidation({ newPassword: features.passwordValidate(newValue, email) })
    setNewPasswordValue(newValue)
    parentCallback(newValue)
    setPasswordError(false)
  }

  const handleFocusNewPassword = () => {
    setShowPasswordValidationContent({ ...showPasswordValidationContent, newPassword: 'show' })
  }

  useEffect(() => {
    if (unregisteredUser && !newPasswordValue) {
      setPasswordError(true)
    }

    if (unregisteredUser && newPasswordValue !== '' && !passwordValidation.newPassword.full) {
      setPasswordError(true)
    }
  }, [newPasswordValue, unregisteredUser, passwordValidation.newPassword.full, setPasswordError])

  useEffect(() => {
    setNewPasswordValue('')
  }, [apiResponseSuccessMessages, errorMessages?.newPassword])

  return (
    <div className="o-form-fieldset-container password-change-container" data-test="password-container">
      <div className="o-form-fieldset o-form-label-top margin-btm-5 new-password-container SiginIn-password-container">
        <div className="o-form-input">
          <span className="o-form-input-name-username o-form-control">
            <TextField
              label="Password"
              name="password"
              variant="outlined"
              className="Textfield-formControl SiginIn-password"
              data-test="password-textfield"
              fullWidth
              autoComplete="off"
              value={newPasswordValue}
              type={showPassword.newPassword ? 'text' : 'password'}
              onChange={handleChangeNewPassword}
              onFocus={handleFocusNewPassword}
              helperText={helperText}
              error={passwordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      data-test="password-visibility"
                    >
                      {showPassword.newPassword ? 'Hide' : 'Show'}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </span>

          {!passwordValidation.newPassword.full && (
            <>
              <ul className={`password-requirements-container ${showPasswordValidationContent.newPassword}`}>
                <li
                  className={passwordValidation.newPassword.lowercase ? colorPrimary : ''}
                  data-test="lowercase-validation"
                >
                  <span className={passwordValidation.newPassword.lowercase ? iconCheckmark : iconsClose}></span>
                  One lowercase letter
                </li>
                <li
                  className={passwordValidation.newPassword.capital ? colorPrimary : ''}
                  data-test="uppercase-validation"
                >
                  <span className={passwordValidation.newPassword.capital ? iconCheckmark : iconsClose}></span>
                  One uppercase letter
                </li>
                <li className={passwordValidation.newPassword.digit ? colorPrimary : ''} data-test="number-validation">
                  <span className={passwordValidation.newPassword.digit ? iconCheckmark : iconsClose}></span>
                  One number
                </li>
              </ul>
              <ul className={`password-requirements-container ${showPasswordValidationContent.newPassword}`}>
                <li
                  className={passwordValidation.newPassword.special ? colorPrimary : ''}
                  data-test="special-character-validation"
                >
                  <span className={passwordValidation.newPassword.special ? iconCheckmark : iconsClose}></span>
                  One special character
                </li>
                <li
                  className={passwordValidation.newPassword.length ? colorPrimary : ''}
                  data-test="character-length-validation"
                >
                  <span className={passwordValidation.newPassword.length ? iconCheckmark : iconsClose}></span>8
                  characters minimum
                </li>
                <li
                  className={passwordValidation.newPassword.email ? colorPrimary : ''}
                  data-test="username-validation"
                >
                  <span className={passwordValidation.newPassword.email ? iconCheckmark : iconsClose}></span>
                  Can&apos;t contain your email
                </li>
              </ul>
            </>
          )}

          {passwordValidation.newPassword.full && (
            <ul className="password-requirements-container full">
              <li className={colorPrimary} data-test="password-complete-validation">
                <span className={iconCheckmark} />
                Nice work. This is an excellent password.
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default Password
