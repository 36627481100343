import * as helper from 'utils/HelperFunction'
import * as logging from 'utils/Logging'

import { CreateQuoteRequest, CreateQuoteResponse, ReferenceDataItem } from 'api/BorrowerPublicApiSchemas'

import { LoanQuoteDetailsResponse } from '../../loanQuote/Model'
import { borrowerPublicApiClient } from 'api/BorrowerPublicApiClient'
import { useState } from 'react'

interface UseLoanApiRequestData {
  loanPurposeDetails: ReferenceDataItem[] | undefined
  apiResponseLoanIntro: string | null
  fetchLoanPurposeDetails: (setLoading: (loading: boolean) => void) => void
  submitQuoteApplication: (
    quoteRequest: CreateQuoteRequest,
    setLoading: (loading: boolean) => void,
    setApiError: React.Dispatch<React.SetStateAction<boolean>>,
  ) => void
  quoteResponse: CreateQuoteResponse | undefined
  quoteDetails: LoanQuoteDetailsResponse
}

const UseQuoteApiRequest = (): UseLoanApiRequestData => {
  const borrowerPublicApi = borrowerPublicApiClient()

  const [loanPurposeDetails, setLoanPurposeDetails] = useState<ReferenceDataItem[] | undefined>([])
  const [apiResponseLoanIntro, setApiResponseLoanIntro] = useState<string>('')

  const [reponseQuoteRequest, setReponseQuoteRequest] = useState<CreateQuoteResponse | undefined>()
  const [quoteDetails, setQuoteDetails] = useState({
    message: '',
    quote: {
      amount: 0,
      monthlyCost: 0,
      duration: 0,
      apr: 0,
      interestRate: 0,
      arrangementFee: 0,
      totalPayable: 0,
    },
    reference: '',
  })

  const fetchLoanPurposeDetails = (setLoading: (loading: boolean) => void) => {
    borrowerPublicApi
      .getLoanPurposes()
      .then(
        (quoteIntroduction) => {
          setLoanPurposeDetails(quoteIntroduction)
        },
        (error) => {
          if (error && error.data && error.data.message) {
            setApiResponseLoanIntro(error.data.message)
            logging.logError('fetchLoanPurposeDetails', error)
          }
        },
      )
      .finally(() => {
        if (helper.checkIsLoadingFunction(setLoading)) {
          setLoading(false)
        }
      })
  }

  const submitQuoteApplication = (
    quoteRequest: CreateQuoteRequest,
    setLoading: (loading: boolean) => void,
    setApiError: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    setApiError(false)
    borrowerPublicApi
      .postCreateQuoteRequest(quoteRequest)
      .then((quoteResponse) => {
        setReponseQuoteRequest(quoteResponse)
        setQuoteDetails({
          message: quoteResponse.message,
          quote: {
            amount: quoteResponse.quote.amount,
            monthlyCost: quoteResponse.quote.monthlyCost,
            duration: quoteResponse.quote.duration,
            apr: quoteResponse.quote.apr,
            interestRate: quoteResponse.quote.interestRate,
            arrangementFee: quoteResponse.quote.arrangementFee,
            totalPayable: quoteResponse.quote.totalPayable,
          },
          reference: quoteResponse.quote.reference,
        })
      })
      .catch((error) => {
        setApiError(true)
        logging.logError('submitQuoteApplication', error)
      })
      .finally(() => {
        if (helper.checkIsLoadingFunction(setLoading)) {
          setLoading(false)
        }
      })
  }

  return {
    loanPurposeDetails,
    apiResponseLoanIntro,
    fetchLoanPurposeDetails,
    submitQuoteApplication,
    quoteResponse: reponseQuoteRequest,
    quoteDetails,
  }
}
export default UseQuoteApiRequest
