import './FeedbackIndicators.scss'

import { Alert, AlertTitle, Box, Collapse, IconButton } from '@mui/material'

import ButtonComponent from '../Button/Button'
import { FeedbackIndicatorsProps } from './FeedbackIndicators.types'

const FeedbackIndicatorsComponent = (props: FeedbackIndicatorsProps): JSX.Element => {
  return (
    <Collapse in={props.isOpen}>
      <Alert
        data-test="feedback-component"
        icon={false}
        severity={props.severity}
        className={props.className}
        action={
          props.closeAction && (
            <IconButton
              data-testid="test-close-icon"
              data-test="feedback-close-icon"
              className={`IconButton-system-close IconButton-system-closeBackground-${props.severity}`}
              aria-label="close"
              color="inherit"
              onClick={props.parentCallback}
              size="large"
            >
              <span className="lw-system-icons-close-x icons-fs-16"></span>
            </IconButton>
          )
        }
      >
        {props.icon && (
          <div className="Alert-icon" data-test="feedback-alert-icon">
            <span className={`${props.icon} icon-bold`}></span>
          </div>
        )}
        <div className="Alert-message">
          {props.title && <AlertTitle data-test="feedback-title">{props.title}</AlertTitle>}

          <div data-test="feedback-messages" dangerouslySetInnerHTML={{ __html: props.messages }} />

          {props.buttonLabel && (
            <Box mt={2}>
              <ButtonComponent
                data-test="feedback-button"
                variant="outlined"
                size="small"
                color="inherit"
                label={props.buttonLabel}
                buttonClick={props.buttonClick}
              />
            </Box>
          )}
        </div>
      </Alert>
    </Collapse>
  )
}

FeedbackIndicatorsComponent.defaultProps = {
  severity: 'success',
  className: '',
  icon: 'string',
  closeAction: false,
  title: '',
  messages: '',
}

export default FeedbackIndicatorsComponent
