import './PasswordTextfield.scss'

import * as features from 'utils/Features'

import { IconButton, InputAdornment, TextField } from '@mui/material'

import { useState } from 'react'

type Props = {
  parentCallback: (newPasswordVal: string, confirmPasswordVal: string) => void
  errorMessages: { newPassword?: string; confirmPassword?: string }
  email: string
}

const ResetPassword = ({ parentCallback, errorMessages, email }: Props): JSX.Element => {
  const iconCheckmark = 'lw-marketing-icons-checkmark icons-colorPrimary'
  const iconsClose = 'lw-system-icons-close-x'
  const colorPrimary = 'icons-colorPrimary'

  const [newPasswordValue, setNewPasswordValue] = useState('')

  const [confirmPasswordValue, setConfirmPasswordValue] = useState('')

  //  new password
  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    confirmPassword: false,
  })

  const [showPasswordValidationContent, setShowPasswordValidationContent] = useState({
    newPassword: 'hide',
    confirmPassword: 'hide',
  })

  const [passwordValidation, setPasswordValidation] = useState({
    newPassword: {
      email: false,
      capital: false,
      lowercase: false,
      digit: false,
      special: false,
      length: false,
      full: false,
    },
  })

  const handleClickShowNewPassword = () =>
    setShowPassword({
      ...showPassword,
      newPassword: !showPassword.newPassword,
    })

  const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
  }

  const handleChangeNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value as string
    setPasswordValidation({ ...passwordValidation, newPassword: features.passwordValidate(newValue, email) })
    setNewPasswordValue(newValue)
    parentCallback(newValue, confirmPasswordValue)
  }

  const handleFocusNewPassword = () => {
    setShowPasswordValidationContent({ ...showPasswordValidationContent, newPassword: 'show' })
  }

  // Confirm new password
  const handleClickShowConfirmPassword = () =>
    setShowPassword({
      ...showPassword,
      confirmPassword: !showPassword.confirmPassword,
    })

  const handleChangeConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value as string
    setConfirmPasswordValue(newValue)
    parentCallback(newPasswordValue, newValue)
  }

  const handleFocusConfirmPassword = () => {
    setShowPasswordValidationContent({ ...showPasswordValidationContent, confirmPassword: 'show' })
  }

  return (
    <div className="o-form-fieldset-container password-reset-container">
      <div className="o-form-fieldset o-form-label-top margin-btm-5 new-password-container">
        <div className="okta-form-label o-form-label">
          <label>New password</label>
        </div>
        <div className="o-form-input">
          <span className="o-form-input-name-username o-form-control">
            <TextField
              name="new-password"
              type={showPassword.newPassword ? 'text' : 'password'}
              autoComplete="off"
              onChange={handleChangeNewPassword}
              onFocus={handleFocusNewPassword}
              error={errorMessages && errorMessages.newPassword && errorMessages.newPassword.length > 0 ? true : false}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword.newPassword ? 'Hide' : 'Show'}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </span>

          {!passwordValidation.newPassword.full && (
            <>
              <ul className={`password-requirements-container ${showPasswordValidationContent.newPassword}`}>
                <li className={passwordValidation.newPassword.lowercase ? colorPrimary : ''}>
                  <span className={passwordValidation.newPassword.lowercase ? iconCheckmark : iconsClose}></span>
                  One lowercase letter
                </li>
                <li className={passwordValidation.newPassword.capital ? colorPrimary : ''}>
                  <span className={passwordValidation.newPassword.capital ? iconCheckmark : iconsClose}></span>
                  One uppercase letter
                </li>
                <li className={passwordValidation.newPassword.digit ? colorPrimary : ''}>
                  <span className={passwordValidation.newPassword.digit ? iconCheckmark : iconsClose}></span>One number
                </li>
              </ul>
              <ul className={`password-requirements-container ${showPasswordValidationContent.newPassword}`}>
                <li className={passwordValidation.newPassword.special ? colorPrimary : ''}>
                  <span className={passwordValidation.newPassword.special ? iconCheckmark : iconsClose}></span>One
                  special character
                </li>
                <li className={passwordValidation.newPassword.length ? colorPrimary : ''}>
                  <span className={passwordValidation.newPassword.length ? iconCheckmark : iconsClose}></span>8
                  characters minimum
                </li>
                <li className={passwordValidation.newPassword.email ? colorPrimary : ''}>
                  <span className={passwordValidation.newPassword.email ? iconCheckmark : iconsClose}></span>Can&apos;t
                  contain your email
                </li>
              </ul>
            </>
          )}

          {passwordValidation.newPassword.full && (
            <ul className="password-requirements-container full">
              <li className={colorPrimary}>
                <span className={iconCheckmark}></span>Nice work. This is an excellent password.
              </li>
            </ul>
          )}
        </div>
      </div>

      <div className="o-form-fieldset o-form-label-top margin-btm-30">
        <div className="okta-form-label o-form-label">
          <label>Confirm new password</label>
        </div>
        <div className="o-form-input">
          <span className="o-form-input-name-password o-form-control">
            <TextField
              name="confirm-new-password"
              type={showPassword.confirmPassword ? 'text' : 'password'}
              autoComplete="off"
              onChange={handleChangeConfirmPassword}
              onFocus={handleFocusConfirmPassword}
              error={
                errorMessages && errorMessages.confirmPassword && errorMessages.confirmPassword.length > 0
                  ? true
                  : false
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword.confirmPassword ? 'Hide' : 'Show'}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </span>

          {passwordValidation.newPassword.full &&
            newPasswordValue.length !== 0 &&
            confirmPasswordValue.length !== 0 &&
            newPasswordValue !== confirmPasswordValue && (
              <ul className="password-requirements-container full">
                <li className="icons-colorCrimson">
                  <span className={iconsClose}></span>Passwords don&apos;t match
                </li>
              </ul>
            )}
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
