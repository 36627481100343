import { Box, Grid } from '@mui/material'
import * as messages from 'utils/Messages'

const SubmitApplicationConsiderationMessage = (): JSX.Element => {
  return (
    <Grid item xs={12}>
      <Box mb={4} className="Card-shadow MuiAlert-message">
        <div className="Alert-icon" data-test="feedback-alert-icon">
          <span className="lw-marketing-icons-light-bulb-on icon-bg-notice icon-bold"></span>
        </div>
        <div className="Alert-message" data-test="consider-application-alert-message">
          {messages.considerApplicationMessage}
        </div>
      </Box>
    </Grid>
  )
}

export default SubmitApplicationConsiderationMessage
