import * as features from 'utils/Features'
import * as paths from 'utils/Links'
import * as logging from 'utils/Logging'
import * as variable from 'utils/Variable'

import {
  LoanApplicationDetails,
  SubmitLoanApplicationRequest,
  SubmitLoanApplicationResponse,
} from 'api/BorrowerApiSchemas'

import { borrowerApiClient } from 'api/BorrowerApiClient'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { setAndTrackFeatureCookies } from '../../../../api/HeapConfig'
import { StatusDeclined, StatusReferred } from '../../../../utils/LoanStatus'

interface FinalDetailsProps {
  loanApplicationDetails: LoanApplicationDetails | undefined
  loanApplicationResponse: SubmitLoanApplicationResponse | undefined
  errorResponseCode: number
  sectionNumber: {
    AboutYou: number
    Employment: number
    BankDetails: number
  }
  fetchLoanApplicationDetails: (reference: string, setLoading: (loading: boolean) => void) => void
  postSubmitLoanApplication: (
    reference: string,
    data: SubmitLoanApplicationRequest,
    setIsSubmitting: (loading: boolean) => void,
  ) => void
  postApiErrorMessage: string | null
  timeout: boolean
  openBankingReason: string
}

const UseApiRequest = (): FinalDetailsProps => {
  const borrowerApi = borrowerApiClient()
  const [loanApplicationDetails, setLoanApplicationDetails] = useState<LoanApplicationDetails>()
  const [loanApplicationResponse, setLoanApplicationResponse] = useState<SubmitLoanApplicationResponse>()
  const [errorResponseCode, setErrorResponseCode] = useState<number>(0)
  const [sectionNumber, setSectionNumber] = useState({
    AboutYou: 0,
    Employment: 1,
    BankDetails: 2,
  })
  const [postApiErrorMessage, setPostApiErrorMessage] = useState<string | null>('')

  const [timeout, setTimeout] = useState<boolean>(false)
  const [openBankingReason, setOpenBankingReason] = useState<string>('')

  const navigate = useNavigate()

  const fetchLoanApplicationDetails = (reference: string, setLoading: (loading: boolean) => void) => {
    borrowerApi
      .getLoanApplicationDetails(reference)
      .then(
        (data) => {
          if (data.hasSubmittedLoanApplication) {
            navigate(paths.getDashboard())
          }

          setLoanApplicationDetails(data)

          if (!data.mobile) {
            setSectionNumber({
              AboutYou: 1,
              Employment: 2,
              BankDetails: 3,
            })
          } else if (data.mobile && data.sortCode && data.accountNumber) {
            setSectionNumber({
              AboutYou: 0,
              Employment: 0,
              BankDetails: 0,
            })
          }
          borrowerApi.getAccountDetails().then((details) => {
            setAndTrackFeatureCookies(details)
          })
        },
        (error) => {
          setErrorResponseCode(error.code)
          logging.logError('fetchLoanApplicationDetails', error)

          if (
            error.code === variable.httpStatusCodes.badRequest ||
            error.code === variable.httpStatusCodes.internalServerError
          ) {
            setTimeout(true)
          }
        },
      )
      .finally(() => {
        setLoading(false)
      })
  }

  const postSubmitLoanApplication = (
    reference: string,
    data: SubmitLoanApplicationRequest,
    setIsSubmitting: (isSubmitting: boolean) => void,
  ) => {
    features.heapTrack('ApplicationSubmitted')
    borrowerApi
      .postSubmitLoanApplicationRequest(data)
      .then(
        (response) => {
          if (response.decision === StatusDeclined) {
            features.heapTrack('LoanApplicationDeclined')
          } else if (response.decision === StatusReferred) {
            features.heapTrack('LoanApplicationReferred')
          } else {
            features.heapTrack('LoanApplicationAutoApproved')
          }
          setIsSubmitting(false)
          setLoanApplicationResponse(response)
          if (response.openBankingReason) {
            setOpenBankingReason(response.openBankingReason)
          }
        },
        (error) => {
          setPostApiErrorMessage(error.data.message)
          setIsSubmitting(false)
          setErrorResponseCode(error.code)
          logging.logError('postSubmitLoanApplicationRequest', error)
        },
      )
      .finally(() => {
        if (localStorage.getItem('isRedirectCreatePassword')) {
          localStorage.removeItem('isRedirectCreatePassword')
        }
      })
  }

  return {
    loanApplicationDetails,
    loanApplicationResponse,
    errorResponseCode,
    sectionNumber,
    fetchLoanApplicationDetails,
    postApiErrorMessage,
    postSubmitLoanApplication,
    timeout,
    openBankingReason,
  }
}

export default UseApiRequest
