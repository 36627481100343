import './Loader.scss'

type Props = {
  size: number | string
  color: string // white, secondary(green)
}

const Loader = ({ size, color }: Props): JSX.Element => {
  return <span className={`loader ${size} ${color}`}></span>
}

export default Loader
