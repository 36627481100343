import * as variable from 'utils/Variable'

import { Box, Button, CssBaseline, Divider, Grid, Typography } from '@mui/material'
import FeedbackIndicatorsComponent from '../../../../components/FeedbackIndicators/FeedbackIndicators'
import Loader from '../../../../components/Loader/Loader'
import LogoPartners from '../../../../components/LogoPartners/LogoPartners'
import Stepper from '../../../../components/Stepper/Stepper'
import { heapTrack } from '../../../../utils/Features'
import LayoutWrapper from '../../../public/layout/LayoutWrapper'
import OptForCall from '../../loanApplication/referred/OpenBankingMandatory/OptForCall'

const OpenBankingChallengerCopy = (
  openBankingLink: string,
  previouslyFailedToConnect: boolean,
  showOptForCallComponent: boolean,
  setShowOptForCallComponent: (showOptForCallComponent: boolean) => void,
  reference: string,
) => {
  if (showOptForCallComponent) {
    return <OptForCall reference={reference} />
  }

  return (
    <LayoutWrapper className="openbanking-wrapper">
      <CssBaseline />
      <Grid container className="openbanking-container">
        <Grid item xs={12}>
          <div data-test="openbanking-header">
            <Box mb={3} mt={12} className="Card-shadow Headers lines" data-test={'mandatory'}>
              {previouslyFailedToConnect && (
                <FeedbackIndicatorsComponent
                  severity="error"
                  icon="lw-system-icons-close icon-bg-error"
                  title={"We couldn't connect your open banking account."}
                  messages={'Please try again.'}
                  closeAction={false}
                  isOpen={true}
                />
              )}
            </Box>
          </div>
          {openBankingLink === '' && <Loader data-test="openbanking-loader" size="large" color="white" />}
          {openBankingLink !== '' && (
            <div data-test="openbanking-cta">
              <div>
                <Box className="openbanking-stepper">
                  <Stepper step1="completed" step2="active" />
                </Box>
                <Box className="Card-shadow lines">
                  <Typography variant="h3" data-test="openbanking-copy" className="nearly-there top-shadow">
                    Help us complete your application by verifying your income and spending.
                  </Typography>
                  <Divider />
                  <Box className="bud-blurb">
                    <p>
                      The fastest and most popular way to verify this is through our trusted Open Banking partner, Bud.
                    </p>
                    <p>
                      With your consent, Bud will connect to your bank account in just a few clicks, keeping your data
                      safe and secure with bank-grade security.
                    </p>
                    <p>
                      This allows us to view your bank statements to verify your information. We can&apos;t access your
                      login details or money, but it does help us to understand your circumstances as best we can.
                    </p>
                    <p>Once you verify, you'll hear from us within {variable.underwriterContactTimeframe}.</p>
                    <Grid display="flex" alignItems="center" flexDirection="row" className="mandatory-ob-text">
                      <span className="lw-marketing-icons-light-bulb-on icon-bg-notice icon-bold mandatory-ob-light-bulb"></span>
                      Please link the bank account where you receive your salary.
                    </Grid>
                  </Box>
                  <div className={'mandatory-ob-verify-income-button-container'}>
                    <div className="verify-now-button-container">
                      <a
                        href={openBankingLink}
                        data-test="openbanking-link"
                        onClick={() => {
                          heapTrack('OpenBankingClickOut')
                        }}
                      >
                        <Button variant="contained" color="primary">
                          Verify now
                        </Button>
                      </a>
                    </div>
                  </div>
                  <Box className="idk" display="flex">
                    <p>Powered by</p>
                    <Box className="bud-logo-icon">
                      <LogoPartners api="BudWithIcon" />
                    </Box>
                  </Box>
                </Box>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </LayoutWrapper>
  )
}

export default OpenBankingChallengerCopy
