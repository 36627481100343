import './_about.scss'

import * as features from 'utils/Features'
import * as messages from 'utils/Messages'

import { Box, FormHelperText, Grid, TextField, Typography } from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'

type Props = {
  aboutUsError: {
    first: boolean
    last: boolean
    mobile: boolean
    email: boolean
  }
  dateOfBirthError: boolean
  parentCallbackAboutUs: (first: string, last: string, mobile: string, email: string) => void
  parentCallbackDOB: (day: string, month: string, year: string) => void
}

const AboutYou = ({ aboutUsError, dateOfBirthError, parentCallbackAboutUs, parentCallbackDOB }: Props): JSX.Element => {
  const [firstName, setFirstName] = useState<string>('')
  const [firstNameError, setFirstNameError] = useState<boolean>(false)

  const [lastName, setLastName] = useState<string>('')
  const [lastNameError, setLastNameError] = useState<boolean>(false)

  const [mobile, setMobile] = useState<string>('')
  const [mobileError, setMobileError] = useState<boolean>(false)

  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<boolean>(false)

  const [dateOfBirthInputError, setDateOfBirthInputError] = useState<boolean>(false)
  const [dateOfBirthValidationMessages, setDateOfBirthValidationMessages] = useState<string>('')

  useEffect(() => {
    setFirstNameError(aboutUsError.first)
    setLastNameError(aboutUsError.last)
    setMobileError(aboutUsError.mobile)
    setEmailError(aboutUsError.email)

    setDateOfBirthInputError(dateOfBirthError)
    if (dateOfBirthError) {
      setDateOfBirthValidationMessages(messages.dateOfBirthError)
    } else {
      setDateOfBirthValidationMessages(messages.dateOfBirthHelpText)
    }
  }, [aboutUsError, dateOfBirthError])

  const passValueToParent = useCallback(
    (key: string, value: string) => {
      parentCallbackAboutUs(
        key === 'first' ? value : firstName,
        key === 'last' ? value : lastName,
        key === 'mobile' ? value : mobile,
        key === 'email' ? value : email,
      )
    },
    [email, firstName, lastName, mobile, parentCallbackAboutUs],
  )

  const handleFirstName = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFirstNameError(false)
    setFirstName(e.target.value)
    passValueToParent('first', e.target.value)
  }

  const firstNameInputValidation = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = e.target.value

    if (input.length >= 3) {
      setFirstNameError(false)
    } else {
      setFirstNameError(true)
    }
  }

  const handleLastName = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLastNameError(false)
    setLastName(e.target.value)
    passValueToParent('last', e.target.value)
  }

  const lastNameInputValidation = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = e.target.value

    if (input.length >= 3) {
      setLastNameError(false)
    } else {
      setLastNameError(true)
    }
  }

  const handleMobile = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMobileError(false)

    setMobile(e.target.value)
    passValueToParent('mobile', e.target.value)
  }

  const mobileInputValidation = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = e.target.value
    const regexNumberCheck = features.checkMobileNumber(input)

    if (input.length >= 1 && !regexNumberCheck) {
      setMobileError(true)
    } else {
      setMobileError(false)
    }
  }

  const handleEmail = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmailError(false)
    setEmail(e.target.value)
    passValueToParent('email', e.target.value)
  }

  const emailInputValidation = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = e.target.value
    const regexEmailCheck = features.emailValidate(input)

    if (input.length >= 1 && !regexEmailCheck) {
      setEmailError(true)
    } else {
      setEmailError(false)
    }
  }

  /* start dob */
  const [dateOfBirthDay, setDateOfBirthDay] = useState<string>('')
  const [dateOfBirthDayError, setDateOfBirthDayError] = useState<boolean>(false)

  const [dateOfBirthMonth, setDateOfBirthMonth] = useState<string>('')
  const [dateOfBirthMonthError, setDateOfBirthMonthError] = useState<boolean>(false)

  const [dateOfBirthYear, setDateOfBirthYear] = useState<string>('')
  const [dateOfBirthYearError, setDateOfBirthYearError] = useState<boolean>(false)

  const today = new Date()
  const nowYear = today.getFullYear()
  const nowDay = today.getDate()
  const nowMonth = today.getMonth()

  const isBirthday = () => {
    const birth = new Date(`${dateOfBirthYear}-${dateOfBirthMonth}-${dateOfBirthDay}`)
    const birthMonth = birth.getMonth()
    const birthDay = birth.getDate()

    if (birthMonth === nowMonth && birthDay === nowDay) {
      setDateOfBirthValidationMessages(messages.dateOfBirthToday)
    }
  }

  const dayInputValidation = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = e.target.value.padStart(2, '0')
    if (parseInt(input) > 0 && parseInt(input) < 32) {
      setDateOfBirthDayError(false)
      setDateOfBirthValidationMessages(messages.dateOfBirthHelpText)
      setDateOfBirthDay(input)
      parentCallbackDOB(input, dateOfBirthMonth, dateOfBirthYear)
      isBirthday()
    } else {
      setDateOfBirthDayError(true)
      setDateOfBirthValidationMessages(messages.dateOfBirthError)
    }
  }

  const monthInputValidation = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = e.target.value.padStart(2, '0')
    if (parseInt(input) > 0 && parseInt(input) <= 12) {
      setDateOfBirthMonthError(false)
      setDateOfBirthValidationMessages(messages.dateOfBirthHelpText)
      setDateOfBirthMonth(input)
      parentCallbackDOB(dateOfBirthDay, input, dateOfBirthYear)
      isBirthday()
    } else {
      setDateOfBirthMonthError(true)
      setDateOfBirthValidationMessages(messages.dateOfBirthError)
    }
  }

  const yearInputValidation = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = e.target.value.replace(/^0+/, '')
    if (input.length === 4) {
      // check if the user younger than 18
      const userDob = `${input}-${dateOfBirthMonth}-${dateOfBirthDay}`
      if (parseInt(input) > nowYear) {
        setDateOfBirthYearError(true)
        setDateOfBirthValidationMessages(messages.dateOfBirthAgeFuture)
      } else if (features.underAgeValidate(userDob) < 18) {
        setDateOfBirthYearError(true)
        setDateOfBirthValidationMessages(messages.dateOfBirthAgeMin)
      } else if (features.underAgeValidate(userDob) > 116) {
        setDateOfBirthYearError(true)
        setDateOfBirthValidationMessages(messages.dateOfBirthAgeMax)
      } else {
        setDateOfBirthValidationMessages(messages.dateOfBirthHelpText)
        setDateOfBirthYearError(false)
        isBirthday()
      }
    } else {
      setDateOfBirthYearError(true)
      setDateOfBirthValidationMessages(messages.dateOfBirthError)
    }
  }

  const handleDateOfBirthDay = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDateOfBirthInputError(false)
    setDateOfBirthDayError(false)

    setDateOfBirthDay(e.target.value)
    parentCallbackDOB(e.target.value, dateOfBirthMonth, dateOfBirthYear)
  }

  const handleDateOfBirthMonth = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDateOfBirthInputError(false)
    setDateOfBirthMonthError(false)

    setDateOfBirthMonth(e.target.value)
    parentCallbackDOB(dateOfBirthDay, e.target.value, dateOfBirthYear)
  }

  const handleDateOfBirthYear = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDateOfBirthInputError(false)
    setDateOfBirthYearError(false)

    setDateOfBirthYear(e.target.value)
    parentCallbackDOB(dateOfBirthDay, dateOfBirthMonth, e.target.value)
  }
  /* end dob */

  return (
    <Box className="Card-shadow">
      <Grid item xs={12} className="Account-content line-separate" data-test="about-you-container">
        <Box>
          <Typography variant="h4">2. About you</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} className="Account-content">
        <Box mb={2} data-test="first-name-container">
          <TextField
            fullWidth
            label="First name"
            value={firstName}
            onChange={handleFirstName}
            onBlur={firstNameInputValidation}
            error={firstNameError}
            helperText={firstNameError ? messages.firstNameRequired : ''}
          />
        </Box>
        <Box mb={2} data-test="last-name-container">
          <TextField
            fullWidth
            label="Last name"
            value={lastName}
            onChange={handleLastName}
            onBlur={lastNameInputValidation}
            error={lastNameError}
            helperText={lastNameError ? messages.lastNameRequired : ''}
          />
        </Box>
        <Box mb={1}>Date of birth</Box>
        <Box mb={2} data-test="date-of-birthday-container">
          <TextField
            label="Day"
            id="day"
            className="dateOfBirthday dateOfBirthday-day"
            autoComplete="off"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 2 }}
            value={dateOfBirthDay}
            onInput={features.allowNumbersInput}
            onChange={handleDateOfBirthDay}
            onBlur={dayInputValidation}
            error={dateOfBirthInputError || dateOfBirthDayError}
            placeholder="DD"
            data-test="date-of-birthday-day-input"
            type="text"
          />
          <TextField
            label="Month"
            id="month"
            className="dateOfBirthday"
            autoComplete="off"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 2 }}
            value={dateOfBirthMonth}
            onInput={features.allowNumbersInput}
            onChange={handleDateOfBirthMonth}
            onBlur={monthInputValidation}
            error={dateOfBirthInputError || dateOfBirthMonthError}
            placeholder="MM"
            data-test="date-of-birthday-month-input"
            type="text"
          />
          <TextField
            label="Year"
            id="year"
            className="dateOfBirthday dateOfBirthday-year"
            autoComplete="off"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 4 }}
            value={dateOfBirthYear}
            onInput={features.allowNumbersInput}
            onChange={handleDateOfBirthYear}
            onBlur={yearInputValidation}
            error={dateOfBirthInputError || dateOfBirthYearError}
            placeholder="YYYY"
            data-test="date-of-birthday-year-input"
            type="text"
          />

          <FormHelperText
            className={`MuiFormHelperText-root  ${
              dateOfBirthInputError || dateOfBirthDayError || dateOfBirthMonthError || dateOfBirthYearError
                ? 'Mui-error'
                : ''
            }`}
            style={{ paddingLeft: '14px' }}
            data-test="date-of-birthday-help-text"
          >
            {dateOfBirthValidationMessages}
          </FormHelperText>
        </Box>

        <Box mb={2} data-test="mobile-number-container">
          <TextField
            label="Mobile number"
            id="mobile"
            className="Textfield-formControl"
            autoComplete="off"
            fullWidth
            inputProps={{ inputMode: 'tel', maxLength: 11 }}
            value={mobile}
            onInput={features.allowNumbersInput}
            onChange={handleMobile}
            onBlur={mobileInputValidation}
            data-test="mobile-number"
            error={mobileError}
            helperText={mobileError ? messages.addValidMobileNumber : messages.phoneHelpText}
            type="text"
          />
        </Box>
        <Box data-test="email-address-container">
          <TextField
            label="Email address"
            id="email"
            className="Textfield-formControl"
            autoComplete="off"
            fullWidth
            inputProps={{ inputMode: 'email' }}
            value={email}
            onChange={handleEmail}
            onBlur={emailInputValidation}
            data-test="email-address"
            error={emailError}
            helperText={emailError ? messages.emailError : messages.emailHelpText}
            type="text"
          />
        </Box>
      </Grid>
    </Box>
  )
}

export default AboutYou
