import * as helper from 'utils/HelperFunction'
import * as logging from 'utils/Logging'

import { CreateQuoteAuthenticatedRequest, CreateQuoteAuthenticatedResponse } from 'api/BorrowerApiSchemas'

import { LoanQuoteDetailsResponse } from '../../loanQuote/Model'
import { borrowerApiClient } from 'api/BorrowerApiClient'
import { useState } from 'react'

interface UseLoanApiRequestData {
  quoteDetails: LoanQuoteDetailsResponse
  responseQuoteRequestAuthenticated: CreateQuoteAuthenticatedResponse | undefined
  submitQuoteApplicationLogged: (
    quoteRequest: CreateQuoteAuthenticatedRequest,
    setLoading: (loading: boolean) => void,
    setApiError: React.Dispatch<React.SetStateAction<boolean>>,
  ) => void
}

const UseQuoteApiRequestAuthenticated = (): UseLoanApiRequestData => {
  const borrowerApi = borrowerApiClient()
  const [authenticatedQuoteResponse, setAuthenticatedQuoteResponse] = useState<
    CreateQuoteAuthenticatedResponse | undefined
  >()

  const [quoteDetails, setQuoteDetails] = useState({
    message: '',
    quote: {
      amount: 0,
      monthlyCost: 0,
      duration: 0,
      apr: 0,
      interestRate: 0,
      arrangementFee: 0,
      totalPayable: 0,
    },
    reference: '',
  })

  const submitQuoteApplicationLogged = (
    quoteRequest: CreateQuoteAuthenticatedRequest,
    setLoading: (loading: boolean) => void,
    setApiError: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    setApiError(false)
    borrowerApi
      .postCreateQuoteAuthenticatedRequest(quoteRequest)
      .then((quoteResponse) => {
        setAuthenticatedQuoteResponse(quoteResponse)
        setQuoteDetails({
          message: quoteResponse.message,
          quote: {
            amount: quoteResponse.quote.amount,
            monthlyCost: quoteResponse.quote.monthlyCost,
            duration: quoteResponse.quote.duration,
            apr: quoteResponse.quote.apr,
            interestRate: quoteResponse.quote.interestRate,
            arrangementFee: quoteResponse.quote.arrangementFee,
            totalPayable: quoteResponse.quote.totalPayable,
          },
          reference: quoteResponse.quote.reference,
        })
      })
      .catch((error) => {
        setApiError(true)
        logging.logError('submitQuoteApplicationLogged', error)
      })
      .finally(() => {
        if (helper.checkIsLoadingFunction(setLoading)) {
          setLoading(false)
        }
      })
  }

  return {
    submitQuoteApplicationLogged,
    responseQuoteRequestAuthenticated: authenticatedQuoteResponse,
    quoteDetails,
  }
}
export default UseQuoteApiRequestAuthenticated
