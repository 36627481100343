import '../../partnerIntroduction/_partnerIntroduction.scss'

import { Box, TextField } from '@mui/material'

import Password from 'components/PasswordTextfield/Password'
import { useCallback } from 'react'

type Props = {
  email: string
  helperText?: string
  oktaErrorMessages: string
  newUser: boolean
  passwordError: boolean
  registeredUser: boolean
  apiResponseSuccessMessages: string
  parentCallback: (password: string) => void
  setPasswordError: (password: boolean) => void
}

const SignInAndCreateAccountForm = ({
  email,
  parentCallback,
  helperText,
  newUser,
  oktaErrorMessages,
  passwordError,
  setPasswordError,
  registeredUser,
  apiResponseSuccessMessages,
}: Props): JSX.Element => {
  const callback = useCallback(
    (password: string) => {
      parentCallback(password)
    },
    [parentCallback],
  )

  return (
    <div className="email-password">
      <Box mb={2} data-test="username-textfield">
        <TextField label="Email" value={email} fullWidth disabled />
      </Box>
      <Box mb={3} className="intro-sign-in" data-test="password-container">
        <Password
          parentCallback={callback}
          errorMessages={{
            newPassword: oktaErrorMessages,
          }}
          email={email}
          helperText={helperText}
          newUser={newUser}
          passwordError={passwordError}
          registeredUser={registeredUser}
          setPasswordError={setPasswordError}
          apiResponseSuccessMessages={apiResponseSuccessMessages}
        />
      </Box>
    </div>
  )
}

export default SignInAndCreateAccountForm
