import './_sideMenu.scss'

import { List } from '@mui/material'
import SideMenuItem from './SideMenuItem'
import { sideTopMenuItems } from 'utils/LinksMenu'

type Props = { name: string; link: string; icon: string }

const SideTopMenu = (props: SideMenuProp): JSX.Element => {
  return (
    <List component="nav" disablePadding>
      {sideTopMenuItems.map((item: Props, index: number) => (
        <SideMenuItem {...item} key={index} {...props} />
      ))}
    </List>
  )
}

export interface SideMenuProp {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

export default SideTopMenu
