import './_underwriter.scss'

import * as features from 'utils/Features'
import * as paths from 'utils/Links'
import * as variable from 'utils/Variable'

import { Box, Button, CssBaseline, Grid, Typography } from '@mui/material'

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { customerServiceNumber } from 'utils/Variable'
import LayoutWrapper from '../../../public/layout/LayoutWrapper'

const ReferralUnderwriter = (): JSX.Element => {
  const navigate = useNavigate()

  const dashboard = () => {
    navigate(paths.getDashboard())
  }

  useEffect(() => {
    features.heapTrack('ReferralUnderwriterComponent')
  }, [])

  return (
    <LayoutWrapper className="PublicLoanContainer-underwriter-container">
      <CssBaseline />
      <Grid container className="underwriter-container" data-test="uw-container">
        <Grid item xs={12}>
          <div className="uw-title-container">
            <div className="uw-status-container">
              <Box display="flex">
                <Box className="icon-container">
                  <div className="fluro-icon-search ">
                    <span className="path1 icons-colorAzurite icons-fs-50"></span>
                    <span className="path2 icons-colorAzurite icons-fs-50"></span>
                    <span className="path3 icons-colorAzurite icons-fs-50"></span>
                  </div>
                </Box>
              </Box>
              <Box display="flex">
                <Box flexGrow={2} className="title-box" data-test="uw-nearly-there-box">
                  <Typography variant="h1">Nearly there!</Typography>
                </Box>
              </Box>
              <Box display="flex">
                <Box className="paragraph-box" data-test="uw-message-box">
                  <p>We need to do a few more checks.</p>
                </Box>
              </Box>
            </div>
            <div className="uw-info">
              <div className="uw-message" data-test="uw-info">
                <p>
                  One of our underwriters will now review your application. We will aim to contact you within one
                  business day with a final decision. Should we need to call you, we will do so from{' '}
                  {customerServiceNumber}
                </p>
              </div>
              <div>
                <Button data-test="uw-goto-dashboard-btn" variant="contained" color="primary" onClick={dashboard}>
                  Go to dashboard
                </Button>
              </div>
              <div className="secure-process">
                <span className="fluro-icon-lock icons-colorBlack icons-fs-20" />
                <span>Secure, encrypted process</span>
              </div>
            </div>
          </div>
          <div className="block-info" data-test="uw-check-email">
            <span className="lw-marketing-icons-light-bulb-on icons-colorInkDark icons-bg-colorSkyLight icons-font-bold icons-bg-round icons-fs-18" />
            <p>
              Please keep an eye on your email for any updates on your application. If you need any help please contact
              us on{' '}
              <a
                href={`tel: ${variable.customerServiceNumberCall}`}
                data-test="customer-service-number-cta"
                aria-label="Referral Underwriter - click call cs number"
              >
                {variable.customerServiceNumber}
              </a>{' '}
              or{' '}
              <a
                href={`mailto:${variable.underwriterEmail}`}
                target="_blank"
                rel="noreferrer"
                aria-label="Referral Underwriter - click call cs email"
              >
                {variable.underwriterEmail}
              </a>
              .
            </p>
          </div>
        </Grid>
      </Grid>
    </LayoutWrapper>
  )
}

export default ReferralUnderwriter
