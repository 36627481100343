import PartnersSVG from './Logo'

type Props = {
  api: string
}

const LogoPartners = ({ api }: Props): JSX.Element => {
  return (
    <>
      {(api === 'Accepty1' || api === 'Accepty2') && <img src={PartnersSVG.Accepty} alt="" />}
      {(api === 'ASDA' || api === 'ASDA(H)') && <img src={PartnersSVG.AsdaMoney} alt="" />}
      {(api === 'Churchill Loans' ||
        api === 'Churchill Loans (Compare the Market)' ||
        api === 'Churchill Loans (MSM)') && <img src={PartnersSVG.Churchill} alt="" />}
      {api === 'ClearScore' && <img src={PartnersSVG.ClearScore} alt="" />}
      {api === 'RPD-CTM' && <img src={PartnersSVG.CompareTheMarket} alt="" />}
      {(api === 'RPD-CNF' || api === 'RPD-CNFA' || api === 'AFF21') && <img src={PartnersSVG.Confused} alt="" />}
      {api === 'CredAbility (Accepty)' && <img src={PartnersSVG.CredAbility} alt="" />}
      {api === 'Bud' && <img src={PartnersSVG.Bud} alt="" />}
      {api === 'BudWithIcon' && <img src={PartnersSVG.BudWithIcon} alt="" />}
      {api === 'dll' && <img src={PartnersSVG.DirectLine} alt="" />}
      {api === 'ES Money' && <img src={PartnersSVG.ESMoney} alt="" />}
      {(api === 'Experian' || api === 'EXP' || api === 'Experian Credit Matcher') && (
        <img src={PartnersSVG.Experian} alt="" />
      )}
      {api === 'Finder.com' && <img src={PartnersSVG.Finder} alt="" />}
      {api === 'Fluent Loans' && <img src={PartnersSVG.FluentLoans} alt="" />}
      {api === 'Forbes Advisor' && <img src={PartnersSVG.ForbesAdvisor} alt="" />}
      {api === 'Freedom Finance' && <img src={PartnersSVG.FreedomFinance} alt="" />}
      {(api === 'RPD-GCO' || api === 'RPD-GCOA') && <img src={PartnersSVG.GoCompare} alt="" />}
      {api === 'WLP-HIT' && <img src={PartnersSVG.Hitched} alt="" />}
      {(api === 'Monevo (Confused.com)' ||
        api === 'Monevo (Credit Karma)' ||
        api === 'Monevo (Money.co.uk)' ||
        api === 'Monevo (Money Guru)') && <img src={PartnersSVG.Monevo} alt="" />}
      {api === 'AFF6' && <img src={PartnersSVG.Money} alt="" />}
      {api === 'MGU' && <img src={PartnersSVG.MoneyGuru} alt="" />}
      {api === 'MoneySavingExpert' && <img src={PartnersSVG.MoneySavingExpert} alt="" />}
      {(api === 'MoneySupermarket' || api === 'Churchill Loans (MSM)' || api === 'AFF30' || api === 'AFF29') && (
        <img src={PartnersSVG.MSM} alt="" />
      )}
      {api === 'Norton Finance' && <img src={PartnersSVG.NortonFinance} alt="" />}
      {api === 'Ocean Finance' && <img src={PartnersSVG.OceanFinance} alt="" />}

      {(api === 'REV' || api === 'REV2') && <img src={PartnersSVG.Revolut} alt="" />}
      {(api === 'RPD-DFT' || api === 'RPD-DET' || api === 'RPD-RPD') && <img src={PartnersSVG.Runpath} alt="" />}
      {api === 'Simply Money' && <img src={PartnersSVG.SimplyMoney} alt="" />}
      {api === 'SmarterSavings' && <img src={PartnersSVG.SmarterSavings} alt="" />}
      {(api === 'AFF3' || api === 'TMO') && <img src={PartnersSVG.TotallyMoney} alt="" />}
      {api === 'Fluro' && <img src={PartnersSVG.Fluro} alt="" />}
      {api === 'INT14' && <img src={PartnersSVG.LoansWarehouse} alt="" />}
    </>
  )
}

export default LogoPartners
