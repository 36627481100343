import { FileError, FileRejection, useDropzone } from 'react-dropzone'
import { Grid, Typography } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { Button } from '@mui/material'
import { SingleFileUploadWithProgress } from './SingleFileUploadWithProgress'
import { UploadError } from './UploadError'
import { useField } from 'formik'

let currentId = 0

function getNewId() {
  return ++currentId
}

export interface UploadableFile {
  id: number
  file: File
  errors: FileError[]
  url?: string
}

const activeStyle = {
  borderColor: '#7A953D',
  background: 'rgba(122,149,61,0.1)',
}

const acceptStyle = {
  borderColor: '#472A87',
  background: '#F4EFFF',
}

const rejectStyle = {
  borderColor: '#BF0711',
  background: '#FDF4F2',
}

export function MultipleFileUploadField({ name }: { name: string }): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, __, helpers] = useField(name)

  const [files, setFiles] = useState<UploadableFile[]>([])
  const [rejectFiles, setRejectFiles] = useState<UploadableFile[]>([])

  const onDrop = useCallback((accFiles: File[], rejFiles: FileRejection[]) => {
    const mappedAcc = accFiles.map((file) => ({ file, errors: [], id: getNewId() }))
    const mappedRej = rejFiles.map((r) => ({ ...r, id: getNewId() }))
    setFiles((curr) => [...mappedAcc, ...curr])
    setRejectFiles((curr) => [...mappedRej, ...curr])
  }, [])

  useEffect(() => {
    helpers.setValue(files)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  function onUpload(file: File, url: string) {
    setFiles((curr) =>
      curr.map((fw) => {
        if (fw.file === file) {
          return { ...fw, url }
        }
        return fw
      }),
    )
  }

  function onDelete(file: File) {
    setFiles((curr) => curr.filter((fw) => fw.file !== file))
  }

  function onDeleteRejFiles(file: File) {
    setRejectFiles((curr) => curr.filter((fw) => fw.file !== file))
  }

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
      'application/*': ['.pdf'],
    },
    maxSize: 10000 * 1024, // 10000KB (10MB)
  })

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  )

  return (
    <>
      {rejectFiles.map((fileWrapper) => (
        <Grid item className="no-padding" key={`grid-${fileWrapper.id}`}>
          {fileWrapper.errors.length > 0 ? (
            <UploadError file={fileWrapper.file} onDelete={onDeleteRejFiles} errors={fileWrapper.errors} />
          ) : (
            ''
          )}
        </Grid>
      ))}

      <Grid className="document-upload-grid" item>
        <div {...getRootProps({ style })} className="uploadDocumentContainer">
          <input {...getInputProps()} />

          {isDragActive ? (
            <>
              <p
                className={`lw-system-icons-cloud-upload icons-fs-30 ${
                  isDragReject ? 'text-colorError' : 'icons-colorPrimary'
                } `}
                style={{ marginTop: '56px', marginBottom: '0' }}
              ></p>
              <Typography className={`${isDragReject ? 'text-colorErrorDark' : 'text-colorPrimaryDark'}`}>
                Drop files to add
              </Typography>
            </>
          ) : (
            <>
              <p
                className="lw-marketing-icons-file-uploaded icons-fs-55 icons-colorInk"
                style={{ marginBottom: '24px' }}
              ></p>
              <Button
                color="inherit"
                size="small"
                variant="outlined"
                className="Button-addFiles"
                data-test="upload-document-add-files-btn"
              >
                Add files
              </Button>
              <div style={{ marginTop: '10px' }}>
                <Typography className="drop-files-text" variant="caption" color="textPrimary">
                  or drop files to add
                </Typography>
              </div>
            </>
          )}
        </div>

        <Typography
          variant="caption"
          color="textPrimary"
          className="upload-document-format-text"
          data-test="upload-document-format-text"
        >
          Files accepted: JPG, JPEG, PNG or PDF. Maximum file size: 10MB.
        </Typography>
      </Grid>

      <div className="FileUploadContainer">
        {files.map((fileWrapper) => (
          <Grid item key={fileWrapper.id} className="FileUploadContainerGrid" data-test="file-upload-container-grid">
            <SingleFileUploadWithProgress onDelete={onDelete} onUpload={onUpload} file={fileWrapper.file} />
          </Grid>
        ))}
      </div>
    </>
  )
}
