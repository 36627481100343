import { useEffect, useRef } from 'react'

declare global {
  interface Window {
    Trustpilot: { loadFromElement: (arg0: null, arg1: boolean) => void }
  }
}
const TrustPilotBox = (): JSX.Element => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = useRef(null)

  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])
  return (
    <div
      ref={ref} // We need a reference to this element to load the TrustBox in the effect.
      className="trustpilot-widget"
      data-locale="en-GB"
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="6304e8f191601441fb809a43"
      data-style-height="130px"
      data-style-width="100%"
      data-theme="light"
      data-tags="SelectedReview"
      data-test="trustpilot"
    >
      <a href="https://uk.trustpilot.com/review/www.fluro.co.uk" target="_blank" rel="noopener noreferrer">
        Trustpilot
      </a>
    </div>
  )
}

export default TrustPilotBox
