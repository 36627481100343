import './TextfieldBaseline.scss'

import TextField, { TextFieldProps } from '@mui/material/TextField'

import InputAdornment from '@mui/material/InputAdornment'

type DataTestProp = {
  dataTest?: string
}

const TextfieldCurrencyBaseline = (props: TextFieldProps & DataTestProp): JSX.Element => {
  return (
    <TextField
      id={props.id || 'filled-basic'}
      className="Textfield-formControl"
      variant={props.variant || 'outlined'}
      label={props.label || ''}
      helperText={props.helperText}
      error={props.error}
      disabled={props.disabled}
      InputProps={{
        startAdornment: <InputAdornment position="start">£</InputAdornment>,
      }}
      inputProps={{ inputMode: 'numeric', pattern: '^([0-9]+,?(.\\d{2})?)*$' }}
      type="text"
      onChange={props.onChange}
      onBlur={props.onBlur}
      value={props.value}
      autoComplete="off"
      fullWidth={props.fullWidth}
      data-test={props.dataTest}
    />
  )
}

export default TextfieldCurrencyBaseline
