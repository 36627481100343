import * as features from 'utils/Features'
import * as messages from 'utils/Messages'

import { Box, Grid, TextField } from '@mui/material'

import { useFormikContext } from 'formik'
import * as Yup from 'yup'
import { LoanApplicationFormValues } from './LoanApplicationForm'

type Props = {
  step: number
}

export const AboutYouLoggedInFormValidationSchema = Yup.object({
  mobile: Yup.string()
    .matches(/^(07\d{9})$/, { message: messages.addValidMobileNumber })
    .required(messages.addValidMobileNumber),
})

export interface AboutYouLoggedInFormValues {
  mobile: string
}

const AboutYou = ({ step }: Props): JSX.Element => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<LoanApplicationFormValues>()

  return (
    <>
      <Grid item xs={12} className="Account-content line-separate" data-test="about-you-container">
        <Box>
          <strong>{step}. About you</strong>
        </Box>
      </Grid>
      <Grid item xs={12} className="Account-content " data-test="about-you-mobile-input">
        <Box>
          <TextField
            label="Mobile number"
            id="mobile"
            className="Textfield-formControl"
            autoComplete="off"
            fullWidth
            inputProps={{ inputMode: 'tel', maxLength: 11 }}
            value={values.mobile}
            onInput={features.allowNumbersInput}
            onChange={handleChange}
            onBlur={handleBlur}
            data-test="mobile-number"
            error={touched.mobile && Boolean(errors.mobile)}
            helperText={touched.mobile && Boolean(errors.mobile) ? errors.mobile : messages.phoneHelpText}
            type="text"
          />
        </Box>
      </Grid>
    </>
  )
}

export default AboutYou
