import { toRelativeUrl } from '@okta/okta-auth-js'
import { useOktaAuth } from '@okta/okta-react'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import RequiredAuthLayout from 'RequiredAuthLayout'
import './assets/styles/global.scss'
import './_app.scss'

export const RequiredAuth: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (authState?.isAuthenticated === false || !oktaAuth.getAccessToken()) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin)
      oktaAuth.setOriginalUri(originalUri)
      navigate('/sign-in')
    }
  }, [oktaAuth, authState, authState?.isAuthenticated, navigate])

  return authState?.isAuthenticated ? <RequiredAuthLayout /> : <div>Loading...</div>
}
