import { CssBaseline } from '@mui/material'
import 'view/portal/loanApplication/finalDetails/_finalDetails.scss'

import { useEffect, useState } from 'react'
import * as loanStatus from 'utils/LoanStatus'
import * as variable from 'utils/Variable'

import ApiResponseWrapper from 'view/portal/components/apiResponseWrapper/Home'
import LoanProcessing from 'view/portal/components/checking/Home'
import LoanApplicationTimeout from 'view/portal/components/timeout/Home'
import HeaderNavigationWithLogin from 'view/portal/layout/HeaderNavigationWithLogin'
import UseApiRequestAuth from 'view/portal/loanApplication/finalDetails/UseApiRequest'
import DisplayDecisionContainer from 'view/portal/loanApplication/finalDetails/responseSubmitLoanApplication/Decision'

type Props = {
  users: {
    accountNumber: string
    employer: string
    employmentPosition: string
    employmentSector: string
    employmentStatus: string
    futureChangeOfIncome: boolean
    futureChangeOfIncomeReason?: string
    futureChangeOfIncomeSpecificReason?: string
    mobileNumber: string
    reference: string
    sortCode: string
  }
}

const LoanApplicationRedirect = ({ users }: Props): JSX.Element => {
  const {
    postSubmitLoanApplication,
    openBankingReason,
    errorResponseCode,
    loanApplicationResponse,
    postApiErrorMessage,
  } = UseApiRequestAuth()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(true)
  const [flag, setFlag] = useState(true)

  useEffect(() => {
    if (flag) {
      if (users && users !== undefined) {
        postSubmitLoanApplication(users.reference, users, setIsSubmitting)
        setFlag(false)
      }
    }
  }, [flag, postSubmitLoanApplication, users])

  // show processing
  if (isSubmitting) {
    return (
      <div className="loan-status-processing">
        <HeaderNavigationWithLogin />
        <CssBaseline />
        <LoanProcessing {...{ copy: 'We are performing our checks…' }} />
      </div>
    )
  }

  // show error
  if (errorResponseCode === variable.httpStatusCodes.internalServerError) {
    return (
      <div className="loan-status-timeout">
        <LoanApplicationTimeout />
      </div>
    )
  }

  const errorResponseText = () => {
    let message
    if (errorResponseCode === variable.httpStatusCodes.conflict) {
      message = 'warning'
    } else {
      message = 'error'
    }
    return message
  }

  const errorMessageTitleText = () => {
    let message
    switch (errorResponseCode) {
      case variable.httpStatusCodes.notFound:
        message = `Oops! This link isn't valid.`
        break
      default:
        message = 'Oops!'
    }
    return message
  }

  const errorMessageHeadingText = () => {
    let message
    switch (errorResponseCode) {
      case variable.httpStatusCodes.notFound:
        message = 'Please double check the link and try again or get a quote.'
        break

      case variable.httpStatusCodes.conflict:
        message = `You've already applied for this loan.`
        break
      default:
        message = `There's been a problem with our system.`
    }
    return message
  }

  if (errorResponseCode > 0) {
    return (
      <div className={`api-response-${errorResponseText()}`}>
        <ApiResponseWrapper
          title={errorMessageTitleText()}
          text={errorMessageHeadingText()}
          response={errorResponseText()}
        >
          <div>If you have further issues, please don't hesitate to contact our team.</div>
        </ApiResponseWrapper>
      </div>
    )
  }

  if (postApiErrorMessage) {
    return (
      <div>
        <ApiResponseWrapper title={postApiErrorMessage} text={postApiErrorMessage} response={postApiErrorMessage}>
          <div>If you have further issues, please don't hesitate to contact our team.</div>
        </ApiResponseWrapper>
      </div>
    )
  }

  if (
    loanApplicationResponse?.decision === loanStatus.StatusReferred ||
    loanApplicationResponse?.decision === loanStatus.StatusApproved ||
    loanApplicationResponse?.decision === loanStatus.StatusDeclined
  ) {
    return (
      <DisplayDecisionContainer
        loanApplicationResponse={loanApplicationResponse}
        openBankingReason={openBankingReason}
      />
    )
  }

  return (
    <div className="loan-status-processing">
      <LoanProcessing {...{ copy: 'We are performing our checks…' }} />
    </div>
  )
}

export default LoanApplicationRedirect
