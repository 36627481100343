import './_termsAndConditions.scss'

import * as links from 'utils/Links'

import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material'
import CheckboxSelectedIcon from 'assets/images/icons/checkbox-selected.svg'
import CheckboxUnselectedIcon from 'assets/images/icons/checkbox-unselected.svg'
import { ChangeEvent } from 'react'

type Props = {
  loggedIn?: boolean
  step?: number
  acceptedTerms?: boolean
  parentCallbackMarketingCheckbox: (checked: boolean) => void
  getLoanQuoteButton?: boolean
}

const NewUserMarketingContent = ({
  loggedIn,
  step,
  acceptedTerms,
  parentCallbackMarketingCheckbox,
  getLoanQuoteButton,
}: Props): JSX.Element => {
  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    parentCallbackMarketingCheckbox(checked)
  }

  const clickCopy = getLoanQuoteButton || loggedIn ? 'Get my quote' : 'Continue'
  const stepCopy = step === undefined ? '' : `${step}.`

  return (
    <div className="Card-shadow terms-conditions" data-test="terms-conditions-container">
      <Box display="flex">
        <Grid item xs={12} className="title-box line-separate">
          <Box flexGrow={2} data-test="title-box">
            <Typography variant="h4">{stepCopy} Terms and conditions</Typography>
          </Box>
        </Grid>
      </Box>
      <Box display="flex">
        <Box className="paragraph-box" data-test="paragraph-box">
          <p>
            By clicking “{clickCopy}“, you agree to be bound by our{' '}
            <a href={links.termsAndConditions} target="_blank" rel="noopener noreferrer">
              Borrower Platform Terms and Conditions
            </a>
            . Read our{' '}
            <a href={links.privacyPolicy} target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>{' '}
            to see how we process your personal information.
          </p>
        </Box>
      </Box>
      {!loggedIn ? (
        <div className="checkbox-container">
          <Box display="flex">
            <Box flexGrow={2} className={`checkbox ${acceptedTerms ? 'checkbox-checked' : ''}`}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<img src={CheckboxUnselectedIcon} alt="Checkbox" />}
                      checkedIcon={<img src={CheckboxSelectedIcon} alt="Checkbox" />}
                      checked={acceptedTerms}
                      color="success"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Tick to receive tips and special offers to help you get the most from your Fluro account. We'll never spam you."
                  data-test="loan-intro-tsandcs"
                />
              </FormGroup>
            </Box>
          </Box>
        </div>
      ) : (
        <br />
      )}
    </div>
  )
}

export default NewUserMarketingContent
