import './_home.scss'
import * as features from 'utils/Features'

import * as messages from 'utils/Messages'

import React, { ChangeEvent, useState } from 'react'
import logo from 'assets/images/logo/fluro-logo.svg'
import Loader from 'components/Loader/Loader'
import useApiRequest from './UseApiRequest'

import { Box, Button, CssBaseline, Grid, TextField, Typography } from '@mui/material'
import LoginWrapper from '../../layout/LoginWrapper'

interface UserAuthenticatesProps {
  title: string
  message: string
  buttonText: string
  confirmationTitle: string
  confirmationMessage: React.ReactNode
  type: string
}

const UserAuthentication = ({
  title,
  message,
  buttonText,
  confirmationTitle,
  confirmationMessage,
  type,
}: UserAuthenticatesProps): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [emailError, setEmailError] = useState<boolean>(false)
  const [emailValue, setEmailValue] = useState<string>('')

  const { recoverCustomer } = useApiRequest()

  const handleEmail = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmailValue(e.target.value)
    setEmailError(false)
  }
  const emailInputValidation = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regexEmailCheck = features.emailValidate(e.target.value)

    if (e.target.value.length >= 1 && !regexEmailCheck) {
      setEmailError(true)
    } else {
      setEmailError(false)
    }
  }
  const handleSubmit = () => {
    setIsSubmitting(true)
    if (emailError) {
      setIsSubmitting(false)
    } else {
      postUserEmail()
    }
  }
  const postUserEmail = () => {
    if (!emailValue) {
      setEmailError(true)
      setIsSubmitting(false)
    } else {
      recoverCustomer(emailValue, setIsSubmitted)
    }
  }

  return (
    <LoginWrapper>
      <CssBaseline />
      <Grid container className="reset-password-container">
        <Grid item xs={12}>
          <Box display="flex">
            <Box className="logo-container" data-test="reset-logo-container">
              <a href="https://www.fluro.co.uk" title="Fluro homepage">
                <img
                  src={logo}
                  className="fluro-logo"
                  alt="Fluro Logo"
                  data-test="fluro-logo"
                  width="100%"
                  height="100%"
                />
              </a>
            </Box>
          </Box>
          {isSubmitted ? (
            <div className="email-container">
              <Box display="flex">
                <Box flexGrow={2} className="title-box">
                  <div className="icon-box">
                    <span className="fluro-icon-round-check icons-colorPrimary icons-fs-50 icon-bold" />
                  </div>
                  <Typography variant="h1" data-test="confirmation-title">
                    {confirmationTitle}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" className="paragraph-box" data-test="confirmation-message">
                <p>{confirmationMessage}</p>
              </Box>
            </div>
          ) : (
            <>
              <div className="email-container">
                <Box display="flex">
                  <Box flexGrow={2} className="title-box">
                    <Typography variant="h1" data-test="title">
                      {title}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box className="paragraph-box" data-test="message">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: message,
                      }}
                    />
                  </Box>
                </Box>
                <Box display="flex" mt={2}>
                  <Box className="email-input">
                    <label>Email</label>
                    <TextField
                      id="email"
                      className="Textfield-formControl"
                      variant="outlined"
                      autoComplete="off"
                      type="text"
                      value={emailValue}
                      fullWidth
                      data-test="email-input"
                      helperText={emailError ? messages.emailError : ''}
                      error={emailError}
                      onChange={handleEmail}
                      onBlur={emailInputValidation}
                    />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box className="password-btn">
                    <Button
                      className="update-submit-button"
                      data-test="submit-btn"
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={isSubmitted || isSubmitting}
                    >
                      {isSubmitting ? <Loader size="small" color="white" /> : buttonText}
                    </Button>
                  </Box>
                </Box>
              </div>
              <Box display="flex">
                <Box className="login-cta" data-test="return-to-login">
                  <a href="/sign-in">Return to log in</a>
                </Box>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </LoginWrapper>
  )
}

export default UserAuthentication
