import * as logging from 'utils/Logging'

import { AddressDataItem, ReferenceDataItem } from 'api/BorrowerPublicApiSchemas'
import { Dispatch, SetStateAction, useState } from 'react'

import { borrowerPublicApiClient } from 'api/BorrowerPublicApiClient'

interface Props {
  addressList: AddressDataItem[]
  fetchAddressList: (
    postcode: string,
    isLoading: Dispatch<SetStateAction<boolean>>,
    setPostcodeNotFoundError: React.Dispatch<React.SetStateAction<boolean>>,
  ) => void
  residentialStatuses: ReferenceDataItem[]
  fetchResidentialStatuses: () => void
}

const UseApiRequest = (): Props => {
  const borrowerPublicApi = borrowerPublicApiClient()
  const [addressList, setAddressList] = useState<AddressDataItem[]>([])
  const [residentialStatuses, setResidentialStatuses] = useState<ReferenceDataItem[]>([])

  const fetchAddressList = (
    postcode: string,
    isLoading: Dispatch<SetStateAction<boolean>>,
    setPostcodeNotFoundError: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    isLoading(true)
    setPostcodeNotFoundError(false)

    borrowerPublicApi
      .getAddressDataItemList(postcode)
      .then(
        (res) => {
          setAddressList(res)
        },
        (error) => {
          setPostcodeNotFoundError(true)
          logging.logError('failed to perform postcode lookup', error)
          setAddressList([])
        },
      )
      .finally(() => {
        isLoading(false)
      })
  }

  const fetchResidentialStatuses = () => {
    borrowerPublicApi.getResidentialStatuses().then(
      (statuses) => {
        setResidentialStatuses(statuses)
      },
      (error) => {
        logging.logError('failed to perform residential status lookup', error)
        setResidentialStatuses([])
      },
    )
  }

  return {
    addressList,
    fetchAddressList,
    residentialStatuses,
    fetchResidentialStatuses,
  }
}

export default UseApiRequest
