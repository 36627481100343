import './Questions.scss'

import * as variable from 'utils/Variable'

type Props = {
  useCSEmail?: boolean
}

const Questions = ({ useCSEmail }: Props): JSX.Element => {
  const email = useCSEmail ? variable.customerServiceEmail : variable.underwriterEmail
  const link = useCSEmail ? variable.customerServiceEmail : variable.underwriterEmail

  return (
    <div className="questionContactUs" data-test="questions-container">
      <ul>
        <li>
          <span
            className="lw-system-icons-phone icon-bold icons-colorSecordary icons-fs-24"
            data-test="phone-icon"
          ></span>
          <a
            className="phone-link"
            href={`tel: ${variable.customerServiceNumberCall}`}
            data-test="customer-service-number-cta"
          >
            {variable.customerServiceNumber}
          </a>
        </li>
        <li>
          <span
            className="lw-system-icons-email icon-bold icons-colorSecordary icons-fs-20"
            data-test="email-icon"
          ></span>
          <a
            className="email-link"
            href={`mailto:${link}`}
            target="_blank"
            rel="noreferrer"
            data-test="customer-service-email-cta"
          >
            {email}
          </a>
        </li>
      </ul>
      <p data-test="customer-service-hours">{variable.openingHours}</p>
    </div>
  )
}

export default Questions
