import UserAuthentication from '../components/userAuthentication/Home'

export const SignUp = (): JSX.Element => {
  const SignUpSuccessContentWrapper = () => {
    return (
      <>
        Didn't get the email? Check your spam folder or{' '}
        <a className="text-colorPrimary" href="/sign-up">
          try again
        </a>
        .
      </>
    )
  }

  const loginMSG = `Enter your email address and we'll send you a link to create your password if you have a loan or quote with us. If you don't have one, you can <a href='/borrowing-centre/request-a-loan'>get a quote</a> first.`

  return (
    <UserAuthentication
      title={'Create your account'}
      message={loginMSG}
      buttonText={'Send email'}
      confirmationTitle={'Thanks, a link has been sent to your inbox to create your password.'}
      confirmationMessage={<SignUpSuccessContentWrapper />}
      type={'SignUp'}
    />
  )
}

export default SignUp
