import './FeedbackIndicators.scss'

import { Alert } from '@mui/material'
import { FeedbackIndicatorsProps } from './FeedbackIndicators.types'
import IconButton from '@mui/material/IconButton'

const FeedbackIndicatorsModelComponent = (props: FeedbackIndicatorsProps): JSX.Element => {
  return (
    <Alert
      icon={false}
      severity={props.severity}
      className={`${props.className} CardModalContainer`}
      action={
        <IconButton
          className={`IconButton-system-close IconButton-system-closeBackground-${props.severity}`}
          aria-label="close"
          color="inherit"
          onClick={props.parentCallback}
          size="large"
        >
          <span className="lw-system-icons-close-x icons-fs-16"></span>
        </IconButton>
      }
    >
      {props.icon && (
        <div className="Alert-icon">
          <span className={`${props.icon} icon-bold`}></span>
        </div>
      )}
      <div className="Alert-message">{props.messages}</div>
    </Alert>
  )
}

FeedbackIndicatorsModelComponent.defaultProps = {
  severity: 'success',
  className: '',
  icon: 'string',
  closeAction: false,
  buttonAction: false,
  messages: 'Helpful and short message.',
}

export default FeedbackIndicatorsModelComponent
