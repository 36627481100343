export const OptionLists = [
  { id: 1, name: 'select 1', value: 'path' },
  { id: 2, name: 'select 2', value: 'path' },
]

export const LabelOptionLists = [{ label: 'select 1' }, { label: 'select 2' }]

export interface SelectOption {
  id: number
  reason?: string
  name?: string
  value?: string | number
}

export interface SelectLabelOption {
  label: string
}

export interface SelectProps {
  variant: 'filled' | 'outlined' | 'standard'
  onChange: (select: string) => void
  label?: string
  labelId?: string
  error?: boolean
  helperText?: string
  errorText?: string
  options?: SelectOption[]
  valSelected?: (select: string) => void
  getSelectedValue?: (select: string) => void
  value?: string
  disabled?: boolean
  name?: string
  defaultValue?: string
  dataTest?: string
}

export interface SelectLabelProps {
  variant: 'filled' | 'outlined' | 'standard'
  onChange: (select: string) => void
  label?: string
  labelId?: string
  error?: boolean
  helperText?: string
  errorText?: string
  options?: SelectLabelOption[]
  valSelected?: (select: string) => void
  getSelectedValue?: (select: string) => void
  value: string
  disabled?: boolean
  name?: string
  defaultValue?: string
  dataTest?: string
}
