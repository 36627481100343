import * as logging from 'utils/Logging'

import { useState } from 'react'
import { OpenBankingConnectionStatus } from '../../../api/BorrowerApiSchemas'
import { borrowerPublicApiClient } from '../../../api/BorrowerPublicApiClient'

interface Props {
  openBankingLink: string
  fetchOpenBankingLink: (reference: string, userId: number) => void

  linkGenerationError: boolean
  connectionStatus: OpenBankingConnectionStatus | null
  checkOpenBankingConnectionStatus: (taskId: string, reference: string, userId: number) => void
  connectionCheckError: boolean
}

const OpenBankingApi = (): Props => {
  const borrowerApi = borrowerPublicApiClient()
  const [openBankingLink, setOpenBankingLink] = useState<string>('')
  const [linkGenerationError, setLinkGenerationError] = useState(false)
  const [connectionStatus, setConnectionStatus] = useState<OpenBankingConnectionStatus | null>(null)
  const [connectionCheckError, setConnectionCheckError] = useState(false)

  const fetchOpenBankingLink = (reference: string, userId: number) => {
    borrowerApi.getOpenBankingAuthenticationUrlResponse(reference, userId).then(
      (res) => {
        setOpenBankingLink(res.url)
      },
      (error) => {
        setLinkGenerationError(true)
        logging.logError('failed to get open banking link while unauthenticated', error)
      },
    )
  }

  const checkOpenBankingConnectionStatus = (taskId: string, reference: string, userId: number) => {
    borrowerApi.getOpenBankingConnectionStatusResponse(taskId, userId, reference).then(
      (res) => {
        setConnectionStatus(res.status)
      },
      (error) => {
        logging.logError('failed to check open banking connection status while unauthenticated', error)
        setConnectionCheckError(true)
      },
    )
  }

  return {
    openBankingLink,
    fetchOpenBankingLink,
    linkGenerationError,
    connectionStatus,
    checkOpenBankingConnectionStatus,
    connectionCheckError,
  }
}

export default OpenBankingApi
