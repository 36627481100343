import * as variable from 'utils/Variable'

import { Box, Button, CssBaseline, Divider, Grid, Typography } from '@mui/material'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import FeedbackIndicatorsComponent from '../../../../components/FeedbackIndicators/FeedbackIndicators'
import Loader from '../../../../components/Loader/Loader'
import LogoPartners from '../../../../components/LogoPartners/LogoPartners'
import { heapTrack } from '../../../../utils/Features'
import { getLoanApplicationIncomeDocumentUploadWithSearch } from '../../../../utils/Links'
import LayoutWrapper from '../../../public/layout/LayoutWrapper'
import OptForCall from '../../loanApplication/referred/OpenBankingMandatory/OptForCall'

const OpenBankingCopy = (
  openBankingLink: string,
  canManuallyUpload: boolean,
  previouslyFailedToConnect: boolean,
  mandatoryOpenBanking: boolean,
  showOptForCallComponent: boolean,
  setShowOptForCallComponent: (showOptForCallComponent: boolean) => void,
  reference: string,
  reason?: string,
) => {
  const { search } = useLocation()
  const queryParams = queryString.parse(search)
  const openBankingReferralReason = (queryParams?.reason as string) ?? reason ?? ''

  const isMandatoryDataTestIdentifier = mandatoryOpenBanking ? 'mandatory' : 'optional'

  let whatNeedsVerifying
  switch (openBankingReferralReason) {
    case 'bank':
      whatNeedsVerifying = 'bank account'
      break
    case 'income':
      whatNeedsVerifying = 'income'
      break
    case 'bank_and_income':
      whatNeedsVerifying = 'bank account and income'
      break
    default:
      whatNeedsVerifying = 'bank account/income'
  }

  if (showOptForCallComponent) {
    return <OptForCall reference={reference} />
  }

  const blurbCopy = (
    <p>
      With your consent, our trusted Open Banking partner, Bud, will connect to your bank account in just a few clicks.
      This allows us to view your bank statements to verify your information. We can&apos;t access your login details or
      money, but it does help us to understand your current circumstances as best we can. Your data is safe and secure,
      and Bud is regulated by the Financial Conduct Authority.
    </p>
  )

  return (
    <LayoutWrapper className="openbanking-wrapper">
      <CssBaseline />
      <Grid container className="openbanking-container">
        <Grid item xs={12}>
          <div data-test="openbanking-header">
            <Box mb={3} mt={12} className="Card-shadow Headers lines" data-test={isMandatoryDataTestIdentifier}>
              <Typography variant="h3" data-test="openbanking-copy" className="nearly-there top-shadow">
                {mandatoryOpenBanking
                  ? 'We need to do a few more checks.'
                  : `Nearly there! We need to verify your ${whatNeedsVerifying}.`}
              </Typography>
              <Typography className="complete-application">
                To complete your application, please verify your{' '}
                {mandatoryOpenBanking ? 'information' : whatNeedsVerifying} below
              </Typography>
              {previouslyFailedToConnect && (
                <FeedbackIndicatorsComponent
                  severity="error"
                  icon="lw-system-icons-close icon-bg-error"
                  title={"We couldn't connect your open banking account."}
                  messages={'Please try again.'}
                  closeAction={false}
                  isOpen={true}
                />
              )}
            </Box>
          </div>
          {openBankingLink === '' && <Loader data-test="openbanking-loader" size="large" color="white" />}
          {openBankingLink !== '' && (
            <div data-test="openbanking-cta">
              <div>
                <Box className="Card-shadow lines">
                  <Box display="flex" className="openbanking-text-and-bud-logo">
                    <Box className="openbanking-text">
                      <Typography variant="h5">Open Banking</Typography>
                    </Box>
                    <Box className="bud-logo">
                      <LogoPartners api="Bud" />
                    </Box>
                  </Box>
                  <Divider />
                  <Box className="bud-blurb">
                    <p>
                      The fastest and most popular way to provide proof, with no need to download or scan documents.
                    </p>
                    {blurbCopy}
                    <p>
                      Once you have provided this additional information, we will respond to you within{' '}
                      {variable.underwriterContactTimeframe}.
                    </p>
                    <Grid display="flex" alignItems="center" flexDirection="row" className="mandatory-ob-text">
                      <span className="lw-marketing-icons-light-bulb-on icon-bg-notice icon-bold mandatory-ob-light-bulb"></span>
                      Please link the bank account where you receive your salary.
                    </Grid>
                  </Box>
                  <div className={mandatoryOpenBanking ? 'mandatory-ob-verify-income-button-container' : ''}>
                    <div className="verify-income-button-container">
                      <a
                        href={openBankingLink}
                        data-test="openbanking-link"
                        onClick={() => {
                          heapTrack('OpenBankingClickOut')
                        }}
                      >
                        <Button variant="contained" color="primary">
                          Verify
                        </Button>
                      </a>
                    </div>
                  </div>
                </Box>
              </div>
              {canManuallyUpload && !mandatoryOpenBanking && (
                <div className="upload-manually">
                  <Typography>
                    Or choose to{' '}
                    <a
                      data-test="upload-manually-link"
                      className="web-link text-colorPrimary"
                      href={getLoanApplicationIncomeDocumentUploadWithSearch(search)}
                    >
                      upload manually
                    </a>
                  </Typography>
                </div>
              )}
            </div>
          )}
        </Grid>
      </Grid>
    </LayoutWrapper>
  )
}

export default OpenBankingCopy
