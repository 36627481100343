import './_loanIntro.scss'

import * as features from 'utils/Features'
import * as paths from 'utils/Links'
import * as messages from 'utils/Messages'
import * as seoTitle from 'utils/SeoTitle'

import { Box, Button, CssBaseline, Grid } from '@mui/material'
import { useOktaAuth } from '@okta/okta-react'

import queryString, { ParsedQuery } from 'query-string'
import { useCallback, useEffect, useState } from 'react'
import { List } from 'react-content-loader'
import { useLocation, useNavigate } from 'react-router-dom'

import FeedbackIndicatorsComponent from 'components/FeedbackIndicators/FeedbackIndicators'
import Loader from 'components/Loader/Loader'
import PartnersLogo from 'components/LogoPartners/LogoPartners'
import Stepper from 'components/Stepper/Stepper'
import QuoteDetails from 'view/portal/components/quoteDetails/Home'
import HeaderNavigationWithLogin from 'view/portal/layout/HeaderNavigationWithLogin'
import LinkInvalid from 'view/public/common/LinkInvalid/Home'
import UseLoanApiRequest from 'view/public/common/api/UseLoanApiRequest'
import useOktaRequest from 'view/public/common/api/UseOktaRequest'
import HelperContainer from 'view/public/common/shared/HelperContainer'
import SignInAndCreateAccountForm from 'view/public/common/shared/SignInAndCreateAccountForm'
import SignInAndCreateAccountHeader from 'view/public/common/shared/SignInAndCreateAccountHeader'
import NewUserMarketingContent from 'view/public/common/shared/TermsAndConditions'

import LayoutWrapper from '../layout/LayoutWrapper'
import RightContentWrapper from '../layout/RightContentWrapper'

const LoanIntroductionForm = (): JSX.Element => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false)
  const [newPassword, setNewPassword] = useState<string>('')
  const [passwordError, setPasswordError] = useState<boolean>(false)
  const [hasLoaded, setHasLoaded] = useState<boolean>(false)
  const oktaAuth = useOktaAuth()
  const { oktaErrorMessages, postSignInWithCredentials } = useOktaRequest()
  const {
    quoteIntroductionDetails,
    apiResponseErrorMessage,
    apiResponseSuccessMessages,
    fetchQuoteIntroductionDetails,
    postRegisterCustomer,
    apiResponseLoanIntro,
  } = UseLoanApiRequest()
  const { pathname, search } = useLocation()
  const queryParams = queryString.parse(search)
  const createPasswordUser = quoteIntroductionDetails?.accountStatus === 'CreatePassword'
  const userSignIn = quoteIntroductionDetails?.accountStatus === 'SignIn'
  const userAccountLockedOut = quoteIntroductionDetails?.accountStatus === 'LockedOut'
  const userPasswordExpired = quoteIntroductionDetails?.accountStatus === 'ExpiredPassword'
  const userPasswordRecovery = quoteIntroductionDetails?.accountStatus === 'Recovery'
  const accountStatusExpiredOrRecovery = userPasswordExpired || userPasswordRecovery
  const email = quoteIntroductionDetails?.email || ''
  const sourceName = quoteIntroductionDetails?.quote.source.name || ''
  const sourceApi = quoteIntroductionDetails?.quote.source.api || ''
  const submittedApplication = quoteIntroductionDetails?.quote.hasSubmittedLoanApplication

  const uuid = pathname.split('/')[2]

  const tokenId = parseTokenId(queryParams)
  const validTokenId = !!tokenId
  const validUuid = validTokenId ? tokenId : uuid

  const urn = (queryParams?.urn as string) ?? ''
  // TODO: remove overallRank once clearscore have completed their rollout
  const quoteResultRank = Number(queryParams?.overallRank || queryParams?.or) || undefined

  const redirectUrl = submittedApplication
    ? paths.getDashboard()
    : paths.getLoanApplicationForm(quoteIntroductionDetails?.quote.reference)
  const registeredUser = apiResponseSuccessMessages !== '' || false
  const unRegisteredUser = createPasswordUser && !registeredUser
  const newUserPasswordSuccess = apiResponseSuccessMessages && oktaErrorMessages === ''

  if (oktaAuth?.authState?.isAuthenticated) {
    console.log('user is logged in')
  }

  function parseTokenId(queryParams: ParsedQuery) {
    const isArray = queryParams?.tokenId && Array.isArray(queryParams.tokenId)
    return isArray
      ? queryParams?.tokenId && ((queryParams.tokenId as string[])?.filter((id) => id !== '')[0] as string)
      : (queryParams?.tokenId as string)
  }

  const callback = useCallback((password: string) => {
    setNewPassword(password)
  }, [])

  const closeFeedbackIndicatorsComponent = () => {
    setIsOpen(false)
  }

  const submitNewUser = useCallback(() => {
    setIsSubmitting(true)
    postRegisterCustomer(validUuid, newPassword, acceptedTerms, setIsSubmitting)

    if (oktaErrorMessages) {
      setIsOpen(true)
    }
  }, [validUuid, newPassword, acceptedTerms, oktaErrorMessages, postRegisterCustomer])

  const submitExistingUser = useCallback(() => {
    setIsSubmitting(true)

    postSignInWithCredentials(email, newPassword, setIsSubmitting, redirectUrl)

    if (oktaErrorMessages) {
      setIsOpen(true)
    }
  }, [email, newPassword, redirectUrl, oktaErrorMessages, postSignInWithCredentials])

  const handleSubmitNewUserCreatePassword = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsSubmitting(true)
    localStorage.removeItem('isRedirectCreatePassword')

    if (submittedApplication) {
      submitNewUser()
      return
    }

    const reference = quoteIntroductionDetails?.quote.reference ? quoteIntroductionDetails.quote.reference : ''
    features.heapTrack('FirstFormCompleted')

    // Go loan application page to create password - /loan-application/apply/create-password/SMPL33218574
    navigate(paths.getLoanApplicationCreateForm(reference), {
      state: {
        user: quoteIntroductionDetails?.user,
        email: quoteIntroductionDetails?.email,
        accountStatus: quoteIntroductionDetails?.accountStatus,
        source: {
          name: quoteIntroductionDetails?.quote.source.name,
          api: quoteIntroductionDetails?.quote.source.api,
        },
        uuid: validUuid,
        acceptedTerms: acceptedTerms,
        reference: reference,
      },
    })
  }

  const handleSubmitExistingUser = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    submitExistingUser()
  }

  const callbackMarketingCheckbox = useCallback((checked: boolean) => {
    setAcceptedTerms(checked)
  }, [])

  const showLoginContainer = userSignIn || submittedApplication
  const showNewUserTermsConditionsContainter = unRegisteredUser // show the new user T&C and go to next page

  const OktaErrorMessages = () => {
    return (
      <FeedbackIndicatorsComponent
        className="feedback-indicator"
        severity={`${newUserPasswordSuccess ? 'success' : 'error'}`}
        icon={`${
          newUserPasswordSuccess ? 'lw-system-icons-checkmark icon-bg-success' : 'lw-system-icons-close icon-bg-error'
        }`}
        messages={oktaErrorMessages || apiResponseErrorMessage || apiResponseSuccessMessages}
        isOpen={isOpen}
        closeAction={true}
        parentCallback={closeFeedbackIndicatorsComponent}
      />
    )
  }

  useEffect(() => {
    features.getBrowserHiddenProps(seoTitle.loanApplicationSeoTitle, seoTitle.loanApplicationChangeSeoTitle)

    if (!hasLoaded) {
      fetchQuoteIntroductionDetails(validUuid, setLoading, urn, quoteResultRank)
      setHasLoaded(true)
    }
  }, [hasLoaded, fetchQuoteIntroductionDetails, validUuid, urn, quoteResultRank])

  useEffect(() => {
    if (oktaErrorMessages) {
      setPasswordError(true)
    }
  }, [oktaErrorMessages])

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault()
        if (!accountStatusExpiredOrRecovery && newPassword !== '') {
          if (!passwordError && unRegisteredUser) {
            submitNewUser()
          } else {
            submitExistingUser()
          }
        }
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [passwordError, accountStatusExpiredOrRecovery, unRegisteredUser, newPassword, submitNewUser, submitExistingUser])

  if (loading) {
    return (
      <LayoutWrapper>
        <div className="loan-intro-container">
          <List />
        </div>
      </LayoutWrapper>
    )
  }

  if (apiResponseLoanIntro) {
    return (
      <>
        <HeaderNavigationWithLogin />
        <LayoutWrapper className="PublicLoanContainer-LinkInvalid-container">
          <CssBaseline />
          <LinkInvalid />
        </LayoutWrapper>
      </>
    )
  }

  return (
    <>
      <HeaderNavigationWithLogin />
      <LayoutWrapper>
        <CssBaseline />
        <Grid container className="loan-intro-container" data-test="loan-intro-container">
          <Grid item xs={12}>
            {sourceName !== '' && features.brandLogoCheck(sourceApi) && (
              <Grid item xs={12}>
                <Box display="flex">
                  <Box className="Logo-container" data-test="partner-logo-container">
                    <PartnersLogo api={sourceApi} />
                  </Box>
                </Box>
              </Grid>
            )}

            <div className="application-steps">
              <Stepper step1="active" />
            </div>

            {quoteIntroductionDetails?.quote && (
              <QuoteDetails
                quote={quoteIntroductionDetails?.quote}
                message={quoteIntroductionDetails?.message}
                reference={quoteIntroductionDetails?.quote.reference}
              />
            )}

            {showLoginContainer && (
              <div
                className={`login-container ${unRegisteredUser ? 'margin-bottom24' : ''}`}
                data-test="loan-intro-login-container"
              >
                <SignInAndCreateAccountHeader
                  unRegisteredUser={unRegisteredUser}
                  newUser={createPasswordUser}
                  userAccountLockedOut={userAccountLockedOut}
                  accountStatusExpiredOrRecovery={accountStatusExpiredOrRecovery}
                  step={1}
                />

                {(userAccountLockedOut || accountStatusExpiredOrRecovery) && (
                  <Box display="flex">
                    <Box className="paragraph-box">
                      <FeedbackIndicatorsComponent
                        className="feedback-indicator"
                        severity="error"
                        icon="lw-system-icons-close icon-bg-error"
                        messages={
                          accountStatusExpiredOrRecovery
                            ? messages.accountPasswordExpired
                            : messages.accountTemporarilyLocked
                        }
                        isOpen={isOpen}
                        closeAction={false}
                      />
                    </Box>
                  </Box>
                )}

                {(oktaErrorMessages || apiResponseErrorMessage || apiResponseSuccessMessages) && isOpen && (
                  <div className="okta-feedback">
                    <OktaErrorMessages />
                  </div>
                )}

                <SignInAndCreateAccountForm
                  email={email}
                  parentCallback={callback}
                  newUser={createPasswordUser}
                  helperText={`${unRegisteredUser ? "You'll need this to manage your loan" : ''}`}
                  oktaErrorMessages={oktaErrorMessages}
                  passwordError={passwordError}
                  registeredUser={registeredUser}
                  setPasswordError={setPasswordError}
                  apiResponseSuccessMessages={apiResponseSuccessMessages}
                />

                {(!createPasswordUser || registeredUser) && (
                  <>
                    <div className="login-btn">
                      <Button
                        data-test="button-dialogs-submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleSubmitExistingUser}
                        disabled={passwordError || isSubmitting || accountStatusExpiredOrRecovery}
                      >
                        {isSubmitting ? <Loader size="small" color="white" /> : 'Log in and continue'}
                      </Button>
                    </div>

                    <div className="forgot-password-cta">
                      <HelperContainer newUser={createPasswordUser} email={email} registeredUser={registeredUser} />
                    </div>
                  </>
                )}
              </div>
            )}

            {showNewUserTermsConditionsContainter && (
              <>
                <NewUserMarketingContent
                  acceptedTerms={acceptedTerms}
                  parentCallbackMarketingCheckbox={callbackMarketingCheckbox}
                />

                <div className="continue-btn">
                  <Button
                    data-test="loan-intro-continue"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitNewUserCreatePassword}
                    disabled={passwordError || isSubmitting}
                  >
                    {isSubmitting ? <Loader size="small" color="white" /> : 'Continue'}
                  </Button>
                  <div className="credit-score-container">
                    <span className="lw-system-icons-credit-search icons-fs-16-normal icons-colorBlack icon-bold" />
                    <span>This won't affect your credit score</span>
                  </div>
                </div>
              </>
            )}
          </Grid>

          <RightContentWrapper />
        </Grid>
      </LayoutWrapper>
    </>
  )
}

export default LoanIntroductionForm
