import './Button.scss'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import Loader from 'components/Loader/Loader'
import { useNavigate } from 'react-router-dom'
import { ButtonProps } from './Button.types'

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      position: 'relative',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
)

const ButtonComponents: React.FC<ButtonProps & React.HTMLAttributes<HTMLDivElement>> = (props: ButtonProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { testAttribute = 'button-component' } = props

  const classNames = clsx({
    'MuiButton-containedDestructive': props.destructive,
    'MuiButton-withIcon': props.icon,
  })

  const redirect = () => {
    if (props.buttonClick) {
      navigate(`${props.buttonClick}`)
    }
  }

  return (
    <div className={classes.wrapper}>
      <Button
        data-testid={testAttribute}
        data-test={testAttribute}
        variant={props.variant}
        color={props.color}
        disabled={props.loading || props.disabled ? true : false}
        size={props.size}
        className={`${props.className} ${classNames}`}
        onClick={redirect}
      >
        {props.label}
        {props.icon && (
          <span className="MuiButton-buttonIcon">
            <ExpandMoreIcon />
          </span>
        )}
        {props.loading && <Loader size="small" color="white" />}
      </Button>
    </div>
  )
}

ButtonComponents.defaultProps = {
  size: 'medium',
  label: 'Button name',
  color: 'primary',
  destructive: false,
  icon: false,
  disabled: false,
  loading: false,
}

export default ButtonComponents
