export const colours = {
  whiteSmoke: '#F8F8F8',
  white: '#FFF',
  black: '#000',

  amberLighter: '#ffffe0',
  amberLight: '#ffff9a',
  amber: '#fafa19',
  amberDark: '#ff964f',
  amberDarker: '#5d2d0d',

  azuriteLight: '#bffbfb',
  azurite: '#6bffc3',
  azuriteDarker: '#063846',

  crimsonLighter: '#fff7f9',
  crimsonLight: '#ffcada',
  crimson: '#f01b5c',
  crimsonDark: '#aa1240',
  crimsonDarker: '#6c0a28',

  mustardLighter: '#FFFAE6',
  mustardLight: '#FFE380',
  mustard: '#FFAB00',
  mustardDark: '#FF991F',
  mustardDarker: '#4A1504',

  melroseLighter: '#F4F5FA',
  melroseLight: '#B3BCF5',
  melrose: '#5C6AC4',
  melroseDark: '#202E78',
  melroseDarker: '#000639',

  skyLighter: '#fafcfc',
  skyLight: '#eef5f5',
  sky: '#dadfdf',
  skyDark: '#8a9292',
  skyDarker: '#787f7f',

  inkLighter: '#D6D6D6',
  inkLight: '#D4D4D4',
  ink: '#B5B5B5',
  inkDark: '#919191',
  inkDarker: '#4A4A4A',

  jacarandaLighter: '#fcfbff',
  jacarandaLight: '#f4efff',
  jacaranda: '#472a87',
  jacarandaDark: '#351d6b',
  jacarandaDarker: '#1b0a40',

  blackOpacityWithZeroEightFive: 'rgba(0, 0, 0, 0.85)',
}
