import { CssBaseline, Grid } from '@mui/material'

import React from 'react'

type Props = {
  children: React.ReactNode
}

const Layout = ({ children }: Props): JSX.Element => {
  return (
    <div className="SignUpSignInContainer-mainLayout">
      <CssBaseline />
      <Grid container className="Layout-MainContainer">
        <Grid item xs={12} className="Layout-MainContainer-MainContent">
          {children}
        </Grid>
      </Grid>
    </div>
  )
}

export default Layout
