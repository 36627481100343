import './_loanQuote.scss'

import * as features from 'utils/Features'
import * as loanStatus from 'utils/LoanStatus'
import * as seoTitle from 'utils/SeoTitle'

import { Box, Button, CssBaseline, Grid } from '@mui/material'
import { CreateQuoteRequest, UserAddress } from 'api/BorrowerPublicApiSchemas'
import { useCallback, useEffect, useRef, useState } from 'react'
import YourFinances, { employmentStatusesWithoutTimeInRole } from './yourFinances/YourFinances'

import FeedbackIndicatorsComponent from 'components/FeedbackIndicators/FeedbackIndicators'
import Loader from 'components/Loader/Loader'
import Stepper from 'components/Stepper/Stepper'
import LoanApplicationDeclined from 'view/portal/components/declined/Home'
import QuoteDetails from 'view/portal/components/quoteDetails/Home'
import HeaderNavigationWithLogin from 'view/portal/layout/HeaderNavigationWithLogin'
import useQuoteApiRequest from 'view/public/common/api/UseQuoteApiRequest'
import TermsAndConditions from 'view/public/common/shared/TermsAndConditions'
import LayoutWrapper from 'view/public/layout/LayoutWrapper'
import RightContentWrapper from 'view/public/layout/RightContentWrapper'

import AboutYou from './aboutYou/AboutYou'
import CurrentAddress from './addresses/currentAddress/Addresses'
import LoanDetail from './loanDetails/Home'
import LoggedInLoanQuote from './loggedIn/Home'
import LoginSignup from './loginSignup/Home'

interface UserAddressProps {
  firstAddress: string
  monthsAtFirstAddress: number
  firstAddressMoveInDate: string
  firstAddressPostcode: string
  secondAddress: string
  monthsAtSecondAddress: number
  secondAddressMoveInDate: string
  secondAddressPostcode: string
  thirdAddress: string
  monthsAtThirdAddress: number
  thirdAddressMoveInDate: string
  thirdAddressPostcode: string
  monthlyPayment: number
  residentialStatus?: string
}

const Quote = (): JSX.Element => {
  const [apiError, setApiError] = useState(false)
  const { submitQuoteApplication, quoteResponse, quoteDetails } = useQuoteApiRequest()

  const [hasLoaded, setHasLoaded] = useState<boolean>(false)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [showQuoteDetails, setShowQuoteDetails] = useState<boolean>(false)

  const [aboutUs, setAboutUs] = useState({ first: '', last: '', mobile: '', email: '' })
  const [aboutUsError, setAboutUsError] = useState({
    first: false,
    last: false,
    mobile: false,
    email: false,
  })
  const [userAddress, setUserAddress] = useState<UserAddressProps>({
    firstAddress: '',
    monthsAtFirstAddress: 0,
    firstAddressMoveInDate: '',
    firstAddressPostcode: '',
    secondAddress: '',
    monthsAtSecondAddress: 0,
    secondAddressPostcode: '',
    secondAddressMoveInDate: '',
    thirdAddress: '',
    monthsAtThirdAddress: 0,
    thirdAddressPostcode: '',
    thirdAddressMoveInDate: '',
    monthlyPayment: 0,
    residentialStatus: '',
  })
  const [addressError, setAddressError] = useState({
    firstAddress: false,
    monthsAtFirstAddress: false,
    firstAddressMoveInDate: false,
    firstAddressPostcode: false,
    residentialStatus: false,
  })

  const [loanDetails, setLoanDetails] = useState({
    loanAmount: 10000,
    loanDuration: '',
    loanPurpose: '',
    purposeReason: '',
  })
  const [loanDetailsError, setLoanDetailsError] = useState({
    loanAmount: false,
    loanDuration: false,
    loanPurpose: false,
    purposeReason: false,
  })

  const [dateOfBirth, setDateOfBirth] = useState<string>('')
  const [dateOfBirthError, setDateOfBirthError] = useState<boolean>(false)

  const [yourFinancesErrors, setYourFinancesErrors] = useState({
    employmentStatus: false,
    timeInRole: false,
    monthlyIncome: false,
  })

  const handleSubmit = () => {
    setIsSubmitting(true)

    if (!formError) {
      const quoteRequest: CreateQuoteRequest = {
        addresses: buildAddressList(userAddress),
        employmentStatus: employmentStatus,
        income: Number.parseInt(monthlyIncome),
        loanAmount: loanDetails.loanAmount,
        loanDuration: Number.parseInt(loanDetails.loanDuration),
        loanPurpose: loanDetails.loanPurpose,
        loanPurposeSpecified: loanDetails.purposeReason,
        receiveMarketingInfo: acceptedTerms,
        rentMortgage: userAddress.monthlyPayment,
        firstName: aboutUs.first,
        lastName: aboutUs.last,
        dateOfBirth: new Date(dateOfBirth),
        mobileNumber: aboutUs.mobile,
        email: aboutUs.email,
        timeInRole: Number.parseInt(timeInRole),
      }
      submitQuoteApplication(quoteRequest, setIsSubmitting, setApiError)
    }
  }

  const buildAddressList = (props: UserAddressProps): UserAddress[] => {
    const addresses = []
    addresses.push({
      addressLine: props.firstAddress,
      durationInMonths: props.monthsAtFirstAddress,
      postcode: props.firstAddressPostcode,
      residentialStatus: props.residentialStatus,
    })
    if (props.secondAddress !== '') {
      addresses.push({
        addressLine: props.secondAddress,
        durationInMonths: props.monthsAtSecondAddress,
        postcode: props.secondAddressPostcode,
      })
    }

    if (props.thirdAddress !== '') {
      addresses.push({
        addressLine: props.thirdAddress,
        durationInMonths: props.monthsAtThirdAddress,
        postcode: props.thirdAddressPostcode,
      })
    }

    return addresses
  }

  const formError =
    Object.values(aboutUsError).some((x) => x) ||
    dateOfBirthError ||
    Object.values(loanDetailsError).some((x) => x) ||
    Object.values(addressError).some((x) => x) ||
    Object.values(yourFinancesErrors).some((x) => x)

  const refLoanDetail = useRef<null | HTMLDivElement>(null)
  const refAboutYou = useRef<null | HTMLDivElement>(null)
  const refCurrentAddress = useRef<null | HTMLDivElement>(null)
  const refYourFinances = useRef<null | HTMLDivElement>(null)

  const handleFormSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault()

    if (!formError) {
      handleSubmit()
    } else {
      const isFalseRefLoanDetail = Object.values(loanDetailsError).every((value) => value === false)
      const isFalseRefAboutYou = Object.values(aboutUsError).every((value) => value === false)
      const isFalseRefCurrentAddress = Object.values(addressError).every((value) => value === false)
      const isFalseRefYourFinances = Object.values(yourFinancesErrors).every((value) => value === false)

      if (!isFalseRefLoanDetail) {
        refLoanDetail.current?.scrollIntoView({ behavior: 'smooth' })
      } else if (!isFalseRefAboutYou) {
        refAboutYou.current?.scrollIntoView({ behavior: 'smooth' })
      } else if (!isFalseRefCurrentAddress) {
        refCurrentAddress.current?.scrollIntoView({ behavior: 'smooth' })
      } else if (!isFalseRefYourFinances) {
        refYourFinances.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  const formValidation = () => {
    setLoanDetailsError({
      loanAmount: loanDetails.loanAmount === 0,
      loanDuration: loanDetails.loanDuration === '' || isNaN(Number.parseInt(loanDetails.loanDuration)),
      loanPurpose: loanDetails.loanPurpose === '',
      purposeReason: loanDetails.loanPurpose === 'Other' && loanDetails.purposeReason === '',
    })

    setAboutUsError({
      first: aboutUs.first === '',
      last: aboutUs.last === '',
      mobile: !(aboutUs.mobile !== '' && features.checkMobileNumber(aboutUs.mobile)),
      email: !(aboutUs.email !== '' && features.emailValidate(aboutUs.email)),
    })
    setAddressError({
      firstAddress: userAddress.firstAddress === '',
      monthsAtFirstAddress: userAddress.monthsAtFirstAddress === 0,
      firstAddressMoveInDate: userAddress.firstAddressMoveInDate === '',
      firstAddressPostcode: userAddress.firstAddressPostcode === '',
      residentialStatus: userAddress.residentialStatus === '',
    })

    setDateOfBirthError(dateOfBirth.length !== 10)

    setYourFinancesErrors({
      employmentStatus: employmentStatus === '',
      timeInRole: timeInRole === '' && !employmentStatusesWithoutTimeInRole.includes(employmentStatus),
      monthlyIncome: monthlyIncome === '' || isNaN(Number.parseInt(monthlyIncome)),
    })
  }

  // Get value from children
  const callbackAboutUs = useCallback((first: string, last: string, mobile: string, email: string) => {
    setAboutUs({ first: first, last: last, mobile: mobile, email: email })
  }, [])

  const callbackDOB = useCallback((day: string, month: string, year: string) => {
    setDateOfBirth(`${year}-${month}-${day}`)
  }, [])

  const [acceptedTerms, setAcceptedTerms] = useState(false)

  const [employmentStatus, setEmploymentStatus] = useState('')
  const [monthlyIncome, setMonthlyIncome] = useState('')
  const [timeInRole, setTimeInRole] = useState('')

  const callbackUserAddress = useCallback(
    (
      firstAddress: string,
      monthsAtFirstAddress: number,
      firstAddressMoveInDate: string,
      firstAddressPostcode: string,
      secondAddress: string,
      monthsAtSecondAddress: number,
      secondAddressMoveInDate: string,
      secondAddressPostcode: string,
      thirdAddress: string,
      monthsAtThirdAddress: number,
      thirdAddressMoveInDate: string,
      thirdAddressPostcode: string,
      monthlyPayment: number,
      residentialStatus: string | undefined,
    ) => {
      setUserAddress({
        firstAddress: firstAddress,
        monthsAtFirstAddress: monthsAtFirstAddress,
        firstAddressMoveInDate: firstAddressMoveInDate,
        firstAddressPostcode: firstAddressPostcode,
        secondAddress: secondAddress,
        monthsAtSecondAddress: monthsAtSecondAddress,
        secondAddressPostcode: secondAddressPostcode,
        secondAddressMoveInDate: secondAddressMoveInDate,
        thirdAddress: thirdAddress,
        monthsAtThirdAddress: monthsAtThirdAddress,
        thirdAddressPostcode: thirdAddressPostcode,
        thirdAddressMoveInDate: thirdAddressMoveInDate,
        monthlyPayment: monthlyPayment,
        residentialStatus: residentialStatus,
      })
    },
    [],
  )

  const callbackUserAddressError = useCallback(
    (
      firstAddress: boolean,
      monthsAtFirstAddress: boolean,
      firstAddressMoveInDate: boolean,
      firstAddressPostcode: boolean,
      residentialStatus: boolean,
    ) => {
      setAddressError({
        firstAddress: firstAddress,
        monthsAtFirstAddress: monthsAtFirstAddress,
        firstAddressMoveInDate: firstAddressMoveInDate,
        firstAddressPostcode: firstAddressPostcode,
        residentialStatus: residentialStatus,
      })
    },
    [],
  )

  const callbackLoanDetails = useCallback(
    (loanAmount: number, loanDuration: string, loanPurpose: string, purposeReason: string) => {
      setLoanDetails({
        loanAmount: loanAmount,
        loanDuration: loanDuration,
        loanPurpose: loanPurpose,
        purposeReason: purposeReason,
      })
    },
    [],
  )

  const callbackMarketingCheckbox = useCallback((checked: boolean) => {
    setAcceptedTerms(checked)
  }, [])

  useEffect(() => {
    features.getBrowserHiddenProps(seoTitle.loanQuoteSeoTitle, seoTitle.loanQuoteChangeSeoTitle)

    if (!hasLoaded) {
      setHasLoaded(true)
    }
  }, [hasLoaded])

  useEffect(() => {
    if (quoteResponse?.quote.status === loanStatus.StatusCreated) {
      setShowQuoteDetails(true)
      window.scrollTo(0, 0)
    }
  }, [quoteResponse?.quote])

  if (quoteResponse?.quote.status === loanStatus.StatusDeclined) {
    return (
      <div className="loan-status-declined">
        <HeaderNavigationWithLogin isQuickQuote={!showQuoteDetails ? true : false} />
        <LayoutWrapper>
          <LoanApplicationDeclined />
        </LayoutWrapper>
      </div>
    )
  }

  if (
    (localStorage.getItem('okta-token-storage') !== '{}' && localStorage.getItem('okta-token-storage')?.length) ??
    0 > 2
  ) {
    return <LoggedInLoanQuote />
  }

  return (
    <div className={`loan-status-apply${!showQuoteDetails ? ' quick-quote' : ''}`}>
      <HeaderNavigationWithLogin isQuickQuote={!showQuoteDetails ? true : false} />

      <LayoutWrapper>
        <CssBaseline />
        <Grid container className="quote-container" data-test="quote-container">
          {!showQuoteDetails ? (
            <>
              <Grid item xs={12} className="quote-container-header">
                <h1>Get a quick quote</h1>
                <div>This will take you 2 minutes and it won't affect your credit score.</div>
              </Grid>

              <Grid item xs={12}>
                <form onSubmit={handleFormSubmit}>
                  <div ref={refLoanDetail}></div>
                  <LoanDetail loanDetailsError={loanDetailsError} parentCallbackLoanDetails={callbackLoanDetails} />

                  <div ref={refAboutYou}></div>
                  <AboutYou
                    aboutUsError={aboutUsError}
                    parentCallbackAboutUs={callbackAboutUs}
                    dateOfBirthError={dateOfBirthError}
                    parentCallbackDOB={callbackDOB}
                  />

                  <div ref={refCurrentAddress}></div>
                  <CurrentAddress
                    parentCallbackUserAddress={callbackUserAddress}
                    parentCallbackUserAddressError={callbackUserAddressError}
                    addressError={addressError}
                  />

                  <div ref={refYourFinances}></div>
                  <YourFinances
                    setEmploymentStatusCallback={setEmploymentStatus}
                    setMonthlyIncomeCallback={setMonthlyIncome}
                    setTimeInRoleCallback={setTimeInRole}
                    errors={yourFinancesErrors}
                    setErrors={setYourFinancesErrors}
                    step={4}
                  />

                  <TermsAndConditions
                    step={5}
                    acceptedTerms={acceptedTerms}
                    parentCallbackMarketingCheckbox={callbackMarketingCheckbox}
                    getLoanQuoteButton={true}
                  />

                  {apiError && (
                    <Box mt={3} mb={2}>
                      <FeedbackIndicatorsComponent
                        messages="An error occurred while submitting your quote. Please try again later."
                        className="feedback-indicator"
                        severity="error"
                        icon="lw-system-icons-close icon-bg-error"
                        closeAction={false}
                        isOpen={apiError}
                      ></FeedbackIndicatorsComponent>
                    </Box>
                  )}

                  <Grid item xs={12} className="get-my-quote-container">
                    <Box mt={3} mb={2}>
                      <Button
                        type="submit"
                        className="complete-application-submit-button"
                        data-test="button-complete-application-submit"
                        size="large"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={formValidation}
                      >
                        <>{isSubmitting ? <Loader size="small" color="white" /> : 'Get my quote'}</>
                      </Button>
                    </Box>
                    <Box mb={3}>
                      <div className="credit-score-container">
                        <span className="lw-system-icons-credit-search icons-fs-16-normal icons-colorBlack icon-bold" />
                        <span> This won't affect your credit score</span>
                      </div>
                    </Box>
                  </Grid>
                </form>
              </Grid>
            </>
          ) : (
            <div className="quoteDetails-container">
              <Grid item xs={12} className="application-steps">
                <Stepper step1="active" />
              </Grid>

              <Box mt={3}>
                <QuoteDetails
                  quote={quoteDetails?.quote}
                  message={quoteResponse?.message}
                  reference={quoteResponse?.quote.reference}
                />
              </Box>

              <Box mt={2}>
                <LoginSignup
                  acceptedTerms={acceptedTerms}
                  reference={quoteResponse?.quote.reference}
                  hasSubmittedLoanApplication={quoteResponse?.quote.hasSubmittedLoanApplication}
                  email={quoteResponse?.email ? quoteResponse?.email : ''}
                  accountStatus={quoteResponse?.accountStatus}
                />
              </Box>

              <RightContentWrapper useCSEmail={true} />
            </div>
          )}
        </Grid>
      </LayoutWrapper>
    </div>
  )
}

export default Quote
