import * as helperFunction from 'utils/HelperFunction'
import * as paths from './utils/Links'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useOktaAuth } from '@okta/okta-react'
import SessionTimeout from 'components/SessionTimeout/SessionTimeout'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import HeaderNavigation from 'view/portal/layout/HeaderNavigation'
import AppMenuMobile from 'view/portal/menu/appMenu/AppMenuMobile'
import SideMenu from 'view/portal/menu/sideMenu/Home'

type Props = {
  sideMenu: { loanCount: number; missedArrears: string; missedReaged: string }
}

const RequiredAuthMenu = ({ sideMenu }: Props): JSX.Element => {
  const oktaAuth = useOktaAuth()
  const authUser = oktaAuth?.authState?.isAuthenticated
  const redirectPath = localStorage.getItem('referrerPath') || ''

  useEffect(() => {
    if (authUser && helperFunction.stringIsDefinedAndNotEmpty(redirectPath)) {
      const timeout = setTimeout(() => {
        localStorage.removeItem('referrerPath')
      }, 1000)
      return () => clearTimeout(timeout)
    }
  }, [authUser, redirectPath])

  let { pathname } = useLocation()

  if (pathname.indexOf('loan-application') > -1) {
    pathname = 'loan-application'
  }

  const isMatch = paths.hideSideMenu().indexOf(pathname) > -1

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  // journey desktop and mobile use the same top menu
  let loggedTopNavMobile = true
  if (paths.hideMobileHamburger().includes(pathname)) {
    loggedTopNavMobile = false
  }

  return (
    <>
      <HeaderNavigation loggedTopNav={loggedTopNavMobile} />
      <SessionTimeout />

      {/* hide the side menu */}
      {isMatch ? '' : !isMobile && <SideMenu sideMenu={sideMenu} />}
      {paths.hideMobileHamburger().includes(pathname) ? '' : isMobile && <AppMenuMobile sideMenu={sideMenu} />}
    </>
  )
}

export default RequiredAuthMenu
