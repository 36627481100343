import './_openBanking.scss'

import React, { useEffect, useState } from 'react'

import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import UseApiRequest from './UseApiRequest'
import OpenBankingCopy from '../../components/openBanking/OpenBankingCopy'
import MandatoryOpenBankingConnectionFailure from './OpenBankingMandatory/MandatoryOpenBankingConnectionFailure'
import DocumentUpload from './DocumentUpload'
import { isFeatureEnabled } from '../../../../utils/HelperFunction'
import OpenBankingChallengerCopy from '../../components/openBanking/OpenBankingChallengerCopy'

interface OpenBankingProps {
  reference?: string
  mandatoryOpenBanking?: boolean
  reason?: string
}

const defaultProps: OpenBankingProps = {
  reference: undefined,
  mandatoryOpenBanking: false,
  reason: undefined,
}

const OpenBanking = (props: OpenBankingProps = defaultProps): JSX.Element => {
  const { openBankingLink, fetchOpenBankingLink, openBankingLinkError } = UseApiRequest()
  const [flag, setFlag] = useState(true)

  const { search } = useLocation()
  const queryParams = queryString.parse(search)
  const reference = (queryParams?.reference as string) ?? props.reference ?? ''
  const [showOptForCallComponent, setShowOptForCallComponent] = useState<boolean>(false)
  // Used for post-Bud callback
  localStorage.setItem('isMandatoryOpenBankingProcess', props.mandatoryOpenBanking ? 'true' : 'false')

  const challengerCopyEnabled = isFeatureEnabled('openBankingChallengerCopy')

  useEffect(() => {
    if (flag) {
      if (openBankingLink === '') {
        fetchOpenBankingLink(reference)
      }
      setFlag(false)
    }
  }, [flag, fetchOpenBankingLink, openBankingLink, reference])

  if (openBankingLinkError && (props.mandatoryOpenBanking ?? false)) {
    return <MandatoryOpenBankingConnectionFailure reference={reference} />
  } else if (openBankingLinkError) {
    return <DocumentUpload type={'OpenBanking'} openBankingError={true} />
  }

  if (props.mandatoryOpenBanking && challengerCopyEnabled) {
    return OpenBankingChallengerCopy(
      openBankingLink,
      false,
      showOptForCallComponent,
      setShowOptForCallComponent,
      reference,
    )
  }

  return OpenBankingCopy(
    openBankingLink,
    true,
    false,
    props.mandatoryOpenBanking ?? false,
    showOptForCallComponent,
    setShowOptForCallComponent,
    reference,
    props.reason,
  )
}

export default OpenBanking
