import './_quoteDetails.scss'

import * as features from 'utils/Features'

import { Box, Typography } from '@mui/material'

import ContinueButton from '../continueButton/Home'
import TooltipComponent from 'components/Tooltip/Tooltip'

type Props = {
  message: string | undefined
  quote: {
    amount: number | undefined
    monthlyCost: number | undefined
    duration: number | undefined
    apr: number | undefined
    interestRate: number | undefined
    arrangementFee: number | undefined
    totalPayable: number | undefined
  }
  loggedIn?: boolean
  reference?: string | undefined
}

const QuoteDetails = ({ quote, message, loggedIn, reference }: Props): JSX.Element => {
  return (
    <div className="loan-quote-container" data-test="loan-preapproved-container">
      <div className="loan-status-container">
        <Box display="flex">
          <Box className="icon-container">
            <div className="lw-system-icons-checkmark icons-colorSecordary icons-fs-40" />
          </Box>
        </Box>
        <Box display="flex">
          <Box flexGrow={2} className="title-box" data-test="title-box">
            <Typography variant="h1">Great news!</Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Box className="paragraph-box" data-test="paragraph-box">
            <p>{message}</p>
          </Box>
        </Box>
      </div>
      <div className="quote-details-container" data-test="loan-intro-quote-details">
        <Box display="flex">
          <Box flexGrow={2} className="title-box" data-test="subtitle-box">
            <Typography variant="h3">Your quote</Typography>
          </Box>
        </Box>
        {quote && (
          <div className="quote-details">
            <div className="details-alignment" data-test="loan-amount">
              <span>Loan amount</span>
              <span className="font-bold">{features.currencyFormat(Number(quote.amount), 2)}</span>
            </div>
            <div className="details-alignment" data-test="monthly-payment">
              <span>Monthly payment</span>
              <span className="font-bold">{features.currencyFormat(Number(quote.monthlyCost), 2)}</span>
            </div>
            <div className="details-alignment" data-test="loan-duration">
              <span>Term</span>
              <span className="font-bold">{quote.duration} months</span>
            </div>
            <div className="details-alignment" data-test="loan-apr">
              <span>APR</span>
              <span className="font-bold">{quote.apr}%</span>
            </div>
            <div className="details-alignment" data-test="loan-interest">
              <span>Interest rate</span>
              <span className="font-bold">{quote.interestRate}% p.a. fixed</span>
            </div>
            <div className="details-alignment" data-test="loan-fee">
              <span>
                Arrangement fee{' '}
                <TooltipComponent
                  title={`The arrangement fee is included in the monthly payment and APR.`}
                  position="top"
                />
              </span>
              <span className="font-bold">{features.currencyFormat(Number(quote.arrangementFee), 2)}</span>
            </div>
            <div className="details-alignment total-container" data-test="loan-total">
              <span>Total payable</span>
              <span className="font-bold">{features.currencyFormat(Number(quote.totalPayable), 2)}</span>
            </div>
          </div>
        )}

        {loggedIn && <ContinueButton reference={reference} />}
      </div>
    </div>
  )
}

export default QuoteDetails
