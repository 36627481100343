import '../../loanIntroduction/_loanIntro.scss'

import { Box, Typography } from '@mui/material'

type Props = {
  unRegisteredUser: boolean
  newUser: boolean
  userAccountLockedOut: boolean
  accountStatusExpiredOrRecovery: boolean
  step: number
}

const SignInAndCreateAccountHeader = ({
  unRegisteredUser,
  newUser,
  userAccountLockedOut,
  accountStatusExpiredOrRecovery,
  step,
}: Props): JSX.Element => {
  const number = step !== 0 ? `${step}. ` : ''

  return (
    <>
      <Box display="flex" className="line-separate">
        <Box flexGrow={2} className="title-box" data-test="login-title">
          <Typography variant="h4">{unRegisteredUser ? `${number}Create` : 'Log into'} your account</Typography>
        </Box>
      </Box>

      {((!newUser && !userAccountLockedOut) || accountStatusExpiredOrRecovery) && (
        <Box display="flex">
          <Box className="paragraph-box" data-test="login-info">
            <p>
              We've noticed you already have an account with us. Log in below to complete your loan application faster.
            </p>
          </Box>
        </Box>
      )}
    </>
  )
}

export default SignInAndCreateAccountHeader
