import './_yourFinances.scss'

import * as logging from 'utils/Logging'

import { Box, Grid, Typography } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'

import { NumericFormat } from 'react-number-format'
import { borrowerPublicApiClient } from '../../../../api/BorrowerPublicApiClient'
import { ReferenceDataItem } from '../../../../api/BorrowerPublicApiSchemas'
import FeedbackIndicatorsComponent from '../../../../components/FeedbackIndicators/FeedbackIndicators'
import Select from '../../../../components/Select/Baseline/Select'
import SelectReferenceData from '../../../../components/Select/Baseline/SelectReferenceData'
import { SelectOption } from '../../../../components/Select/Select.types'
import TextfieldCurrencyBaseline from '../../../../components/Textfield/Baseline/TextfieldCurrencyBaseline'

type YourFinancesProps = {
  setEmploymentStatusCallback: (status: string) => void
  setMonthlyIncomeCallback: (income: string) => void
  setTimeInRoleCallback: (timeInRole: string) => void
  errors: YourFinancesErrors
  setErrors: (errors: YourFinancesErrors) => void
  step: number
}

type YourFinancesErrors = {
  employmentStatus: boolean
  timeInRole: boolean
  monthlyIncome: boolean
}

export const employmentStatusesWithoutTimeInRole = ['Unemployed', 'Student', 'Retired', 'Homemaker']

const YourFinances = ({
  setEmploymentStatusCallback,
  setMonthlyIncomeCallback,
  setTimeInRoleCallback,
  errors,
  setErrors,
  step,
}: YourFinancesProps): JSX.Element => {
  const borrowerPublicApi = borrowerPublicApiClient()
  const [employmentStatus, setEmploymentStatus] = useState('')
  const [employmentStatusesOptions, setEmploymentStatusesOptions] = useState<ReferenceDataItem[]>([])
  const [loading, setLoading] = useState(true)
  const [monthlyIncome, setMonthlyIncome] = useState('')
  const [timeInRole, setTimeInRole] = useState('')
  const [largeMonthlyIncome, setLargeMonthlyIncome] = useState(false)

  const handleEmploymentStatusesOption = (newValue: string) => {
    setEmploymentStatus(newValue)
    setEmploymentStatusCallback(newValue)
    setErrors({
      employmentStatus: false,
      timeInRole: errors.timeInRole,
      monthlyIncome: errors.monthlyIncome,
    })
  }

  const handleMonthlyIncome = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = e.target.value
    const inputCleaned = e.target.value.replace(/,/g, '')
    const income = Number.parseInt(inputCleaned)
    if (income > 9999) {
      setLargeMonthlyIncome(true)
    } else {
      setLargeMonthlyIncome(false)
    }
    setMonthlyIncome(input)
    setMonthlyIncomeCallback(inputCleaned)
    setErrors({
      employmentStatus: errors.employmentStatus,
      timeInRole: errors.timeInRole,
      monthlyIncome: false,
    })
  }

  const handleTimeInRole = (select: string) => {
    setTimeInRole(select)
    setTimeInRoleCallback(select)
    setErrors({
      employmentStatus: errors.employmentStatus,
      timeInRole: false,
      monthlyIncome: errors.monthlyIncome,
    })
  }

  const timeInRoleOptions: SelectOption[] = [
    { id: 1, reason: '1 month' },
    { id: 2, reason: '2 months' },
    { id: 3, reason: '3 months' },
    { id: 4, reason: '4 months' },
    { id: 5, reason: '5 months' },
    { id: 6, reason: '6 months' },
    { id: 7, reason: '7 months' },
    { id: 8, reason: '8 months' },
    { id: 9, reason: '9 months' },
    { id: 10, reason: '10 months' },
    { id: 11, reason: '11 months' },
    { id: 12, reason: '1 year' },
    { id: 24, reason: '2 years' },
    { id: 36, reason: '3 years' },
    { id: 48, reason: '4 years' },
    { id: 60, reason: '5 years' },
    { id: 72, reason: '6 years' },
    { id: 84, reason: '7 years' },
    { id: 96, reason: '8 years' },
    { id: 108, reason: '9 years' },
    { id: 120, reason: '10+ years' },
  ]

  useEffect(() => {
    if (loading) {
      borrowerPublicApi.getEmploymentStatuses().then(
        (data) => {
          setEmploymentStatusesOptions(data)
        },
        (error) => {
          logging.logError('fetchEmploymentStatuses', error)
        },
      )
    }
    setLoading(false)
  }, [borrowerPublicApi, loading, setLoading])

  return (
    <Box className="Card-shadow">
      <Grid item xs={12} className="Account-content line-separate" data-test="your-finances-container">
        <Box>
          <Typography variant="h4">{step}. Your finances</Typography>
        </Box>
      </Grid>

      <Grid item xs={12} className="finances-content">
        <Box mb={2} className="employment-status-container">
          <SelectReferenceData
            variant="filled"
            label="Employment status"
            value={employmentStatus}
            options={employmentStatusesOptions}
            onChange={handleEmploymentStatusesOption}
            dataTest="employment-status"
            error={errors.employmentStatus}
            helperText={errors.employmentStatus ? 'Please select your employment status' : ''}
          />
        </Box>
        {employmentStatus !== '' && !employmentStatusesWithoutTimeInRole.includes(employmentStatus) && (
          <Box mb={2} className="time-in-role-container">
            <Select
              variant="filled"
              label="Time in role"
              value={timeInRole}
              dataTest="time-in-role"
              onChange={handleTimeInRole}
              options={timeInRoleOptions}
              error={errors.timeInRole}
              helperText={errors.timeInRole ? 'Select time in role' : ''}
            />
          </Box>
        )}
        {largeMonthlyIncome && (
          <Box className="large-monthly-income-feedback-container" mb={2}>
            <FeedbackIndicatorsComponent
              icon={'lw-marketing-icons-light-bulb-on icon-bg-notice'}
              messages="Please ensure the figure you entered is your monthly income after tax, not your annual salary after tax."
              isOpen={largeMonthlyIncome}
              className="large-monthly-income-feedback"
              closeAction={true}
              parentCallback={() => {
                setLargeMonthlyIncome(false)
              }}
            />
          </Box>
        )}
        <Box className="monthly-income-container">
          <NumericFormat
            id="monthly-income"
            decimalScale={2}
            thousandSeparator={','}
            decimalSeparator={'.'}
            customInput={TextfieldCurrencyBaseline}
            fullWidth
            value={monthlyIncome}
            onChange={handleMonthlyIncome}
            dataTest="monthly-income"
            label="Monthly income after tax"
            error={errors.monthlyIncome}
            helperText={
              errors.monthlyIncome
                ? 'Please enter your monthly income after tax'
                : 'Net monthly income, excluding any bonus'
            }
          />
        </Box>
      </Grid>
    </Box>
  )
}

export default YourFinances
