/** This code is generated, do not change */
/* eslint-disable */
import { ResponseType } from 'axios'
import * as types from './BorrowerPublicApiSchemas'

export abstract class BorrowerPublicApiContract {
  abstract postObject<T>(url: string, body: unknown, contentType: string, responseType: ResponseType): Promise<T>
  abstract fetchObject<T>(url: string, responseType: ResponseType, searchParams: URLSearchParams | null): Promise<T>

  getAddressDataItemList(postcode: string): Promise<types.AddressDataItem[]> {
    return this.fetchObject<types.AddressDataItem[]>(`/reference-data/addresses`, 'json', new URLSearchParams(`?postcode=${postcode || ''}`))
  }

  getCardPaymentResponse(uuid: string): Promise<types.CardPaymentResponse> {
    return this.fetchObject<types.CardPaymentResponse>(`/public/card-payment-requests/${uuid}`, 'json', null)
  }

  getEmploymentPositions(): Promise<types.ReferenceDataItem[]> {
    return this.fetchObject<types.ReferenceDataItem[]>(`/reference-data/employment-positions`, 'json', null)
  }

  getEmploymentSectors(): Promise<types.ReferenceDataItem[]> {
    return this.fetchObject<types.ReferenceDataItem[]>(`/reference-data/employment-sectors`, 'json', null)
  }

  getEmploymentStatuses(): Promise<types.ReferenceDataItem[]> {
    return this.fetchObject<types.ReferenceDataItem[]>(`/reference-data/employment-statuses`, 'json', null)
  }

  getIncomeChangeReasons(): Promise<types.ReferenceDataItem[]> {
    return this.fetchObject<types.ReferenceDataItem[]>(`/reference-data/income-change-reasons`, 'json', null)
  }

  getLoanPurposes(): Promise<types.ReferenceDataItem[]> {
    return this.fetchObject<types.ReferenceDataItem[]>(`/reference-data/loan-purposes`, 'json', null)
  }

  getOpenBankingAuthenticationUrlResponse(reference: string, userId: number): Promise<types.OpenBankingAuthenticationUrlResponse> {
    return this.fetchObject<types.OpenBankingAuthenticationUrlResponse>(`/public/open-banking/authentication-link`, 'json', new URLSearchParams(`?reference=${reference || ''}&userId=${userId || ''}`))
  }

  getOpenBankingConnectionStatusResponse(taskId: string, userId: number, reference: string): Promise<types.OpenBankingConnectionStatusResponse> {
    return this.fetchObject<types.OpenBankingConnectionStatusResponse>(`/public/open-banking/connection-completed`, 'json', new URLSearchParams(`?taskId=${taskId || ''}&userId=${userId || ''}&reference=${reference || ''}`))
  }

  getQuoteIntroduction(uuid: string, urn?: string, quoteResultRank?: number): Promise<types.QuoteIntroduction> {
    return this.fetchObject<types.QuoteIntroduction>(`/public/quote-introductions/${uuid}`, 'json', new URLSearchParams(`?urn=${urn || ''}&quoteResultRank=${quoteResultRank || ''}`))
  }

  getResidentialStatuses(): Promise<types.ReferenceDataItem[]> {
    return this.fetchObject<types.ReferenceDataItem[]>(`/reference-data/residential-statuses`, 'json', null)
  }

  postCreateQuoteRequest(body: types.CreateQuoteRequest): Promise<types.CreateQuoteResponse> {
    return this.postObject<types.CreateQuoteResponse>(`/public/commands/create-quote`, body, 'application/json', 'json')
  }

  postMarkCardPaymentRequestCompletedRequest(body: types.MarkCardPaymentRequestCompletedRequest): Promise<types.MarkCardPaymentRequestCompletedResponse> {
    return this.postObject<types.MarkCardPaymentRequestCompletedResponse>(`/public/card-payment-requests/completed`, body, 'application/json', 'json')
  }

  postRecoverCustomerRequest(body: types.RecoverCustomerRequest): Promise<types.RecoverCustomerResponse> {
    return this.postObject<types.RecoverCustomerResponse>(`/public/commands/recover-customer`, body, 'application/json', 'json')
  }

  postRegisterCustomerRequest(body: types.RegisterCustomerRequest): Promise<types.RegisterCustomerResponse> {
    return this.postObject<types.RegisterCustomerResponse>(`/public/commands/register-customer`, body, 'application/json', 'json')
  }

  postUnauthorisedPaymentIntentRequest(body: types.UnauthorisedPaymentIntentRequest): Promise<types.PaymentIntentResponse> {
    return this.postObject<types.PaymentIntentResponse>(`/public/commands/create-payment-intent`, body, 'application/json', 'json')
  }
}