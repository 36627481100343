import * as helper from 'utils/HelperFunction'
import * as logging from 'utils/Logging'

import { AccountDetails, UpdateAccountDetailsRequest } from 'api/BorrowerApiSchemas'
import { Dispatch, SetStateAction, useState } from 'react'

import { borrowerApiClient } from 'api/BorrowerApiClient'

interface AccountDetailsProps {
  accountDetails: AccountDetails
  apiResponseSuccessMessage: string
  apiResponseErrorMessage: string
  fetchAccountDetails: (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => void
  postAccountDetails: (
    mobile: string,
    receiveMarketingInfo: boolean,
    telephone: string,
    setIsSubmitting: (loading: boolean) => void,
  ) => void
}

const UseAccountApiRequest = (): AccountDetailsProps => {
  const borrowerApi = borrowerApiClient()

  const [apiResponseSuccessMessage, setApiResponseSuccessMessage] = useState<string>('')
  const [apiResponseErrorMessage, setApiResponseErrorMessage] = useState<string>('')
  const [accountDetails, setAccountDetails] = useState<AccountDetails>({
    userId: -1,
    email: '',
    firstName: '',
    joinDate: new Date(),
    middleName: '',
    mobile: '',
    receiveMarketingInfo: false,
    surname: '',
    telephone: '',
    membershipNumber: '',
    dateOfBirth: new Date(),
    title: '',
    residentialStatus: '',
  })

  const fetchAccountDetails = (setLoading: (loading: boolean) => void) => {
    borrowerApi
      .getAccountDetails()
      .then(
        (val) => {
          setAccountDetails({
            userId: val.userId,
            email: val.email,
            firstName: val.firstName,
            joinDate: val.joinDate,
            middleName: val.middleName,
            mobile: val.mobile,
            receiveMarketingInfo: val.receiveMarketingInfo,
            surname: val.surname,
            telephone: val.telephone,
            membershipNumber: val.membershipNumber,
            address: val.address,
            dateOfBirth: val.dateOfBirth,
            title: val.title,
            residentialStatus: val.residentialStatus,
          })
        },
        (error) => {
          logging.logError('fetchAccountDetails', error)
        },
      )
      .finally(() => {
        setTimeout(() => {
          if (helper.checkIsLoadingFunction(setLoading)) {
            setLoading(false)
          }
        }, 100)
      })
  }

  const postAccountDetails = (
    mobile: string,
    receiveMarketingInfo: boolean,
    telephone: string,
    setIsSubmitting: (loading: boolean) => void,
  ) => {
    const body: UpdateAccountDetailsRequest = {
      mobile: mobile.replace(/\s+/g, ''),
      receiveMarketingInfo,
      telephone: telephone.replace(/\s+/g, ''),
    }
    setApiResponseSuccessMessage('')
    setApiResponseErrorMessage('')
    borrowerApi
      .postUpdateAccountDetailsRequest(body)
      .then(
        (val) => {
          setApiResponseSuccessMessage(val.message)
        },
        (error) => {
          logging.logError('postAccountDetails', error)
          setApiResponseErrorMessage(error.data.message)
        },
      )
      .finally(() => {
        setTimeout(() => {
          if (helper.checkIsLoadingFunction(setIsSubmitting)) {
            setIsSubmitting(false)
          }
        }, 100)
      })
  }

  return {
    accountDetails,
    apiResponseSuccessMessage,
    apiResponseErrorMessage,
    fetchAccountDetails,
    postAccountDetails,
  }
}

export default UseAccountApiRequest
