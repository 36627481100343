import * as helper from 'utils/HelperFunction'
import * as logging from 'utils/Logging'

import { QuoteDetails } from '../../../../api/BorrowerApiSchemas'

import { borrowerApiClient } from 'api/BorrowerApiClient'
import { useState } from 'react'

interface Props {
  apiResponseLoanQuote: string
  loanQuoteDetails: QuoteDetails | null
  fetchLoanQuoteDetails: (ref: string, setLoading: (loading: boolean) => void) => void
}

const UseApiLoanApplication = (): Props => {
  const borrowerApi = borrowerApiClient()

  const [loanQuoteDetails, setLoanQuoteDetails] = useState<QuoteDetails | null>(null)
  const [apiResponseLoanQuote, setApiResponseLoanQuote] = useState<string>('')

  const fetchLoanQuoteDetails = (ref: string, setLoading: (loading: boolean) => void) => {
    borrowerApi
      .getQuoteDetails(ref)
      .then(
        (quote) => {
          setLoanQuoteDetails(quote)
        },
        (error) => {
          if (error && error.data && error.data.message) {
            setApiResponseLoanQuote(error.data.message)
            logging.logError('fetchLoanQuoteDetails', error)
          }
        },
      )
      .finally(() => {
        if (helper.checkIsLoadingFunction(setLoading)) {
          setLoading(false)
        }
      })
  }

  return {
    apiResponseLoanQuote,
    loanQuoteDetails,
    fetchLoanQuoteDetails,
  }
}
export default UseApiLoanApplication
