import * as paths from './Links'

/*
 *
 * Top Menu
 *
 */
export const menuItems = [
  {
    menuTitle: 'Dashboard',
    pageURL: paths.getDashboard(),
    badge: false,
    testDescription: 'dashboard-account-menu',
  },
  {
    menuTitle: 'Notifications',
    pageURL: paths.getNotifications(),
    badge: true,
    testDescription: 'notifications-account-menu',
  },
  {
    menuTitle: 'Help',
    pageURL: paths.marketing.helpCentre.overview.url,
    badge: false,
    testDescription: 'help-account-menu',
    serverRedirect: true,
  },
  {
    menuTitle: 'Contact',
    pageURL: paths.marketing.contactUs.url,
    badge: false,
    testDescription: 'contact-account-menu',
    serverRedirect: true,
  },
]

export const menuSubitems = [
  {
    menuTitle: 'Account details',
    pageURL: paths.getAccounts(),
    icon: 'lw-marketing-icons-profile',
    testDescription: 'account-details-account-menu',
  },
  {
    menuTitle: 'Log out',
    pageURL: paths.getLogout(),
    icon: 'lw-system-icons-logout',
    testDescription: 'log-out-account-menu',
  },
]

/*
 *
 * Side Menu
 *
 */

export const sideLoanManagementMenuItems = [
  {
    name: 'Manage payments',
    link: paths.getAllManagePayments(),
    icon: 'lw-marketing-icons-payments',
    testDescription: 'manage-payments-left-side-menu',
  },
  {
    name: 'Change payment day',
    link: paths.getAllChangePaymentDay(),
    icon: 'lw-marketing-icons-calendar',
    testDescription: 'change-payment-left-side-menu',
  },
  {
    name: 'Make overpayment',
    link: paths.getMakeOverpayment(),
    icon: 'lw-marketing-icons-card',
    testDescription: 'overpayment-left-side-menu',
  },
  {
    name: 'Settle loan',
    link: paths.getSettleLoan(),
    icon: 'lw-system-icons-checkmark',
    testDescription: 'settle-loan-left-side-menu',
  },
  {
    name: 'Loan statements',
    link: paths.getAllViewLoanStatement(),
    icon: 'lw-system-icons-file',
    testDescription: 'loan-statements-left-side-menu',
  },
  {
    name: 'Credit documents',
    link: paths.getAllViewCreditDocument(),
    icon: 'lw-system-icons-open-envelope',
    testDescription: 'credit-documents-left-side-menu',
  },
  {
    name: 'Pay arrears',
    link: paths.getAllPayArrears(),
    icon: 'lw-system-icons-alert',
    testDescription: 'pay-arrears-left-side-menu',
  },
  {
    name: 'Pay re-aged',
    link: paths.getAllPayReAged(),
    icon: 'lw-system-icons-alert',
    testDescription: 'pay-re-aged-left-side-menu',
  },
]

export const sideTopMenuItems = [
  {
    name: 'Dashboard',
    link: paths.getDashboard(),
    icon: 'lw-marketing-icons-home',
    testDescription: 'dashboard-left-side-menu',
  },
  {
    name: 'Get a loan quote',
    link: paths.getLoanQuote(),
    icon: 'lw-marketing-icons-loan-offer',
    testDescription: 'get-loan-quote-left-side-menu',
  },
  {
    name: 'Upload documents',
    link: paths.getUploadDocuments(),
    icon: 'lw-system-icons-cloud-upload',
    testDescription: 'upload-documents-left-side-menu',
  },
]
