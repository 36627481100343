import './_hamburger.scss'

export type Props = {
  open: boolean
  setOpen: (v: boolean) => void
}

const Hamburger = (props: Props): JSX.Element => (
  <>
    <div className={`hamburger ${props.open ? 'is-active' : ''}`} onClick={() => props.setOpen(!props.open)}>
      <div className="hamburger-box" data-test="hamburger-menu">
        <div className="hamburger-inner"></div>
      </div>
    </div>
  </>
)

export default Hamburger
