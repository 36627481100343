import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from 'react-router-dom'
import * as paths from 'utils/Links'
import * as logging from 'utils/Logging'
import * as variable from 'utils/Variable'

let countdownInterval: NodeJS.Timeout
let timeout: NodeJS.Timeout

const SessionTimeout = (): JSX.Element | null => {
  const navigate = useNavigate()

  const [remaining, setRemaining] = useState<number>(0)

  const clearSessionTimeout = () => {
    clearTimeout(timeout)
  }

  const clearSessionInterval = () => {
    clearInterval(countdownInterval)
  }

  const handleLogout = async () => {
    try {
      clearSessionInterval()
      clearSessionTimeout()
      navigate(paths.getLogout())
    } catch (err) {
      const error = {
        name: `${err}`,
      }
      logging.logError('SessionTimeout', error)
    }
  }

  const onIdle = () => {
    console.log('onIdle', remaining)

    const delay = 1000 * 1
    timeout = setTimeout(() => {
      let countDown = 10
      countdownInterval = setInterval(() => {
        if (countDown > 0) {
          console.log(--countDown)
        } else {
          handleLogout()
        }
      }, 1000)
    }, delay)
  }

  const onActive = () => {
    console.log('onActive')
    clearSessionInterval()
    clearSessionTimeout()
  }

  const onAction = () => {
    console.log('onAction')
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: variable.activityTimeout,
    throttle: 250,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  return <></>
}

export default SessionTimeout
