import { Loan, PaymentType } from 'api/BorrowerApiSchemas'
import * as logging from 'utils/Logging'
import * as variable from 'utils/Variable'

import { FormEvent } from 'react'

export function currencyFormat(amount: number, decimals: number): string {
  return `£${amount.toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
}

export function getAprFormat(num: number, decimals: number): string {
  return num.toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + '%'
}

// display the file size
export function formatBytes(bytes: number): string {
  const sufixes = ['Bytes', 'KB', 'MB']

  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return (!bytes && '0 Bytes') || (bytes / Math.pow(1024, i)).toFixed(0) + sufixes[i]
}

// abstract the paragraph if too long
export function textAbstract(text: string, maxlength = 20): string {
  if (text == null) {
    return ''
  }
  if (text.length <= maxlength) {
    return text
  }
  const lastFive = text.substring(text.length - 6)

  text = text.substring(0, maxlength)

  return `${text}...${lastFive}`
}

// List consecutive numbers from 1 to 28.
export function getDropdownAllDays(): {
  id: number
  name: string
  value: string | number
}[] {
  const paymentDaylists = []
  for (let i = 1; i <= 28; i++) {
    let ordinal
    switch (i) {
      case 1:
      case 21:
        ordinal = 'st'
        break
      case 2:
      case 22:
        ordinal = 'nd'
        break
      case 3:
      case 23:
        ordinal = 'rd'
        break
      default:
        ordinal = 'th'
    }

    paymentDaylists.push({
      id: i,
      name: i + ordinal,
      value: i,
    })
  }
  return paymentDaylists
}

// get the reference from url string param or cookies
export function getReference(): string {
  const queryParams = new URLSearchParams(window.location.search)
  let ref = window.sessionStorage.getItem(variable.defaultRef) as string
  if (queryParams.get('ref') !== null) {
    ref = queryParams.get('ref') as string
  }
  return ref
}

// get the string param from url
export function getUrl(stringURL: string): string {
  const queryParams = new URLSearchParams(window.location.search)
  if (queryParams.get(stringURL) !== null) {
    return queryParams.get(stringURL) as string
  }
  return ''
}

export function capitalizeFirstLetter(str: string): string {
  return (
    str[0].toUpperCase() +
    str
      .slice(1)
      .replace(/([A-Z])/g, ' $1')
      .trim()
      .toLowerCase()
  )
}

export function ukBankSortCode(str: string): string {
  return str.substring(0, 2) + '-' + str.substring(2, 4) + '-' + str.substring(4, 6)
}

// convert the subheading inside the DD Form more readable
export function readableAmountTitle(forType: PaymentType): string {
  let title = ''
  switch (forType) {
    case variable.paymentTypeOverpayment:
      title = ''
      break
    case variable.paymentTypeEarlySettlement:
      title = 'Settlement amount'
      break
    case variable.paymentTypeReAged:
      title = 'Re-aged payments'
      break
    default:
      title = 'Arrears'
  }

  return title
}

// check this user have 1 loan or more than 1 loan
export function singleLoanDetails(loans: Loan[]): Loan | Loan[] | null {
  const counts = loans.length

  const ref = window.sessionStorage.getItem(variable.defaultRef)
  const currLoan = loans.filter((item) => item.plan.reference === ref)

  if (counts === undefined) {
    return loans
  }

  return currLoan[0]
}

const cardBrandToPfClass = {
  visa: 'pf-visa',
  mastercard: 'pf-mastercard',
  amex: 'pf-american-express',
  discover: 'pf-discover',
  diners: 'pf-diners',
  jcb: 'pf-jcb',
  unknown: 'pf-credit-card-unknown',
}

export function getBrandIcon(company: string): string {
  let brand = ''
  Object.entries(cardBrandToPfClass).map(([key, value]) => {
    if (key === company) {
      return (brand = value)
    }
    return null
  })

  return brand
}

export function checkPostcode(postcode: string): boolean {
  const trimPostcode = postcode.replace(/\s/g, '')
  const regex = /^[a-zA-Z]{1,2}\d{1,2}[a-zA-Z]? ?\d[a-zA-Z]{2}$/i

  return regex.test(trimPostcode)
}

export function checkPhoneNumber(number: string): boolean {
  const trimNumber = number.replace(/\s/g, '')
  const regex = /^0([1-6]\d{8,10}|7\d{9})$/

  return regex.test(trimNumber)
}

// start with 07
export function checkMobileNumber(number: string): boolean {
  const trimNumber = number.replace(/\s/g, '')
  const regex = /^(07\d{9})$/

  return regex.test(trimNumber)
}

export function validateNumbersInput(value: string): string {
  return value.replace(/\D+$/g, '').replace(/(\..*?)\..*/g, '$1')
}

export function allowNumbersInput(e: FormEvent<HTMLInputElement>): string {
  return ((e.target as HTMLInputElement).value = validateNumbersInput((e.target as HTMLInputElement).value))
}

export function transformSortCode(value: string): string {
  if (value != null) {
    value = value
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{2})/g, '$1-')
      .trim()
  }
  return value
}

/* specail character list
 */
export const eventKeysListsCheck = (key: string | undefined): boolean => {
  const eventKeys: string[] = [
    '/',
    '?',
    '.',
    ',',
    '!',
    '@',
    '£',
    '#',
    '$',
    '%',
    '^',
    '&',
    '*',
    '(',
    ')',
    '{',
    '}',
    '[',
    ']',
    '+',
    '=',
    "'",
    '"',
    '`',
    '~',
    '<',
    '>',
  ]

  if (eventKeys.includes(key as string)) {
    return true
  } else {
    return false
  }
}

const usernameInPasswordCheckSucceeds = (email: string, password: string): boolean => {
  const usernamePartsFourCharsOrLonger = email.match(/[a-zA-Z]{4,}/g)?.map((regexMatch) => regexMatch.toString()) ?? []
  const lowercasePassword = password.toLowerCase()

  for (const usernamePart of usernamePartsFourCharsOrLonger) {
    if (lowercasePassword.includes(usernamePart.toLowerCase())) {
      return false
    }
  }

  return true
}

interface PasswordValidationProps {
  email: boolean
  capital: boolean
  lowercase: boolean
  digit: boolean
  special: boolean
  length: boolean
  full: boolean
}

export function passwordValidate(password: string, email: string): PasswordValidationProps {
  const re = {
    capital: /(?=.*?[A-Z])/,
    lowercase: /(?=.*?[a-z])/,
    digit: /(?=.*?\d)/,
    special: /(?=.*?[#?!@$%^&*-])/,
    length: /.{8,}/,
  }

  return {
    email: usernameInPasswordCheckSucceeds(email, password),
    capital: re.capital.test(password),
    lowercase: re.lowercase.test(password),
    digit: re.digit.test(password),
    special: re.special.test(password),
    length: re.length.test(password),
    full:
      usernameInPasswordCheckSucceeds(email, password) &&
      re.capital.test(password) &&
      re.lowercase.test(password) &&
      re.digit.test(password) &&
      re.special.test(password) &&
      re.length.test(password),
  }
}

export function emailValidate(email: string): boolean {
  const emailExp = /^[a-zA-Z\d._-]+([^@]*)@[a-zA-Z\d.-]+\.[a-zA-Z]{2,6}$/
  return emailExp.test(email)
}

const brandLogoList = [
  'REV', //Revolut
  'REV2', //Revolut
  'MoneySupermarket',
  'ClearScore',
  'RPD-CTM', //CompareTheMarket
  'Experian',
  'Experian Credit Matcher',
  'EXP',
  'Churchill Loans',
  'dll', //DirectLine
  'INT14', //LoansWarehouse
]

export function brandLogoCheck(brand: string): boolean {
  const brandHasLogo = brandLogoList.some((value) => value === brand)
  return brandHasLogo
}

export function checkItemInArray(list: string[], item: string): boolean {
  return list.includes(item)
}

export function checkItemNotInArray(list: string[], item: string): boolean {
  return !list.includes(item)
}

export function underAgeValidate(DOB: string) {
  const today = new Date()
  const birthDate = new Date(DOB)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }

  return age
}

export function heapTrack(track: string) {
  if (window.heap) {
    window.heap.track(track)
    logging.logInfo(`tracking ${track} with heap`)
  } else {
    logging.logInfo('heap disabled')
  }
}

export function getBrowserHiddenProps(pageTitle: string, attentionMessage: string) {
  document.title = pageTitle

  document.addEventListener('visibilitychange', function (e) {
    const isPageActive = !document.hidden

    if (!isPageActive) {
      document.title = attentionMessage
    } else {
      document.title = pageTitle
    }
  })
}
