import * as logging from 'utils/Logging'

import { OktaAuth } from '@okta/okta-auth-js'
import { setFeatureCookies } from '../utils/HelperFunction'
import { borrowerApiClient } from './BorrowerApiClient'
import { AccountDetails, Feature } from './BorrowerApiSchemas'
import { QuoteIntroduction } from './BorrowerPublicApiSchemas'

interface trackProperties {
  loanAmount?: number
  loanTerm?: number
  loanApr?: number
  loanFee?: number
  message?: string
  reference?: string
}

declare global {
  interface Window {
    heap: {
      identify: (arg0: number) => void
      track: (name: string, properties?: trackProperties) => void
      resetIdentity: () => void
      addUserProperties: (arg0: object) => void
    }
  }
}

const identifyHeapUser = (oktaAuth: OktaAuth) => {
  borrowerApiClient(oktaAuth)
    .getAccountDetails()
    .then((acc: AccountDetails) => {
      setAndTrackFeatureCookies(acc)
    })
}

const setAndTrackFeatureCookies = (acc: AccountDetails) => {
  setFeatureCookies(acc.features)
  if (window.heap) {
    window.heap.identify(acc.userId)
    window.heap.addUserProperties(getHeapProperties(acc.email, acc.features))
    logging.logInfo('identified ' + acc.userId + ' with heap')
  } else {
    logging.logInfo('heap disabled')
  }
}

const getHeapProperties = (email: string, features?: Feature[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const heapProperties: any = {}
  features?.forEach((feature) => {
    heapProperties[feature.name] = feature.enabled.toString()
  })
  // actually a built-in property, casing is important
  heapProperties['email'] = email
  return heapProperties
}

const trackQuoteDetails = (quoteIntroduction: QuoteIntroduction) => {
  if (window.heap) {
    window.heap.track('loan request details', {
      reference: quoteIntroduction.quote.reference,
      message: quoteIntroduction.message,
      loanAmount: quoteIntroduction.quote.amount,
      loanTerm: quoteIntroduction.quote.duration,
      loanFee: quoteIntroduction.quote.arrangementFee,
      loanApr: quoteIntroduction.quote.apr,
    })
    logging.logInfo('tracking quote with loan ' + quoteIntroduction.quote.reference + ' with heap')
  } else {
    logging.logInfo('heap disabled')
  }
}

const resetHeapUser = () => {
  if (window.heap && window.heap.resetIdentity) {
    window.heap.resetIdentity()
    logging.logInfo('heap user reset')
  } else {
    logging.logInfo('heap disabled')
  }
}

export { identifyHeapUser, resetHeapUser, setAndTrackFeatureCookies, trackQuoteDetails }
