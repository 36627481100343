import * as logging from 'utils/Logging'

import { borrowerApiClient } from 'api/BorrowerApiClient'
import { useState } from 'react'

interface NotificationProps {
  notifications: number
  getNotificationsSummary: () => void
}

const UseApiRequest = (): NotificationProps => {
  const borrowerApi = borrowerApiClient()
  const [notifications, setNotifications] = useState(0)

  const getNotificationsSummary = () => {
    borrowerApi.getNotificationsSummary().then(
      (value) => {
        if (value.unread) {
          setNotifications(value.unread)
        }
      },
      (error) => {
        logging.logError('AccountMenu oktaAuth.getUser', error)
      },
    )
  }

  return {
    notifications,
    getNotificationsSummary,
  }
}

export default UseApiRequest
