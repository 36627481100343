import '../Select.scss'

import { FormControl, FormHelperText, InputLabel } from '@mui/material'
import { OptionLists, SelectOption, SelectProps } from '../Select.types'
import Select, { SelectChangeEvent } from '@mui/material/Select'

const SelectComponents = (props: SelectProps): JSX.Element => {
  const handleChange = (e: SelectChangeEvent<string>) => {
    const newValue = e.target.value as string

    // Here, we invoke the callback with the new value
    props.onChange(newValue)
  }

  const options = props.options ? props.options : OptionLists

  return (
    <>
      <FormControl variant={props.variant} className="SelectComponents-formControl" data-test="form-control-component">
        <InputLabel id="loan-cancellation-label" error={props.error}>
          {props.label}
        </InputLabel>
        <Select
          labelId="loan-cancellation-label"
          id="loan-cancellation"
          native
          value={props.value}
          data-test={props.dataTest ?? 'select-component'}
          onChange={handleChange}
          IconComponent={() => <span className="lw-system-icons-arrow-down"></span>}
          error={props.error}
          label={props.label}
        >
          <option value="" disabled />
          {options.map((val: SelectOption) => (
            <option key={val.id} value={val.id}>
              {val.reason}
            </option>
          ))}
        </Select>
        {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
        {props.error && <FormHelperText>{props.errorText}</FormHelperText>}
      </FormControl>
    </>
  )
}

SelectComponents.defaultProps = {
  variant: 'filled',
  label: 'select name',
  options: OptionLists,
}

export default SelectComponents
