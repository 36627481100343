import * as config from 'api/config'

import { AxiosRequestConfig, ResponseType } from 'axios'
import { BorrowerPublicApiContract } from './BorrowerPublicApiContract'
import { ErrorResponse } from './ErrorResponse'
import { filterEmptyParams } from './BorrowerApiClient'

class AxiosBorrowerPublicApi extends BorrowerPublicApiContract {
  postObject<T>(url: string, body: unknown, contentType: string, responseType: ResponseType): Promise<T> {
    const requestConfig = AxiosBorrowerPublicApi.requestConfig(contentType, responseType)
    return config.httpProxy
      .post(url, body, requestConfig)
      .then((res) => {
        return res.data as T
      })
      .catch((error) => {
        return Promise.reject(new ErrorResponse(error.response.status, error.response.data))
      })
  }

  fetchObject<T>(url: string, responseType: ResponseType, searchParams: URLSearchParams | null = null): Promise<T> {
    const requestConfig = AxiosBorrowerPublicApi.requestConfig(undefined, responseType, searchParams)
    return config.httpProxy
      .get(url, requestConfig)
      .then((res) => {
        return res.data as T
      })
      .catch((error) => {
        if (error.response !== undefined) {
          return Promise.reject(new ErrorResponse(error.response.status, error.response.data))
        } else {
          return Promise.reject(error)
        }
      })
  }

  private static requestConfig(
    contentType = 'application/json',
    responseType: ResponseType,
    searchParams: URLSearchParams | null = null,
  ): AxiosRequestConfig {
    return {
      headers: {
        'Content-Type': contentType,
      },
      responseType: responseType,
      params: filterEmptyParams(searchParams),
    }
  }
}

export function borrowerPublicApiClient(): BorrowerPublicApiContract {
  return new AxiosBorrowerPublicApi()
}
