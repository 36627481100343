import './_headerNavigation.scss'

import { AppBar, Box, Button, Toolbar } from '@mui/material'

import FluroLogo from 'components/Logo/Logo'

import useNavigationSelector from '../common/NavigationSelector'
import AccountMenu from '../menu/appMenu/AccountMenu'

type Props = {
  logged?: boolean
  isQuickQuote?: boolean
}

const HeaderNavigationWithLogin = ({ logged, isQuickQuote }: Props): JSX.Element => {
  const { isSticky } = useNavigationSelector()

  return (
    <AppBar className={`HeaderNavigation-header centre-logo${isSticky ? '' : '  white-logo'}`}>
      <Toolbar className="HeaderNavigation-headerToolbar">
        {logged ? (
          <>
            <Box flexGrow={1}>
              <FluroLogo isWhite={!isSticky && isQuickQuote} />
            </Box>
            <Box>
              <AccountMenu />
            </Box>
          </>
        ) : (
          <>
            <Box flexGrow={1}>
              <FluroLogo isWhite={!isSticky && isQuickQuote} />
            </Box>
            <Box>
              <Button size="small" variant="outlined" href="/sign-in" className="button-login">
                Log in
              </Button>
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default HeaderNavigationWithLogin
