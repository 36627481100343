/** This code is generated, do not change */
/* eslint-disable */
import { ResponseType } from 'axios'
import * as types from './BorrowerApiSchemas'

export abstract class BorrowerApiContract {
  abstract postObject<T>(url: string, body: unknown, contentType: string, responseType: ResponseType): Promise<T>
  abstract fetchObject<T>(url: string, responseType: ResponseType, searchParams: URLSearchParams | null): Promise<T>

  downloadDirectDebitInstructions(reference: string): Promise<Blob> {
    return this.fetchObject<Blob>(`/quotes/${reference}/review-documents/direct-debit-instructions`, 'blob', null)
  }

  downloadFile(fileId: string): Promise<Blob> {
    return this.fetchObject<Blob>(`/files/${fileId}`, 'blob', null)
  }

  downloadLoanAgreement(reference: string): Promise<Blob> {
    return this.fetchObject<Blob>(`/loans/${reference}/agreement`, 'blob', null)
  }

  downloadPreContractExplanations(reference: string): Promise<Blob> {
    return this.fetchObject<Blob>(`/quotes/${reference}/review-documents/pre-contract-explanations`, 'blob', null)
  }

  downloadPreCreditContract(reference: string): Promise<Blob> {
    return this.fetchObject<Blob>(`/quotes/${reference}/review-documents/pre-credit-contract`, 'blob', null)
  }

  downloadRepaymentAgreement(reference: string, repaymentId: number): Promise<Blob> {
    return this.fetchObject<Blob>(`/loans/${reference}/repayment-agreement/${repaymentId}`, 'blob', null)
  }

  downloadStatement(reference: string, year: number): Promise<Blob> {
    return this.fetchObject<Blob>(`/loans/${reference}/statements/${year}`, 'blob', null)
  }

  downloadUnsignedLoanAgreement(reference: string): Promise<Blob> {
    return this.fetchObject<Blob>(`/quotes/${reference}/review-documents/agreement`, 'blob', null)
  }

  getAccountDetails(): Promise<types.AccountDetails> {
    return this.fetchObject<types.AccountDetails>(`/account-details`, 'json', null)
  }

  getBankAccountInstructions(forType: types.PaymentType, reference: string): Promise<types.BankAccountInstructions> {
    return this.fetchObject<types.BankAccountInstructions>(`/loans/${reference}/instructions/bank-transfer-payment`, 'json', new URLSearchParams(`?forType=${forType || ''}`))
  }

  getCalculateOverpaymentResponse(amount: number, reference: string): Promise<types.CalculateOverpaymentResponse> {
    return this.fetchObject<types.CalculateOverpaymentResponse>(`/loans/${reference}/calculate-overpayment`, 'json', new URLSearchParams(`?amount=${amount || ''}`))
  }

  getDebitCardInstructions(forType: types.PaymentType, reference: string): Promise<types.DebitCardInstructions> {
    return this.fetchObject<types.DebitCardInstructions>(`/loans/${reference}/instructions/debit-card-payment`, 'json', new URLSearchParams(`?forType=${forType || ''}`))
  }

  getDirectDebitInstructions(forType: types.PaymentType, reference: string): Promise<types.DirectDebitInstructions> {
    return this.fetchObject<types.DirectDebitInstructions>(`/loans/${reference}/instructions/direct-debit-payment`, 'json', new URLSearchParams(`?forType=${forType || ''}`))
  }

  getLoan(reference: string): Promise<types.Loan> {
    return this.fetchObject<types.Loan>(`/loans/${reference}`, 'json', null)
  }

  getLoanApplicationDetails(reference: string): Promise<types.LoanApplicationDetails> {
    return this.fetchObject<types.LoanApplicationDetails>(`/loan-applications/${reference}`, 'json', null)
  }

  getLoanSchedule(reference: string): Promise<types.LoanSchedule> {
    return this.fetchObject<types.LoanSchedule>(`/loans/${reference}/schedules`, 'json', null)
  }

  getLoanStatementsList(): Promise<types.LoanStatements[]> {
    return this.fetchObject<types.LoanStatements[]>(`/loans/statements`, 'json', null)
  }

  getLoanSummaryList(): Promise<types.LoanSummary[]> {
    return this.fetchObject<types.LoanSummary[]>(`/loans`, 'json', null)
  }

  getNotificationsSummary(): Promise<types.NotificationsSummary> {
    return this.fetchObject<types.NotificationsSummary>(`/notifications/summary`, 'json', null)
  }

  getOpenBankingApplyResponse(reference: string): Promise<types.OpenBankingApplyResponse> {
    return this.fetchObject<types.OpenBankingApplyResponse>(`/open-banking/apply`, 'json', new URLSearchParams(`?reference=${reference || ''}`))
  }

  getOpenBankingAuthenticationUrlResponse(reference: string, redirectUrl?: string): Promise<types.OpenBankingAuthenticationUrlResponse> {
    return this.fetchObject<types.OpenBankingAuthenticationUrlResponse>(`/open-banking/authentication-link`, 'json', new URLSearchParams(`?reference=${reference || ''}&redirectUrl=${redirectUrl || ''}`))
  }

  getOpenBankingConnectionStatusResponse(reference: string, taskId: string): Promise<types.OpenBankingConnectionStatusResponse> {
    return this.fetchObject<types.OpenBankingConnectionStatusResponse>(`/open-banking/connection-completed`, 'json', new URLSearchParams(`?reference=${reference || ''}&taskId=${taskId || ''}`))
  }

  getQuoteCancellationReasonList(): Promise<types.QuoteCancellationReason[]> {
    return this.fetchObject<types.QuoteCancellationReason[]>(`/quotes/cancellation-reasons`, 'json', null)
  }

  getQuoteDetails(reference: string): Promise<types.QuoteDetails> {
    return this.fetchObject<types.QuoteDetails>(`/quotes/${reference}`, 'json', null)
  }

  getQuoteFinalInformation(reference: string): Promise<types.QuoteFinalInformation> {
    return this.fetchObject<types.QuoteFinalInformation>(`/quotes/${reference}/final-information`, 'json', null)
  }

  getQuoteList(): Promise<types.Quote[]> {
    return this.fetchObject<types.Quote[]>(`/quotes`, 'json', null)
  }

  getRepaymentPlanDetails(uuid: string): Promise<types.RepaymentPlanDetails> {
    return this.fetchObject<types.RepaymentPlanDetails>(`/repayment-plans/${uuid}`, 'json', null)
  }

  getSettlementReasonList(): Promise<types.SettlementReason[]> {
    return this.fetchObject<types.SettlementReason[]>(`/settlement-reasons`, 'json', null)
  }

  getSettlementReasonResponse(reference: string): Promise<types.SettlementReasonResponse> {
    return this.fetchObject<types.SettlementReasonResponse>(`/loans/settlement-reason/${reference}`, 'json', null)
  }

  getUploadedFileList(): Promise<types.UploadedFile[]> {
    return this.fetchObject<types.UploadedFile[]>(`/files`, 'json', null)
  }

  postAcceptCreditAgreementRequest(body: types.AcceptCreditAgreementRequest): Promise<types.MessageResponse> {
    return this.postObject<types.MessageResponse>(`/commands/accept-credit-agreement`, body, 'application/json', 'json')
  }

  postAcceptDirectDebitInstructionRequest(body: types.AcceptDirectDebitInstructionRequest): Promise<types.MessageResponse> {
    return this.postObject<types.MessageResponse>(`/commands/accept-direct-debit-instruction`, body, 'application/json', 'json')
  }

  postAcceptRepaymentPlanRequest(body: types.AcceptRepaymentPlanRequest): Promise<types.MessageResponse> {
    return this.postObject<types.MessageResponse>(`/commands/accept-repayment-plan`, body, 'application/json', 'json')
  }

  postChangePaymentDayRequest(body: types.ChangePaymentDayRequest): Promise<types.ChangePaymentDayResponse> {
    return this.postObject<types.ChangePaymentDayResponse>(`/commands/change-payment-day`, body, 'application/json', 'json')
  }

  postCreateQuoteAuthenticatedRequest(body: types.CreateQuoteAuthenticatedRequest): Promise<types.CreateQuoteAuthenticatedResponse> {
    return this.postObject<types.CreateQuoteAuthenticatedResponse>(`/commands/create-quote`, body, 'application/json', 'json')
  }

  postDirectDebitPaymentRequest(body: types.DirectDebitPaymentRequest): Promise<types.DirectDebitPaymentResponse> {
    return this.postObject<types.DirectDebitPaymentResponse>(`/commands/create-direct-debit`, body, 'application/json', 'json')
  }

  postGenerateLoanDocumentRequest(body: types.GenerateLoanDocumentRequest): Promise<types.GenerateLoanDocumentResponse> {
    return this.postObject<types.GenerateLoanDocumentResponse>(`/commands/generate-loan-document`, body, 'application/json', 'json')
  }

  postNotificationsCollectRequest(body: types.NotificationsCollectRequest): Promise<types.NotificationsCollectResponse> {
    return this.postObject<types.NotificationsCollectResponse>(`/commands/collect-notifications`, body, 'application/json', 'json')
  }

  postOpenBankingPhoneCallRequest(body: types.OpenBankingPhoneCallRequest): Promise<types.MessageResponse> {
    return this.postObject<types.MessageResponse>(`/commands/open-banking/request-phone-call`, body, 'application/json', 'json')
  }

  postPaymentIntentRequest(body: types.PaymentIntentRequest): Promise<types.PaymentIntentResponse> {
    return this.postObject<types.PaymentIntentResponse>(`/commands/create-payment-intent`, body, 'application/json', 'json')
  }

  postQuoteCancellationRequest(body: types.QuoteCancellationRequest): Promise<types.MessageResponse> {
    return this.postObject<types.MessageResponse>(`/commands/cancel-quote`, body, 'application/json', 'json')
  }

  postSettlementReasonRequest(body: types.SettlementReasonRequest): Promise<types.MessageResponse> {
    return this.postObject<types.MessageResponse>(`/commands/update-settlement-reason`, body, 'application/json', 'json')
  }

  postSubmitLoanApplicationRequest(body: types.SubmitLoanApplicationRequest): Promise<types.SubmitLoanApplicationResponse> {
    return this.postObject<types.SubmitLoanApplicationResponse>(`/commands/submit-loan-application`, body, 'application/json', 'json')
  }

  postUpdateAccountDetailsRequest(body: types.UpdateAccountDetailsRequest): Promise<types.MessageResponse> {
    return this.postObject<types.MessageResponse>(`/commands/update-account-details`, body, 'application/json', 'json')
  }

  uploadFile(body: FormData): Promise<types.UploadedFile> {
    return this.postObject<types.UploadedFile>(`/commands/upload-file`, body, 'multipart/form-data', 'json')
  }
}