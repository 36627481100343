import * as features from 'utils/Features'
import * as helper from 'utils/HelperFunction'
import * as logging from 'utils/Logging'

import { QuoteIntroduction, RegisterCustomerRequest } from '../../../../api/BorrowerPublicApiSchemas'

import { useState } from 'react'
import { borrowerPublicApiClient } from '../../../../api/BorrowerPublicApiClient'
import { trackQuoteDetails } from '../../../../api/HeapConfig'

interface UseLoanApiRequestData {
  quoteIntroductionDetails: QuoteIntroduction | null
  apiResponseErrorMessage: string
  apiResponseSuccessMessages: string
  apiResponseLoanIntro: string | null
  fetchQuoteIntroductionDetails: (
    uuid: string,
    setLoading: (loading: boolean) => void,
    urn?: string,
    quoteResultRank?: number,
  ) => void
  postRegisterCustomer: (
    uuid: string,
    password: string,
    receiveMarketingInfo: boolean,
    setIsSubmitting: (loading: boolean) => void,
  ) => void
  postRegisterCustomerWithEmail: (
    email: string,
    password: string,
    receiveMarketingInfo: boolean,
    setIsSubmitting: (loading: boolean) => void,
  ) => void
}

const UseLoanApiRequest = (): UseLoanApiRequestData => {
  const borrowerPublicApi = borrowerPublicApiClient()

  const [quoteIntroductionDetails, setQuoteIntroductionDetails] = useState<QuoteIntroduction | null>(null)
  const [apiResponseErrorMessage, setApiResponseErrorMessage] = useState<string>('')
  const [apiResponseSuccessMessages, setApiResponseSuccessMessages] = useState<string>('')
  const [apiResponseLoanIntro, setApiResponseLoanIntro] = useState<string>('')

  const fetchQuoteIntroductionDetails = (
    uuid: string,
    setLoading: (loading: boolean) => void,
    urn?: string,
    quoteResultRank?: number,
  ) => {
    borrowerPublicApi
      .getQuoteIntroduction(uuid, urn, quoteResultRank)
      .then(
        (quoteIntroduction) => {
          features.heapTrack('JourneyStarted')
          setQuoteIntroductionDetails(quoteIntroduction)
          trackQuoteDetails(quoteIntroduction)
          helper.setFeatureCookies(quoteIntroduction?.features)
        },
        (error) => {
          if (error && error.data && error.data.message) {
            setApiResponseLoanIntro(error.data.message)
            logging.logError('fetchQuoteIntroductionDetails', error)
          }
        },
      )
      .finally(() => {
        if (helper.checkIsLoadingFunction(setLoading)) {
          setLoading(false)
        }
      })
  }

  const postRegisterCustomer = (
    uuid: string,
    password: string,
    receiveMarketingInfo: boolean,
    setIsSubmitting: (loading: boolean) => void,
  ) => {
    const body: RegisterCustomerRequest = {
      uuid,
      password,
      receiveMarketingInfo,
    }
    borrowerPublicApi
      .postRegisterCustomerRequest(body)
      .then(
        () => {
          setApiResponseSuccessMessages(
            `Your account was created. Please log in below using the password you just created.`,
          )
        },
        (error) => {
          if (error && error.data && error.data.message) {
            setApiResponseErrorMessage(error.data.message)
            logging.logError('postRegisterCustomer', error)
          }
        },
      )
      .finally(() => {
        if (helper.checkIsLoadingFunction(setIsSubmitting)) {
          setIsSubmitting(false)
        }
      })
  }

  const postRegisterCustomerWithEmail = (
    email: string,
    password: string,
    receiveMarketingInfo: boolean,
    setIsSubmitting: (loading: boolean) => void,
  ) => {
    const body: RegisterCustomerRequest = {
      email,
      password,
      receiveMarketingInfo,
    }
    borrowerPublicApi
      .postRegisterCustomerRequest(body)
      .then(
        () => {
          setApiResponseSuccessMessages(
            `Your account was created. Please log in below using the password you just created.`,
          )
        },
        (error) => {
          if (error && error.data && error.data.message) {
            setApiResponseErrorMessage(error.data.message)
            logging.logError('postRegisterCustomer', error)
          }
        },
      )
      .catch((error) => {
        setApiResponseErrorMessage(error.data.message)
      })
      .finally(() => {
        if (helper.checkIsLoadingFunction(setIsSubmitting)) {
          setIsSubmitting(false)
        }
      })
  }

  return {
    quoteIntroductionDetails,
    apiResponseErrorMessage,
    apiResponseSuccessMessages,
    apiResponseLoanIntro,
    fetchQuoteIntroductionDetails,
    postRegisterCustomer,
    postRegisterCustomerWithEmail,
  }
}
export default UseLoanApiRequest
