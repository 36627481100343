import * as features from 'utils/Features'
import * as messages from 'utils/Messages'
import * as variable from 'utils/Variable'

import { Box, FormHelperText, Grid, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'

import GetEmploymentApiRequest from 'api/GetEmploymentApiRequest'
import SelectReferenceData from 'components/Select/Baseline/SelectReferenceData'
import { LoanApplicationFormValues } from './LoanApplicationForm'

interface EmploymentSectorOptionType {
  label: string
}

type StepProp = {
  step: number
}

export interface EmploymentFormValues {
  employer: string
  employmentSector: string
  employmentPosition: string
  employmentStatus: string
  futureChangeOfIncome: string
  futureChangeOfIncomeReason?: string
  futureChangeOfIncomeSpecifiedReason?: string
}

export const EmploymentFormValidationSchema = Yup.object({
  employer: Yup.string().when('employmentStatus', {
    is: (value: string) => features.checkItemInArray(variable.employmentStatusSectorRequired, value),
    then: (schema) => schema.required(messages.employerRequired),
  }),
  employmentSector: Yup.string().when('employmentStatus', {
    is: (value: string) => features.checkItemInArray(variable.employmentStatusSectorRequired, value),
    then: (schema) => schema.required(messages.employmentSectorRequired),
  }),
  employmentPosition: Yup.string().when('employmentStatus', {
    is: (value: string) => features.checkItemInArray(variable.employmentStatusSectorRequired, value),
    then: (schema) => schema.required(messages.positionRequired),
  }),
  employmentStatus: Yup.string().required(),
  futureChangeOfIncome: Yup.string().required(),
  futureChangeOfIncomeReason: Yup.string().when('futureChangeOfIncome', {
    is: (value: string) => value === 'yes',
    then: (schema) => schema.required(messages.futureChangeOfIncomeReasonRequired),
  }),
  futureChangeOfIncomeSpecifiedReason: Yup.string().when('futureChangeOfIncomeReason', {
    is: 'Other',
    then: (schema) => schema.required(messages.futureChangeOfIncomeSpecificReasonRequired),
  }),
})

const EmploymentContainer = ({ step }: StepProp): JSX.Element => {
  const {
    employmentStatusesOptions,
    employmentPositionsOptions,
    employmentSectorsOptions,
    incomeChangeReasonsOption,
    fetchEmploymentStatuses,
    fetchEmploymentPositions,
    fetchEmploymentSectors,
    fetchIncomeChangeReasons,
  } = GetEmploymentApiRequest()

  const { values, touched, setFieldValue, errors, handleChange, handleBlur } =
    useFormikContext<LoanApplicationFormValues>()

  const [flag, setFlag] = useState(true)

  useEffect(() => {
    if (flag) {
      fetchEmploymentStatuses()
      fetchEmploymentPositions()
      fetchEmploymentSectors()
      fetchIncomeChangeReasons()
      setFlag(false)
    }
  }, [flag, fetchEmploymentStatuses, fetchEmploymentPositions, fetchEmploymentSectors, fetchIncomeChangeReasons])

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: EmploymentSectorOptionType) => option.label,
  })

  return (
    <>
      <Grid item xs={12} className="Account-content line-separate" data-test="employment-container">
        <Box>
          <strong>{step !== 0 && `${step}.`} Employment</strong>
        </Box>
      </Grid>
      <Grid item xs={12} className="Account-content Employment-Container">
        <Box mb={2} data-test="employment-status-container">
          <SelectReferenceData
            name="employmentStatus"
            variant="filled"
            label="Employment status"
            value={values.employmentStatus}
            options={employmentStatusesOptions}
            onChange={(value) => {
              setFieldValue('employmentStatus', value)
            }}
            error={touched.employmentStatus && Boolean(errors.employmentStatus)}
            helperText={touched.employmentStatus && Boolean(errors.employmentStatus) ? errors.employmentStatus : ''}
          />
        </Box>

        {features.checkItemInArray(variable.employmentStatusSectorRequired, values.employmentStatus) &&
          employmentPositionsOptions && (
            <>
              <Box mb={2} data-test="employment-position-container">
                <SelectReferenceData
                  variant="filled"
                  label="Position"
                  name="employmentPosition"
                  value={values.employmentPosition}
                  options={employmentPositionsOptions}
                  onChange={(value) => {
                    setFieldValue('employmentPosition', value)
                  }}
                  error={touched.employmentPosition && Boolean(errors.employmentPosition)}
                  helperText={
                    touched.employmentPosition && Boolean(errors.employmentPosition) ? errors.employmentPosition : ''
                  }
                />
              </Box>

              <Box mb={2} data-test="employment-sector-container">
                <Autocomplete
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  freeSolo
                  id="employmentSector"
                  value={values.employmentSector}
                  options={employmentSectorsOptions}
                  filterOptions={filterOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sector name (type for suggestions)"
                      error={touched.employmentSector && Boolean(errors.employmentSector)}
                      helperText={
                        touched.employmentSector && Boolean(errors.employmentSector) ? errors.employmentSector : ''
                      }
                    />
                  )}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(_event, value: any) => {
                    if (value) {
                      setFieldValue('employmentSector', value.label)
                    }
                  }}
                  onBlur={handleBlur}
                />
              </Box>

              <Box mb={2} data-test="employer-container">
                <TextField
                  fullWidth
                  label="Employer"
                  name="employer"
                  value={values.employer}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.employer && Boolean(errors.employer)}
                  helperText={touched.employer && Boolean(errors.employer) ? errors.employer : ''}
                />
              </Box>
            </>
          )}

        <Box mb={2} mt={3} data-test="change-in-circumstances-message">
          Are you aware of any changes in your circumstances or income which could impact your ability to repay your
          loan?
        </Box>

        <Box data-test="change-in-circumstances-container">
          <ToggleButtonGroup
            color="primary"
            value={values.futureChangeOfIncome}
            key="center"
            id="futureChangeOfIncome"
            exclusive
            onChange={(e, value) => {
              setFieldValue('futureChangeOfIncome', value ?? '', true)
              setFieldValue('futureChangeOfIncomeReason', '', true)
            }}
            className={
              touched.futureChangeOfIncome && errors.futureChangeOfIncome && values.futureChangeOfIncome === ''
                ? 'Mui-error'
                : ''
            }
          >
            <ToggleButton value="yes" data-test="change-in-circumstances-yes">
              Yes
            </ToggleButton>
            <ToggleButton value="no" data-test="change-in-circumstances-no">
              No
            </ToggleButton>
          </ToggleButtonGroup>
          {touched.futureChangeOfIncome &&
            Boolean(errors.futureChangeOfIncome) &&
            values.futureChangeOfIncome === '' && (
              <FormHelperText className="MuiFormHelperText-root Mui-error" style={{ paddingLeft: '14px' }}>
                {messages.chooseYesOrNo}
              </FormHelperText>
            )}
        </Box>

        {values.futureChangeOfIncome === 'yes' && (
          <Box mb={2} mt={2} data-test="future-change-of-income-reason">
            <SelectReferenceData
              variant="filled"
              label="Reason"
              value={values.futureChangeOfIncomeReason ?? ''}
              name="futureChangeOfIncomeReason"
              options={incomeChangeReasonsOption}
              onChange={(value) => {
                setFieldValue('futureChangeOfIncomeReason', value)
              }}
              error={touched.futureChangeOfIncomeReason && Boolean(errors.futureChangeOfIncomeReason)}
              helperText={
                touched.futureChangeOfIncomeReason && errors.futureChangeOfIncomeReason
                  ? messages.futureChangeOfIncomeReasonRequired
                  : ''
              }
            />
          </Box>
        )}

        {values.futureChangeOfIncome === 'yes' && values.futureChangeOfIncomeReason === 'Other' && (
          <TextField
            label="Please provide more details"
            multiline
            fullWidth
            rows={3}
            value={values.futureChangeOfIncomeSpecifiedReason ?? ''}
            onChange={(value) => {
              setFieldValue('futureChangeOfIncomeSpecifiedReason', value.target.value)
            }}
            error={touched.futureChangeOfIncomeSpecifiedReason && Boolean(errors.futureChangeOfIncomeSpecifiedReason)}
            helperText={touched.futureChangeOfIncomeSpecifiedReason ? errors.futureChangeOfIncomeSpecifiedReason : ''}
            data-test="future-change-of-income-specific-reason"
          />
        )}
      </Grid>
    </>
  )
}

export default EmploymentContainer
