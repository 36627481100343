export function getDateFormat(date: Date, format: string): string {
  const d = new Date(date)
  let dateFormat = ''

  // 21th
  if (format === 'dd') {
    const day = d.getDate()
    let ordinal

    if (day === 1 || day === 21 || day === 31) {
      ordinal = 'st'
    } else if (day === 2 || day === 22) {
      ordinal = 'nd'
    } else if (day === 3 || day === 23) {
      ordinal = 'rd'
    } else {
      ordinal = 'th'
    }

    dateFormat = `${day}${ordinal}`
  }

  // 21 May
  if (format === 'dd mm') {
    const day = d.getDate()
    const month = d.toLocaleString('default', { month: 'short' })
    dateFormat = `${day} ${month}`
  }

  // 2021
  if (format === 'YYYY') {
    const year = d.getFullYear()
    dateFormat = `${year}`
  }

  // 20 Sep 2020 - return month first three letters
  if (format === 'dd mm Y') {
    const day = d.getDate()
    let month = d.toLocaleString('default', { month: 'short' })
    month = month.substring(0, 3)
    const year = d.getFullYear()
    dateFormat = `${day} ${month} ${year}`
  }

  // 06/01/2021
  if (format === 'dd/mm/YYYY') {
    const day = d.getDate().toString().padStart(2, '0') //add a zero in front of numbers<10
    const month = (d.getMonth() + 1).toString().padStart(2, '0') //getMonth is zero based;
    const year = d.getFullYear()
    dateFormat = `${day}/${month}/${year}`
  }

  // 06/01/2021 - hh:mm
  if (format === 'dd/mm/YYYY-hh:mm') {
    const day = d.getDate().toString().padStart(2, '0') //add a zero in front of numbers<10
    const month = (d.getMonth() + 1).toString().padStart(2, '0') //getMonth is zero based;
    const year = d.getFullYear()
    const hours = d.getHours().toString().padStart(2, '0')
    const mins = d.getMinutes().toString().padStart(2, '0') //add a zero in front of numbers<10
    dateFormat = `${day}/${month}/${year} - ${hours}:${mins}`
  }

  return dateFormat
}

// display(16/12/2020 - 17:11), API format in(Fri Jan 31 2014 01:02:03 GMT-0800)
export function getCurrentDateFormat(format?: string): string {
  const d = new Date()
  const day = d.getDate()
  const month = (d.getMonth() + 1).toString().padStart(2, '0') //getMonth is zero based;
  const year = d.getFullYear()

  const hour = d.getHours().toString().padStart(2, '0')
  const minute = d.getMinutes().toString().padStart(2, '0')

  if (format === 'file') {
    return `${year}${month}${day}${hour}${minute}`
  }
  return `${day}/${month}/${year} - ${hour}:${minute}`
}

// return text Now if the notification date and time is today
// else return the time only if the notificationa date is today
// else return the date

const isToday = (someDate: Date) => {
  const today = new Date()
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  )
}

const isTimeNow = (someDate: Date) => {
  const today = new Date()
  return someDate.getHours() === today.getHours() && someDate.getMinutes() === today.getMinutes()
}

export function getDateFormatFilter(date: Date): string {
  const d = new Date(date)
  const today = isToday(d)
  if (!today) {
    return getDateFormat(date, 'dd mm Y')
  } else {
    const now = isTimeNow(d)
    if (now) {
      // return text Now if the time is current
      return 'Now'
    } else {
      // return the time only if the notificationa date is today
      const hour = d.getHours().toString().padStart(2, '0')
      const minute = d.getMinutes().toString().padStart(2, '0')
      return `${hour}:${minute}`
    }
  }
}

/**
 * Take the difference between the dates and divide by milliseconds per day.
 * Round to nearest whole number to deal with DST.
 */
export const dateDiff = (startDate: Date) => {
  const date1: Date = new Date()
  const date2: Date = new Date(startDate)
  const timeInMilisec: number = date2.getTime() - date1.getTime()
  const daysBetweenDates: number = Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24))
  return daysBetweenDates
}
