import * as logging from 'utils/Logging'

import { RecoverCustomerRequest, RecoverCustomerResponse } from '../../../../api/BorrowerPublicApiSchemas'

import { borrowerPublicApiClient } from '../../../../api/BorrowerPublicApiClient'
import { useState } from 'react'

interface RecoverCustomerProps {
  recoveryStatus: RecoverCustomerResponse | undefined
  recoverCustomer: (email: string, setIsSubmitted: (loading: boolean) => void) => void
}

const UseApiRequest = (): RecoverCustomerProps => {
  const borrowerPublicApi = borrowerPublicApiClient()
  const [recoveryStatus, setRecoveryStatus] = useState<RecoverCustomerResponse>()

  const recoverCustomer = (email: string, setIsSubmitted: (loading: boolean) => void) => {
    const body: RecoverCustomerRequest = { email }
    borrowerPublicApi
      .postRecoverCustomerRequest(body)
      .then(
        (res) => {
          setRecoveryStatus(res)
        },
        (error) => {
          logging.logError('recoverCustomer', error)
        },
      )
      .finally(() => {
        setIsSubmitted(true)
      })
  }

  return {
    recoveryStatus,
    recoverCustomer,
  }
}

export default UseApiRequest
