import '../login/_login.scss'

import { Button } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as messages from 'utils/Messages'

import { useOktaAuth } from '@okta/okta-react'
import logo from 'assets/images/logo/fluro-logo.svg'
import Loader from 'components/Loader/Loader'
import ResetPassword from 'components/PasswordTextfield/ResetPassword'
import { List } from 'react-content-loader'
import useOktaRequest from './UseOktaRequest'

type ParamTypes = {
  token: string
}

type Props = {
  name: string
}

export const PasswordReset = ({ name }: Props): JSX.Element => {
  const navigate = useNavigate()

  const { oktaAuth } = useOktaAuth()

  const headerTitle = name === 'create-password' ? 'Create your password' : 'Reset your password'
  const buttonLabel = name === 'create-password' ? 'Create password' : 'Reset password'

  const {
    email,
    errorMessages,
    resetPassword,
    oktaErrorMessages,
    fetchValidatesRecoveryToken,
    postResetPassword,
    updateStateSetOktaErrorMessages,
  } = useOktaRequest()

  const [hasLoaded, setHasLoaded] = useState(true)

  const [isLoading, setLoading] = useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [newPasswordVal, setNewPasswordVal] = useState('')
  const [confirmPasswordVal, setConfirmPasswordVal] = useState('')

  const [newPasswordErrorMessages, setNewPasswordErrorMessages] = useState('')

  const [confirmPasswordErrorMessages, setConfirmPasswordErrorMessages] = useState('')

  const callback = useCallback(
    (newPasswordVal: string, confirmPasswordVal: string) => {
      setNewPasswordVal(newPasswordVal)
      setConfirmPasswordVal(confirmPasswordVal)

      // reset the error messages
      updateStateSetOktaErrorMessages()
      setNewPasswordErrorMessages('')
      setConfirmPasswordErrorMessages('')
    },
    [updateStateSetOktaErrorMessages],
  )

  const { token } = useParams<ParamTypes>()

  useEffect(() => {
    if (hasLoaded) {
      const delayDebounceFn = setTimeout(() => {
        if (token) {
          fetchValidatesRecoveryToken(token, oktaAuth, setLoading)
        }
        setHasLoaded(false)
      }, 3000)

      return () => clearTimeout(delayDebounceFn)
    }
  }, [fetchValidatesRecoveryToken, hasLoaded, newPasswordVal, oktaAuth, token])

  // section form submit
  const { handleSubmit } = useForm<FormData>()

  const onSubmit: SubmitHandler<FormData> = () => {
    setIsSubmitting(true)
    if (newPasswordVal.length === 0) {
      setNewPasswordErrorMessages(messages.newPasswordEmpty)
      setIsSubmitting(false)
    } else if (confirmPasswordVal.length === 0) {
      setConfirmPasswordErrorMessages(messages.confirmPasswordEmpty)
      setIsSubmitting(false)
    } else if (newPasswordVal.length > 0 && confirmPasswordVal.length > 0 && newPasswordVal === confirmPasswordVal) {
      if (token) {
        postResetPassword(token, newPasswordVal, oktaAuth, setIsSubmitting)
      }
    } else {
      setNewPasswordErrorMessages('')
      setConfirmPasswordErrorMessages('')
      setIsSubmitting(false)
    }
  }

  const onSubmitDashboard: SubmitHandler<FormData> = () => {
    navigate('/sign-in')
  }

  const SuccessContentWrapper = () => {
    return (
      <>
        <form
          onSubmit={handleSubmit(onSubmitDashboard)}
          className="success-content primary-auth-form o-form o-form-edit-mode"
        >
          <div className="icon-box">
            <span className="fluro-icon-round-check icons-colorPrimary icons-fs-50 icon-bold" />
          </div>
          <h2 className="okta-form-title o-form-head">You&apos;ve successfully changed your password.</h2>
          <input className="button button-primary" value="Continue to log in" type="submit" />
        </form>
      </>
    )
  }

  const ErrorContentWrapper = () => {
    return (
      <form className="primary-auth-form o-form o-form-edit-mode">
        <div className="o-form-error-container">{errorMessages}</div>
      </form>
    )
  }

  const NewPasswordErrorMessages = () => {
    return (
      <div className="okta-form-infobox-error infobox infobox-error" style={{ marginBottom: '15' }}>
        <span className="icon error-16"></span>
        <p> {newPasswordErrorMessages}</p>
      </div>
    )
  }

  const OktaErrorMessages = () => {
    return (
      <div className="okta-form-infobox-error infobox infobox-error" style={{ marginBottom: '15' }}>
        <span className="icon error-16" />
        <p> {oktaErrorMessages}</p>
      </div>
    )
  }

  return (
    <div id="signInWidget" className="resetPasswordContainer">
      <div id="okta-sign-in" className="auth-container main-container no-beacon">
        <div className="okta-sign-in-header auth-header">
          <h1>
            <a href="https://www.fluro.co.uk" title="Fluro homepage">
              <img
                src={logo}
                className="fluro-logo"
                alt="Fluro Logo"
                data-test="fluro-logo"
                width="100%"
                height="100%"
              />
            </a>
          </h1>
          <div data-type="beacon-container" className="beacon-container" />
        </div>
        <div className="auth-content">
          <div className="auth-content-inner">
            <div className="primary-auth">
              {isLoading ? (
                <form className="primary-auth-form o-form o-form-edit-mode">
                  <List />
                </form>
              ) : (
                <>
                  {resetPassword.successMessages && isSubmitting === false ? (
                    <SuccessContentWrapper />
                  ) : !resetPassword.successMessages && errorMessages ? (
                    <ErrorContentWrapper />
                  ) : (
                    <form onSubmit={handleSubmit(onSubmit)} className="primary-auth-form o-form o-form-edit-mode">
                      <div className="o-form-content o-form-theme clearfix">
                        <h2 className="okta-form-title o-form-head">{headerTitle}</h2>

                        {newPasswordErrorMessages && <NewPasswordErrorMessages />}

                        {oktaErrorMessages && <OktaErrorMessages />}

                        <ResetPassword
                          parentCallback={callback}
                          errorMessages={{
                            newPassword: newPasswordErrorMessages,
                            confirmPassword: confirmPasswordErrorMessages,
                          }}
                          email={email}
                        />
                      </div>
                      <div className="o-form-button-bar">
                        {isSubmitting ? (
                          <Button className="button button-primary" variant="contained" color="primary" disabled={true}>
                            <Loader size="small" color="white" />
                          </Button>
                        ) : (
                          <input className="button button-primary" value={buttonLabel} type="submit" />
                        )}
                      </div>
                    </form>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
