import getLoansApiRequest from 'api/getLoansApiRequest'
import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import RequiredAuthMenu from 'RequiredAuthMenu'
import './assets/styles/global.scss'
import './_app.scss'

import { Container, CssBaseline } from '@mui/material'

export const RequiredAuthLayout: React.FC = () => {
  const { sideMenu, loans, loanError, isLoading, fetchLoanData } = getLoansApiRequest()
  const [hasLoaded, setHasLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (!hasLoaded) {
      fetchLoanData()
      setHasLoaded(true)
    }
  }, [hasLoaded, fetchLoanData])

  const loanObject = {
    loans: loans,
    loanError: loanError,
    isLoading: isLoading,
  }

  return (
    <div className="MainApp">
      <CssBaseline />

      {/* Display router page content  */}
      <main className="MainApp-layoutContainer">
        <Container className="MainApp-layoutContainer-content">
          <RequiredAuthMenu sideMenu={sideMenu} />
          <Outlet context={loanObject} />
        </Container>
      </main>
    </div>
  )
}

export default RequiredAuthLayout
