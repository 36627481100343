import { Box, CssBaseline, Grid } from '@mui/material'
import LogoLoading from 'assets/images/logo/logo-fluro-loading.mp4'
import LayoutWrapper from '../../../public/layout/LayoutWrapper'
import { useEffect } from 'react'
import './_checking.scss'

interface CheckingProps {
  copy: string
}

const Checking = (props: CheckingProps): JSX.Element => {
  const handleBeforeUnload = (event: { returnValue: string }) => {
    const confirmationMessage = 'You have unsaved changes!'

    event.returnValue = confirmationMessage
    return confirmationMessage
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [])

  return (
    <LayoutWrapper className="PublicLoanContainer-LoanProcessing-container">
      <CssBaseline />

      <Grid container className="FinalDetails-container">
        <Grid mb={3} className="Card-shadow Headers justifyContent-center">
          <Box className="PerformingChecks">
            <video data-test="performing-checks-video" autoPlay loop>
              <source src={LogoLoading} type="video/mp4" />
              Your browser does not support HTML video.
            </video>

            <h2 data-test="performing-checks-heading">{props.copy}</h2>

            <p>This may take a few seconds. Please do not press refresh or close down your web browser.</p>
          </Box>
        </Grid>
      </Grid>
    </LayoutWrapper>
  )
}

export default Checking
