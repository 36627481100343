import { Box } from '@mui/material'
import Questions from '../../../components/Questions/Questions'
import TrustPilotBox from '../../../components/TrustPilotBox/TrustPilotBox'

type Props = {
  useCSEmail?: boolean
}

const RightContentWrapper = ({ useCSEmail }: Props): JSX.Element => {
  return (
    <div className="Layout-MainContainer-RightContent" data-test="right-block-container">
      <Box mb={3} className="Card-shadow">
        <h2 data-test="block-title">Questions?</h2>
        <Questions useCSEmail={useCSEmail} />
      </Box>
      <Box className="Card-shadow">
        <TrustPilotBox />
      </Box>
    </div>
  )
}

export default RightContentWrapper
