import * as paths from 'utils/Links'
import * as logging from 'utils/Logging'

import { borrowerApiClient } from 'api/BorrowerApiClient'
import { useState } from 'react'
import { OpenBankingConnectionStatusSucceeded } from 'utils/Variable'
import { OpenBankingConnectionStatus } from '../../../../api/BorrowerApiSchemas'

interface Props {
  openBankingLink: string
  fetchOpenBankingLink: (reference: string) => void

  openBankingLinkError: boolean

  setOpenBankingLinkError: (openBankingLinkError: boolean) => void
  connectionStatus: OpenBankingConnectionStatus | null
  checkOpenBankingConnectionStatus: (taskId: string, reference: string, shouldShowDocUpload: boolean) => void
  connectionCheckError: boolean
  applyStatus: string
}

const UseApiRequest = (): Props => {
  const borrowerApi = borrowerApiClient()
  const [openBankingLink, setOpenBankingLink] = useState<string>('')
  const [openBankingLinkError, setOpenBankingLinkError] = useState(false)
  const [connectionStatus, setConnectionStatus] = useState<OpenBankingConnectionStatus | null>(null)
  const [applyStatus, setApplyStatus] = useState<string>('')
  const [connectionCheckError, setConnectionCheckError] = useState(false)

  const fetchOpenBankingLink = (reference: string) => {
    const redirectUrl = window.location.origin + paths.getOpenBankingCallback()
    borrowerApi.getOpenBankingAuthenticationUrlResponse(reference, redirectUrl).then(
      (res) => {
        setOpenBankingLink(res.url)
      },
      (error) => {
        logging.logError('failed to get openbanking link - bud', error)
        setOpenBankingLinkError(true)
      },
    )
  }

  const checkOpenBankingConnectionStatus = (taskId: string, reference: string, shouldShowDocUpload: boolean) => {
    const refreshCheckKey = `openBankingConnected${reference}`
    borrowerApi
      .getOpenBankingConnectionStatusResponse(reference, taskId)
      .then(
        (res) => {
          // call the lade decision code
          if (res.status === OpenBankingConnectionStatusSucceeded && localStorage.getItem(refreshCheckKey) === null) {
            localStorage.setItem(refreshCheckKey, 'true')
            borrowerApi
              .getOpenBankingApplyResponse(reference)
              .then(
                (res) => {
                  setApplyStatus(res.status)
                },
                (error) => {
                  logging.logError('failed to get openbanking apply', error)
                  setConnectionCheckError(true)
                },
              )
              .finally(() => {
                setConnectionStatus(res.status)
              })
          }
        },
        (error) => {
          logging.logError('failed to check open banking connection status', error)
          setConnectionCheckError(true)
        },
      )
      .finally(() => {
        localStorage.setItem(refreshCheckKey, 'true')
      })
  }

  return {
    openBankingLink,
    fetchOpenBankingLink,
    openBankingLinkError,
    setOpenBankingLinkError,
    connectionStatus,
    checkOpenBankingConnectionStatus,
    connectionCheckError,
    applyStatus,
  }
}

export default UseApiRequest
