export enum AppEnv {
  Prod = 'prod',
}

declare global {
  interface Window {
    appEnv: AppEnv
  }
}

export const appEnv = window.appEnv
