import '../Select.scss'

import { FormControl, FormHelperText, InputLabel } from '@mui/material'
import { LabelOptionLists, SelectLabelProps } from '../Select.types'
import Select, { SelectChangeEvent } from '@mui/material/Select'

const SelectReferenceData = (props: SelectLabelProps): JSX.Element => {
  const handleChange = (e: SelectChangeEvent<string>) => {
    const newValue = e.target.value as string

    // Here, we invoke the callback with the new value
    props.onChange(newValue)
  }

  const options = props.options ? props.options : LabelOptionLists

  return (
    <>
      <FormControl
        variant={props.variant}
        className="SelectComponents-formControl"
        data-test="form-control-component"
        error={props.error}
      >
        <InputLabel id="loan-cancellation-label" error={props.error}>
          {props.label}
        </InputLabel>
        <Select
          data-test={props.dataTest}
          native
          value={props.value}
          onChange={handleChange}
          IconComponent={() => <span className="lw-system-icons-arrow-down"></span>}
          error={props.error}
          label={props.label}
        >
          <option value="" disabled />
          {options.map((v) => (
            <option key={v.label} value={v.label}>
              {v.label}
            </option>
          ))}
        </Select>
        {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
        {props.error && <FormHelperText>{props.errorText}</FormHelperText>}
      </FormControl>
    </>
  )
}

export default SelectReferenceData
