import './_incomeDocuments.scss'

import { getLoanApplicationRequiresOpenbankingWithSearch } from 'utils/Links'
import * as logging from 'utils/Logging'
import * as messagesArea from 'utils/Messages'
import * as variable from 'utils/Variable'

import { Box, Button, CssBaseline, Divider, Grid, Tooltip, Typography } from '@mui/material'
import { Form, Formik, FormikHelpers, FormikValues } from 'formik'
import { array, object, string } from 'yup'

import { borrowerApiClient } from 'api/BorrowerApiClient'
import Loader from 'components/Loader/Loader'
import SystemErrorMessage from 'components/SystemErrorMessage/SystemErrorMessage'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import FeedbackIndicatorsComponent from '../../../../components/FeedbackIndicators/FeedbackIndicators'
import LayoutWrapper from '../../../public/layout/LayoutWrapper'
import { MultipleFileUploadField } from '../../uploadDocuments/upload/MultipleFileUploadField'
import OpenBankingOrDocUploadSuccess from './OpenBankingOrDocUploadSuccess'

const initialValues = {
  files: [],
}

interface DocumentUploadProps {
  type: string
  openBankingError: boolean
}

const DocumentUpload = (props: DocumentUploadProps): JSX.Element => {
  const isOpenBanking = props.type === 'OpenBanking'
  const borrowerApi = borrowerApiClient()
  const [resets, setResets] = useState(1)
  const [error, setError] = useState<string>('')
  const [fileUploadedSuccessfully, setFileUploadedSuccessfully] = useState<boolean>(false)
  const { search } = useLocation()

  const handleSubmit = (values: FormikValues, helpers: FormikHelpers<typeof initialValues>) => {
    const data = values.files

    for (let i = 0; i < data.length; i++) {
      const file = data[i].file
      const formData = new FormData()
      formData.append('file', file)

      borrowerApi.uploadFile(formData).then(
        () => {
          setFileUploadedSuccessfully(true)
        },
        (error) => {
          logging.logError('incomeDocuments handleSubmit', error.data.message)
          setError(error.data.message)
        },
      )
    }

    return new Promise((res) =>
      setTimeout(() => {
        helpers.setValues({ files: [] })
        setResets((s) => s + 1)
        res(null)
      }, 500),
    )
  }

  const addFilesTooltip = 'Please add files before trying to send them.'
  const waitingTooltip = 'Please wait for all the files to be added before sending them.'

  if (fileUploadedSuccessfully) {
    return <OpenBankingOrDocUploadSuccess mandatoryOpenBanking={false} />
  }

  return (
    <LayoutWrapper className="income-documents-wrapper">
      <CssBaseline />
      <Grid container className="income-documents-container">
        {error !== '' && (
          <Box mt={2} mb={2} className="FeedbackIndicator-container">
            <SystemErrorMessage title={messagesArea.errorMessagesTitle} message={messagesArea.errorMessagesSystem} />
          </Box>
        )}
        <Grid item xs={12}>
          <div>
            <Box mb={3} mt={12} className="Card-shadow Headers lines">
              <Typography variant="h3" className="nearly-there top-shadow">
                {isOpenBanking ? (
                  <div> Nearly there! We need to verify your income.</div>
                ) : (
                  <div>Nearly there! We need more information.</div>
                )}
              </Typography>
              <Typography className="complete-application">
                {isOpenBanking ? (
                  <div>To complete your application, please verify your income below</div>
                ) : (
                  <div> To complete your application, please add your documents below </div>
                )}
              </Typography>
            </Box>
          </div>
          <div>
            <Box className="Card-shadow lines document-upload-container">
              <Box className="upload-manually-text">
                {isOpenBanking ? (
                  <Typography variant="h5">Upload manually</Typography>
                ) : (
                  <Typography variant="h5">Upload documents</Typography>
                )}
              </Box>
              <Divider />
              {props.openBankingError && (
                <Box data-test="openbanking-error" className="openbanking-error">
                  <FeedbackIndicatorsComponent
                    severity="info"
                    className="MuiAlert-standardNone"
                    icon="lw-system-icons-info icon-bg-info"
                    messages="Unfortunately, we could not access your details with Open Banking. Please upload your bank statements manually instead."
                    isOpen={true}
                    closeAction={false}
                  />
                </Box>
              )}

              <Box className="upload-documents-form">
                <Box className="upload-documents-blurb">
                  {isOpenBanking && !props.openBankingError && (
                    <Box>
                      <p>
                        Please upload bank statements for the last 3 months. If you don't have this to hand, why not try{' '}
                        <a
                          href={getLoanApplicationRequiresOpenbankingWithSearch(search)}
                          className="web-link text-colorPrimary"
                        >
                          Open Banking
                        </a>
                        ?
                      </p>
                      <p>
                        Once you have provided this additional information, we will respond to you within 1 business
                        day.
                      </p>
                    </Box>
                  )}
                  {isOpenBanking && props.openBankingError && (
                    <Box>
                      <p>
                        Please upload bank statements for the last 3 months. Once you have provided this additional
                        information, we will respond to you within {variable.underwriterContactTimeframe}.
                      </p>
                    </Box>
                  )}
                  {!isOpenBanking && (
                    <Box>
                      <p>
                        We've sent you an email containing the documents we need. After you gather those, please come
                        back here to add and send them.
                      </p>
                      <p>
                        Once you have provided this additional information, we will respond to you within{' '}
                        {variable.underwriterContactTimeframe}.
                      </p>
                    </Box>
                  )}
                </Box>
                <Formik
                  initialValues={{ files: [] }}
                  validationSchema={object({
                    files: array(
                      object({
                        url: string().required(),
                      }),
                    ),
                  })}
                  onSubmit={handleSubmit}
                >
                  {({ values, isValid, isSubmitting }) => (
                    <Form className="DropDocumentContainer" data-test="upload-documents-form">
                      <Grid container direction="column">
                        <MultipleFileUploadField key={resets} name="files" />

                        <Grid className="document-upload-grid" item>
                          <Box mt={3} mb={4} className="send-statements-button-container">
                            {values.files.length === 0 || !isValid ? (
                              <Tooltip
                                className="uploadDocument-tooltip"
                                title={values.files.length === 0 ? addFilesTooltip : waitingTooltip}
                                placement="top"
                                arrow
                              >
                                <span className="Tooltip-span">
                                  <Button
                                    data-test="upload-documents-disabled-submit-button"
                                    role="button"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    className="Mui-disabled"
                                    disabled={values.files.length > 0}
                                    style={values.files.length > 0 ? {} : { pointerEvents: 'none' }}
                                  >
                                    Send documents
                                  </Button>
                                </span>
                              </Tooltip>
                            ) : (
                              <Button
                                data-test="upload-documents-submit-button"
                                role="button"
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={values.files.length <= 0}
                              >
                                {isSubmitting ? <Loader size="small" color="white" /> : 'Send documents'}
                              </Button>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </div>
          {isOpenBanking && !props.openBankingError && (
            <div className="back-to-openbanking">
              <Typography>
                Back to{' '}
                <a
                  data-test="back-to-open-banking-link"
                  className="web-link text-colorPrimary"
                  href={getLoanApplicationRequiresOpenbankingWithSearch(search)}
                >
                  Open Banking
                </a>
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>
    </LayoutWrapper>
  )
}

export default DocumentUpload
