import * as features from 'utils/Features'
import * as seoTitle from 'utils/SeoTitle'

import '../../portal/loanApplication/referred/_openBanking.scss'

import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import OpenBankingCopy from 'view/portal/components/openBanking/OpenBankingCopy'
import LinkInvalid from 'view/public/common/LinkInvalid/Home'
import LayoutWrapper from 'view/public/layout/LayoutWrapper'

import OpenBankingApi from './OpenBankingApi'

const UnauthenticatedOpenBanking = ({ previouslyErrored = false }): JSX.Element => {
  const { openBankingLink, fetchOpenBankingLink, linkGenerationError } = OpenBankingApi()
  const [flag, setFlag] = useState(true)

  const { search } = useLocation()
  const queryParams = queryString.parse(search)
  const reference = (queryParams?.reference as string) ?? ''
  const userId = (queryParams?.userId as string) ?? ''
  const parsedUserId = Number.parseInt(userId)
  const [showOptForCallComponent, setShowOptForCallComponent] = useState<boolean>(false)

  useEffect(() => {
    features.getBrowserHiddenProps(seoTitle.loanApplicationSeoTitle, seoTitle.loanApplicationChangeSeoTitle)

    if (flag) {
      if (openBankingLink === '' && userId !== '') {
        fetchOpenBankingLink(reference, parsedUserId)
      }
      setFlag(false)
    }
  }, [flag, fetchOpenBankingLink, openBankingLink, reference, userId, parsedUserId])

  if (linkGenerationError || reference === '' || userId === '' || Number.isNaN(parsedUserId)) {
    return (
      <LayoutWrapper className="PublicLoanContainer-LinkInvalid-container">
        <LinkInvalid />
      </LayoutWrapper>
    )
  }

  return OpenBankingCopy(
    openBankingLink,
    false,
    previouslyErrored,
    false,
    showOptForCallComponent,
    setShowOptForCallComponent,
    reference,
  )
}

export default UnauthenticatedOpenBanking
