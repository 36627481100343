import './_headerNavigation.scss'

import { AppBar, Box, Toolbar } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import FluroLogo from 'components/Logo/Logo'
import AccountMenu from '../menu/appMenu/AccountMenu'

type Props = {
  loggedTopNav: boolean
}

const Header = ({ loggedTopNav }: Props): JSX.Element => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <AppBar className="HeaderNavigation-header">
      <Toolbar className="HeaderNavigation-headerToolbar">
        {isMobile && loggedTopNav ? (
          <>
            <Box>
              <AccountMenu />
            </Box>
            <Box className="HeaderNavigation-headerMobile">
              <FluroLogo />
            </Box>
          </>
        ) : (
          <>
            <Box flexGrow={1}>
              <FluroLogo />
            </Box>
            <Box>
              <AccountMenu />
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default Header
