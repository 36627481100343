import * as features from 'utils/Features'
import * as messages from 'utils/Messages'
import * as seoTitle from 'utils/SeoTitle'

import 'view/portal/loanApplication/finalDetails/_finalDetails.scss'

import { Box, Button, CssBaseline, Grid, TextField, Typography } from '@mui/material'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { LoanApplicationDetails } from 'api/BorrowerApiSchemas'
import FeedbackIndicatorsComponent from 'components/FeedbackIndicators/FeedbackIndicators'
import Loader from 'components/Loader/Loader'
import PartnersLogo from 'components/LogoPartners/LogoPartners'
import Stepper from 'components/Stepper/Stepper'
import SubmitApplicationConsiderationMessage from 'components/SubmitApplicationConsiderationMessage/SubmitApplicationConsiderationMessage'

import { Form, Formik, FormikErrors } from 'formik'
import HeaderNavigationWithLogin from 'view/portal/layout/HeaderNavigationWithLogin'
import AboutYou, {
  AboutYouLoggedInFormValidationSchema,
  AboutYouLoggedInFormValues,
} from 'view/portal/loanApplication/finalDetails/AboutYou'
import BankDetails, {
  BankDetailsFormValidationSchema,
  BankDetailsFormValues,
} from 'view/portal/loanApplication/finalDetails/BankDetails'
import EmploymentContainer, {
  EmploymentFormValidationSchema,
  EmploymentFormValues,
} from 'view/portal/loanApplication/finalDetails/EmploymentContainer'

import { stringIsNotDefinedOrEmpty } from 'utils/HelperFunction'
import UseLoanApiRequest from 'view/public/common/api/UseLoanApiRequest'
import useOktaRequest from 'view/public/common/api/UseOktaRequest'
import SignInAndCreateAccountHeader from 'view/public/common/shared/SignInAndCreateAccountHeader'
import LayoutWrapper from 'view/public/layout/LayoutWrapper'

import Password from './Password'
import LoanApplicationRedirect from './RedirectRender'

export type Props = {
  loanApplicationDetails: LoanApplicationDetails
}

interface CustomizedState {
  user: {
    accountNumber: string
    employer: string
    employmentPosition: string
    employmentSector: string
    employmentStatus: string
    mobile: string
    sortCode: string
  }
  email: string
  accountStatus: string
  source: {
    name: string
    api: string
  }
  uuid: string
  acceptedTerms: boolean
  reference: string
}

export type LoanApplicationFormValues = AboutYouLoggedInFormValues & EmploymentFormValues & BankDetailsFormValues

const fullValidationSchema = AboutYouLoggedInFormValidationSchema.concat(EmploymentFormValidationSchema).concat(
  BankDetailsFormValidationSchema,
)

const LoanApplicationFinalDetailsCreatePassword = (): JSX.Element => {
  const location = useLocation()
  const state = location.state as CustomizedState // Type Casting, then you can get the params passed via router
  const { user, email, accountStatus, source, uuid, acceptedTerms, reference } = state ?? {
    user: {
      accountNumber: '',
      employer: '',
      employmentPosition: '',
      employmentSector: '',
      employmentStatus: '',
      mobile: '',
      sortCode: '',
    },
    email: '',
    accountStatus: '',
    source: {
      name: '',
      api: '',
    },
    uuid: '',
    acceptedTerms: false,
    reference: '',
  }

  const userAccountLockedOut = accountStatus === 'LockedOut'
  const userPasswordExpired = accountStatus === 'ExpiredPassword'
  const userPasswordRecovery = accountStatus === 'Recovery'
  const accountStatusExpiredOrRecovery = userPasswordExpired || userPasswordRecovery

  const redirectUrl = 'auto-login'

  const isValidUserMobile = features.checkMobileNumber(user?.mobile)

  const [usersDetail, setUsersDetail] = useState({
    accountNumber: '',
    employer: '',
    employmentPosition: '',
    employmentSector: '',
    employmentStatus: '',
    futureChangeOfIncome: false,
    futureChangeOfIncomeReason: '',
    futureChangeOfIncomeSpecificReason: '',
    mobileNumber: '',
    reference: '',
    sortCode: '',
  })
  const [newPassword, setNewPassword] = useState<string>('')
  const [passwordError, setPasswordError] = useState<boolean>(false)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [sectionNumber, setSectionNumber] = useState({
    AboutYou: 0,
    Employment: 1,
    BankDetails: 2,
    CreateAccount: 3,
  })

  const { apiResponseSuccessMessages, apiResponseErrorMessage, postRegisterCustomer } = UseLoanApiRequest()

  const { oktaErrorMessages, postSignInWithCredentials } = useOktaRequest()

  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [flag, setFlag] = useState<boolean>(true)

  const sourceName = source?.name || ''
  const sourceApi = source?.api || ''
  const feedbackValidationMessage = ''

  const initialValues: LoanApplicationFormValues = useMemo(() => {
    return {
      accountNumber: '',
      employer: '',
      employmentPosition: '',
      employmentSector: '',
      employmentStatus: '',
      futureChangeOfIncome: '',
      futureChangeOfIncomeReason: '',
      futureChangeOfIncomeSpecifiedReason: '',
      mobile: '',
      sortCode: '',
    }
  }, [])

  useEffect(() => {
    features.getBrowserHiddenProps(seoTitle.loanApplicationSeoTitle, seoTitle.loanApplicationChangeSeoTitle)

    if (user?.mobile) {
      initialValues.mobile = isValidUserMobile ? user?.mobile : ''
    }
    if (user?.employer) {
      initialValues.employer = user.employer
    }
    if (user?.employmentPosition) {
      initialValues.employmentPosition = user.employmentPosition
    }
    if (user?.employmentSector) {
      initialValues.employmentSector = user.employmentSector
    }
    if (user?.employmentStatus) {
      initialValues.employmentStatus = user.employmentStatus
    }
    if (user?.accountNumber) {
      initialValues.accountNumber = user.accountNumber
    }
    if (user?.sortCode) {
      initialValues.sortCode = features.transformSortCode(user.sortCode).slice(0, -1)
    }

    if (!isValidUserMobile) {
      setSectionNumber({
        AboutYou: 1,
        Employment: 2,
        BankDetails: 3,
        CreateAccount: 4,
      })
    } else if (stringIsNotDefinedOrEmpty(user?.accountNumber) || stringIsNotDefinedOrEmpty(user?.sortCode)) {
      setSectionNumber({
        AboutYou: 0,
        Employment: 1,
        BankDetails: 2,
        CreateAccount: 3,
      })
    } else {
      setSectionNumber({
        AboutYou: 0,
        Employment: 1,
        BankDetails: 0,
        CreateAccount: 2,
      })
    }

    // first time dont show the error border
    setPasswordError(false)
  }, [initialValues, user, isValidUserMobile])

  // get the success response after success register customer
  // let auto signin to get the okta token and storage the local storage
  useEffect(() => {
    if (apiResponseSuccessMessages.length > 0 && usersDetail !== null) {
      setIsSubmitting(true)

      if (flag) {
        setFlag(false)
        postSignInWithCredentials(email, newPassword, setIsSubmitting, redirectUrl)
        localStorage.setItem('isRedirectCreatePassword', 'true')
      }
    }
  }, [flag, apiResponseSuccessMessages, email, newPassword, postSignInWithCredentials, redirectUrl, usersDetail])

  const handleFormSubmit = (values: LoanApplicationFormValues) => {
    if (passwordError) {
      refCreatePasswordContainer.current?.scrollIntoView({ behavior: 'smooth' })
      return
    }

    // create an account with password with displaying loading indicator
    postRegisterCustomer(uuid, newPassword, acceptedTerms, setIsSubmitting)

    if (oktaErrorMessages || apiResponseErrorMessage.length > 0) {
      setIsSubmitting(false)
      setIsOpen(true)
    } else {
      setIsSubmitting(true)

      setUsersDetail({
        reference: reference,
        sortCode: user?.sortCode.trim().length !== 0 ? user?.sortCode : values.sortCode.replace(/-/g, ''),
        accountNumber: user?.accountNumber.trim().length !== 0 ? user?.accountNumber : values.accountNumber,
        mobileNumber: user?.mobile.trim().length !== 0 && isValidUserMobile ? user?.mobile : values.mobile,
        employer: values.employer,
        employmentPosition: values.employmentPosition,
        employmentSector: values.employmentSector,
        employmentStatus: values.employmentStatus,
        futureChangeOfIncome: values.futureChangeOfIncome === 'yes',
        futureChangeOfIncomeReason: values.futureChangeOfIncome ? (values.futureChangeOfIncomeReason as string) : '',
        futureChangeOfIncomeSpecificReason:
          values.futureChangeOfIncome && values.futureChangeOfIncomeReason === 'Other'
            ? (values.futureChangeOfIncomeSpecifiedReason as string)
            : '',
      })
    }
  }

  const refAboutYouContainer = useRef<null | HTMLDivElement>(null)
  const refEmploymentContainer = useRef<null | HTMLDivElement>(null)
  const refBankDetails = useRef<null | HTMLDivElement>(null)
  const refCreatePasswordContainer = useRef<null | HTMLDivElement>(null)

  const formValidation = (errors: FormikErrors<LoanApplicationFormValues>) => {
    if (errors.mobile) {
      refAboutYouContainer.current?.scrollIntoView({ behavior: 'smooth' })
    } else if (
      errors.employer ||
      errors.employmentSector ||
      errors.employmentPosition ||
      errors.employmentPosition ||
      errors.futureChangeOfIncome ||
      errors.futureChangeOfIncomeReason ||
      errors.futureChangeOfIncomeSpecifiedReason
    ) {
      refEmploymentContainer.current?.scrollIntoView({ behavior: 'smooth' })
    } else if (errors.sortCode || errors.accountNumber) {
      refBankDetails.current?.scrollIntoView({ behavior: 'smooth' })
    }

    if (newPassword.length < 8) {
      refCreatePasswordContainer.current?.scrollIntoView({ behavior: 'smooth' })
      setPasswordError(true)
    }
  }

  const closeFeedbackIndicatorsComponent = () => {
    setIsOpen(false)
  }

  const callback = useCallback((password: string) => {
    setNewPassword(password)
    setIsOpen(false)
  }, [])

  if (
    localStorage.getItem('isRedirectCreatePassword') === 'true' &&
    localStorage.getItem('okta-token-storage') !== '{}' &&
    localStorage.getItem('okta-token-storage') !== undefined &&
    usersDetail !== null
  ) {
    return <LoanApplicationRedirect users={usersDetail} />
  }

  const OktaErrorMessages = () => {
    return (
      <FeedbackIndicatorsComponent
        className="feedback-indicator"
        severity="error"
        icon="lw-system-icons-close icon-bg-error"
        messages={oktaErrorMessages}
        isOpen={isOpen}
        closeAction={true}
        parentCallback={closeFeedbackIndicatorsComponent}
      />
    )
  }

  return (
    <LayoutWrapper className="PublicLoanContainer-createPassword">
      <HeaderNavigationWithLogin />
      <CssBaseline />
      <Grid container className="FinalDetails-container" data-test="final-details-container">
        {sourceName !== '' && features.brandLogoCheck(sourceApi) && (
          <Grid item xs={12}>
            <Box display="flex">
              <Box className="Logo-container">
                <PartnersLogo api={sourceApi} />
              </Box>
            </Box>
          </Grid>
        )}

        <Grid item xs={12}>
          <Box mb={3} className="Card-shadow Headers">
            <Stepper step1="completed" step2="active" />

            <Grid item xs={12} className="line-separateTop Header-content">
              <Typography variant="h3" className="title">
                You&apos;re nearly there!
              </Typography>
              <Typography align="center" className="mt-2 paragraph">
                We just need some final details. It takes <b>2 minutes</b> to finish.
              </Typography>
            </Grid>
          </Box>
        </Grid>

        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleFormSubmit}
          validationSchema={fullValidationSchema}
        >
          {(formik) => (
            <Form>
              <Grid item xs={12}>
                {!isValidUserMobile && (
                  <Box mb={3} className="Card-shadow">
                    <div ref={refAboutYouContainer}></div>
                    <AboutYou step={sectionNumber.AboutYou} />
                  </Box>
                )}
              </Grid>

              <Grid item xs={12}>
                <Box mb={3} className="Card-shadow">
                  <div ref={refEmploymentContainer}></div>
                  <EmploymentContainer step={sectionNumber.Employment} />
                </Box>
              </Grid>

              <Grid item xs={12}>
                {(stringIsNotDefinedOrEmpty(user?.accountNumber) || stringIsNotDefinedOrEmpty(user?.sortCode)) && (
                  <Box mb={3} className="Card-shadow">
                    <div ref={refBankDetails}></div>
                    <BankDetails step={sectionNumber.BankDetails} />
                  </Box>
                )}
              </Grid>

              <Grid item xs={12}>
                <Box mb={3} className="Card-shadow Create-password-container">
                  <div ref={refCreatePasswordContainer}></div>
                  <SignInAndCreateAccountHeader
                    unRegisteredUser={true}
                    newUser={true}
                    userAccountLockedOut={userAccountLockedOut}
                    accountStatusExpiredOrRecovery={accountStatusExpiredOrRecovery}
                    step={sectionNumber.CreateAccount}
                  />

                  {(userAccountLockedOut || accountStatusExpiredOrRecovery) && (
                    <Box display="flex">
                      <Box className="paragraph-box">
                        <FeedbackIndicatorsComponent
                          className="feedback-indicator"
                          severity="error"
                          icon="lw-system-icons-close icon-bg-error"
                          messages={
                            accountStatusExpiredOrRecovery
                              ? messages.accountPasswordExpired
                              : messages.accountTemporarilyLocked
                          }
                          isOpen={isOpen}
                          closeAction={false}
                        />
                      </Box>
                    </Box>
                  )}

                  {oktaErrorMessages && isOpen && (
                    <div className="okta-feedback">
                      <OktaErrorMessages />
                    </div>
                  )}

                  <div className="email-password">
                    <Box mb={2} data-test="username-textfield">
                      <TextField label="Email" value={email} fullWidth disabled />
                    </Box>
                    <Box mb={3} className="intro-sign-in" data-test="password-container">
                      <Password
                        parentCallback={callback}
                        errorMessages={{
                          newPassword: '',
                        }}
                        email={email}
                        helperText="You'll need this to manage your loan"
                        newUser={true}
                        registeredUser={false}
                        passwordError={passwordError}
                        setPasswordError={setPasswordError}
                        apiResponseSuccessMessages=""
                      />
                    </Box>
                  </div>
                </Box>
              </Grid>

              <SubmitApplicationConsiderationMessage />

              {feedbackValidationMessage && (
                <Grid item xs={12}>
                  <Box mb={3} className="Card-shadow">
                    <FeedbackIndicatorsComponent
                      className="feedback-indicator"
                      severity="error"
                      icon="lw-system-icons-close icon-bg-error"
                      messages={feedbackValidationMessage}
                      isOpen={isOpen}
                      closeAction={true}
                      parentCallback={closeFeedbackIndicatorsComponent}
                    />
                  </Box>
                </Grid>
              )}

              <Grid item xs={12} className="Complete-application-container">
                <Box mb={2}>
                  <Button
                    type="submit"
                    className="complete-application-submit-button"
                    data-test="button-complete-application-submit"
                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={() => formValidation(formik.errors)}
                  >
                    <>{isSubmitting ? <Loader size="small" color="white" /> : 'Send application'}</>
                  </Button>
                </Box>
                <Box mb={3}>
                  <div className="credit-score-container">
                    <span className="fluro-icon-lock icons-fs-24-normal icons-colorBlack icon-bold mr-1" />
                    <span className="copy">Secure, encrypted process</span>
                  </div>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </LayoutWrapper>
  )
}

export default LoanApplicationFinalDetailsCreatePassword
