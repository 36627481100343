import './../_addresses.scss'

import { Box, Divider, Grid, Typography } from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'

import SelectReferenceData from 'components/Select/Baseline/SelectReferenceData'
import TextfieldCurrencyBaseline from 'components/Textfield/Baseline/TextfieldCurrencyBaseline'
import { NumericFormat } from 'react-number-format'
import { residentialStatusRequired } from '../../../../../utils/Messages'
import AddressPicker from './AddressPicker'
import useApiRequest from './UseApiRequest'

interface AddressProps {
  parentCallbackUserAddress: (
    firstAddress: string,
    monthsAtFirstAddress: number,
    firstAddressMoveInDate: string,
    firstAddressPostcode: string,
    secondAddress: string,
    monthsAtSecondAddress: number,
    secondAddressPostcode: string,
    secondAddressMoveInDate: string,
    thirdAddress: string,
    monthsAtThirdAddress: number,
    thirdAddressPostcode: string,
    thirdAddressMoveInDate: string,
    monthlyPayment: number,
    residentialStatus?: string,
  ) => void
  parentCallbackUserAddressError: (
    firstAddress: boolean,
    monthsAtFirstAddress: boolean,
    firstAddressMoveInDate: boolean,
    firstAddressPostcode: boolean,
    residentialStatus: boolean,
  ) => void
  addressError: {
    firstAddress: boolean
    monthsAtFirstAddress: boolean
    firstAddressMoveInDate: boolean
    firstAddressPostcode: boolean
    residentialStatus: boolean
  }
}

const Addresses = ({
  parentCallbackUserAddress,
  parentCallbackUserAddressError,
  addressError,
}: AddressProps): JSX.Element => {
  const [residentialStatus, setResidentialStatus] = useState<string>('')

  const [firstAddress, setFirstAddress] = useState<string>('')
  const [monthsAtFirstAddress, setMonthsAtFirstAddress] = useState<number>(-1)

  const [secondAddress, setSecondAddress] = useState<string>('')
  const [monthsAtSecondAddress, setMonthsAtSecondAddress] = useState<number>(-1)

  const [thirdAddress, setThirdAddress] = useState<string>('')
  const [monthsAtThirdAddress, setMonthsAtThirdAddress] = useState<number>(-1)
  const [loading, setLoading] = useState(true)
  const [monthlyPayment, setMonthlyPayment] = useState<string>('')
  const [firstAddressPostcode, setFirstAddressPostcode] = useState<string>('')
  const [secondAddressPostcode, setSecondAddressPostcode] = useState<string>('')
  const [thirdAddressPostcode, setThirdAddressPostcode] = useState<string>('')
  const [firstAddressMoveInDate, setFirstAddressMoveInDate] = useState<string>('')
  const [secondAddressMoveInDate, setSecondAddressMoveInDate] = useState<string>('')
  const [thirdAddressMoveInDate, setThirdAddressMoveInDate] = useState<string>('')

  const requiresSecondAddress = monthsAtFirstAddress !== -1 && monthsAtFirstAddress < 12
  const requiresThirdAddress =
    monthsAtSecondAddress !== -1 && requiresSecondAddress && monthsAtFirstAddress + monthsAtSecondAddress < 12

  const handleResidentialStatusesOption = (chosenStatus: string) => {
    setResidentialStatus(chosenStatus)
  }

  const handleAmountChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMonthlyPayment(e.target.value)
  }

  const props = useApiRequest()

  const passValueToParent = useCallback(
    (key: string, value: string) => {
      parentCallbackUserAddress(
        key === 'firstAddress' ? value : firstAddress,
        key === 'monthsAtFirstAddress' ? Number(value) : monthsAtFirstAddress,
        key === 'firstAddressMoveInDate' ? value : firstAddressMoveInDate,
        key === 'firstAddressPostcode' ? value : firstAddressPostcode,
        key === 'secondAddress' ? value : secondAddress,
        key === 'monthsAtSecondAddress' ? Number(value) : monthsAtSecondAddress,
        key === 'secondAddressMoveInDate' ? value : secondAddressMoveInDate,
        key === 'secondAddressPostcode' ? value : secondAddressPostcode,
        key === 'thirdAddress' ? value : thirdAddress,
        key === 'monthsAtThirdAddress' ? Number(value) : monthsAtThirdAddress,
        key === 'thirdAddressMoveInDate' ? value : thirdAddressMoveInDate,
        key === 'thirdAddressPostcode' ? value : thirdAddressPostcode,
        key === 'monthlyPayment' ? Number(value) : Number(monthlyPayment),
        key === 'residentialStatus' ? value : residentialStatus,
      )
    },
    [
      parentCallbackUserAddress,
      firstAddress,
      monthsAtFirstAddress,
      firstAddressMoveInDate,
      firstAddressPostcode,
      secondAddress,
      monthsAtSecondAddress,
      secondAddressMoveInDate,
      secondAddressPostcode,
      thirdAddress,
      monthsAtThirdAddress,
      thirdAddressMoveInDate,
      thirdAddressPostcode,
      monthlyPayment,
      residentialStatus,
    ],
  )

  useEffect(() => {
    if (loading) {
      props.fetchResidentialStatuses()
    }
    setLoading(false)
  }, [loading, setLoading, props.fetchResidentialStatuses, props])

  useEffect(() => {
    passValueToParent('firstAddress', firstAddress)
    passValueToParent('monthsAtFirstAddress', monthsAtFirstAddress.toString())
    passValueToParent('firstAddressPostcode', firstAddressPostcode)
    passValueToParent('secondAddress', secondAddress)
    passValueToParent('monthsAtSecondAddress', monthsAtSecondAddress.toString())
    passValueToParent('secondAddressPostcode', secondAddressPostcode)
    passValueToParent('thirdAddress', thirdAddress)
    passValueToParent('monthsAtThirdAddress', monthsAtThirdAddress.toString())
    passValueToParent('thirdAddressPostcode', thirdAddressPostcode)
    passValueToParent('monthlyPayment', monthlyPayment)
    passValueToParent('residentialStatus', residentialStatus)
  }, [
    firstAddress,
    monthsAtFirstAddress,
    firstAddressPostcode,
    secondAddress,
    monthsAtSecondAddress,
    secondAddressPostcode,
    thirdAddress,
    monthsAtThirdAddress,
    thirdAddressPostcode,
    monthlyPayment,
    passValueToParent,
    residentialStatus,
  ])

  return (
    <Grid container className="addresses-container" data-test="addresses-container">
      <Grid item xs={12}>
        <Box>
          <Box mb={3} className="Card-shadow Headers lines first-address-container">
            <Grid item xs={12} className="Account-content line-separate">
              <Box>
                <Typography variant="h4">3. Current address</Typography>
              </Box>
            </Grid>

            <AddressPicker
              address={firstAddress}
              addressSetter={setFirstAddress}
              previousMonthsOfAddressHistory={-1}
              monthsOfAddressHistorySetter={setMonthsAtFirstAddress}
              parentCallbackUserAddressError={parentCallbackUserAddressError}
              addressError={addressError}
              moveInDateCheck={setFirstAddressMoveInDate}
              postcodeSetter={setFirstAddressPostcode}
            />
            {firstAddress !== '' && (
              <Box className="residential-status-and-monthly-payment">
                <Box mb={2}>
                  <SelectReferenceData
                    dataTest="residential-status"
                    variant="filled"
                    onChange={handleResidentialStatusesOption}
                    value={residentialStatus}
                    options={props.residentialStatuses}
                    label="Residential status"
                    error={addressError.residentialStatus}
                    helperText={addressError.residentialStatus ? residentialStatusRequired : ''}
                  />
                </Box>
                {['Homeowner - With Mortgage', 'Tenant - Paying Rent'].includes(residentialStatus) && (
                  <Box mb={2} data-test="monthly-mortgage-or-rent-payment">
                    <NumericFormat
                      id="homeowner"
                      decimalScale={2}
                      thousandSeparator={','}
                      decimalSeparator={'.'}
                      fullWidth
                      customInput={TextfieldCurrencyBaseline}
                      label={
                        residentialStatus === 'Homeowner - With Mortgage'
                          ? 'What is your monthly mortgage payment?'
                          : 'What is your monthly rent payment?'
                      }
                      value={monthlyPayment}
                      onChange={handleAmountChange}
                      inputProps={{ inputMode: 'numeric' }}
                    />
                  </Box>
                )}
              </Box>
            )}
            {requiresSecondAddress && (
              <Box data-test="second-address-container">
                <Divider />
                <Box className="subsequent-address-title">
                  <Typography variant="h6">Previous address</Typography>
                  <Typography className="subsequent-address-sub-copy">
                    Please provide your previous address. It helps us confirm your identity.
                  </Typography>
                </Box>
                <AddressPicker
                  address={secondAddress}
                  addressSetter={setSecondAddress}
                  previousMonthsOfAddressHistory={monthsAtFirstAddress}
                  monthsOfAddressHistorySetter={setMonthsAtSecondAddress}
                  parentCallbackUserAddressError={parentCallbackUserAddressError}
                  addressError={addressError}
                  moveInDateCheck={setSecondAddressMoveInDate}
                  postcodeSetter={setSecondAddressPostcode}
                />
              </Box>
            )}
            {requiresThirdAddress && (
              <Box data-test="third-address-container">
                <Divider />
                <Box className="subsequent-address-title">
                  <Typography variant="h6">Previous address</Typography>
                  <Typography className="subsequent-address-sub-copy">
                    Please provide 1 more address (we ask for 3 addresses if you've moved a lot).
                  </Typography>
                </Box>
                <AddressPicker
                  address={thirdAddress}
                  addressSetter={setThirdAddress}
                  previousMonthsOfAddressHistory={monthsAtSecondAddress}
                  monthsOfAddressHistorySetter={setMonthsAtThirdAddress}
                  parentCallbackUserAddressError={parentCallbackUserAddressError}
                  addressError={addressError}
                  moveInDateCheck={setThirdAddressMoveInDate}
                  postcodeSetter={setThirdAddressPostcode}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Addresses
