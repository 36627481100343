import './_accountMenu.scss'

import * as paths from 'utils/Links'

import { Badge, Box, Menu, MenuItem, Typography } from '@mui/material'
import { oktaWrapper } from 'api/OktaConfig'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { menuItems, menuSubitems } from 'utils/LinksMenu'
import UseApiRequest from './UseApiRequest'

const AccountMenu = (): JSX.Element => {
  const okta = oktaWrapper.useOktaAuth()
  const { authState, oktaAuth } = okta
  const [userInfo, setUserInfo] = useState<string[] | undefined>([])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const { notifications, getNotificationsSummary } = UseApiRequest()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    function fetchNotification() {
      if (!authState || !authState.isAuthenticated) {
        setUserInfo([]) // When user isn't authenticated, forget any user info
      } else {
        if (authState && authState.idToken && authState.idToken.claims) {
          const user = authState.idToken.claims.name
          const nameObject = user?.split(' ')
          setUserInfo(nameObject)
          getNotificationsSummary()
        }
      }
    }

    fetchNotification()
  }, [authState, oktaAuth]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleMenuClick = (pageURL: string, serverRedirect?: boolean) => {
    if (serverRedirect) {
      paths.serverRedirect(pageURL)
    } else {
      navigate(pageURL)
    }

    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    open ? setAnchorEl(null) : setAnchorEl(e.currentTarget)
  }

  const currentPath = location.pathname

  return (
    <>
      <ul className="AccountMenu AppMenuBar" data-test="account-menu-bar">
        <li className={`${anchorEl && 'anchorEl-active'}`}>
          <Typography onClick={handleMenu} data-test="account-menu-icon">
            <span>
              <span data-test="user-info-name" className="userInfoName">
                {userInfo ? userInfo[0] : ''}
              </span>
              <Badge badgeContent={notifications} color="error">
                <span className="TopMenuIcon lw-marketing-icons-user"></span>
              </Badge>
            </span>
          </Typography>

          <Menu
            className="TopbarMenu TopbarMenu-manIcon"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {menuItems.map((menuItem, index) => {
              const { menuTitle, pageURL, badge, serverRedirect } = menuItem
              return (
                <MenuItem
                  data-test={menuItem.testDescription}
                  onClick={() => handleMenuClick(pageURL, serverRedirect)}
                  key={index}
                  selected={currentPath === pageURL}
                >
                  <a
                    className="MuiLink-withoutUnderline menu-cta"
                    href={pageURL}
                    onClick={(e) => {
                      e.preventDefault()
                    }}
                  >
                    {menuTitle}
                    {badge && (
                      <Box component="span" ml={8}>
                        <Badge badgeContent={notifications} color="error" />
                      </Box>
                    )}
                  </a>
                </MenuItem>
              )
            })}

            <div className="menuSeparateLine"></div>

            {menuSubitems.map((menuItem, index) => {
              const { menuTitle, pageURL, icon } = menuItem
              return (
                <MenuItem
                  data-test={menuItem.testDescription}
                  onClick={() => handleMenuClick(pageURL, false)}
                  key={index}
                  selected={currentPath === pageURL}
                >
                  <a
                    className="MuiLink-withoutUnderline menu-cta"
                    href={pageURL}
                    onClick={(e) => {
                      e.preventDefault()
                    }}
                  >
                    {icon && <Box component="span" mr={1} className={`icons-colorBlack icons-fs-16 ${icon}`}></Box>}
                    {menuTitle}
                  </a>
                </MenuItem>
              )
            })}
          </Menu>
        </li>
      </ul>
    </>
  )
}

export default AccountMenu
