import * as helper from 'utils/HelperFunction'
import * as paths from 'utils/Links'
import * as logging from 'utils/Logging'
import * as messages from 'utils/Messages'
import * as types from 'utils/Types'

import { Dispatch, SetStateAction, useState } from 'react'

import { identifyHeapUser } from 'api/HeapConfig'
import { oktaWrapper } from 'api/OktaConfig'

type T = { status: string }
interface Props {
  oktaErrorMessages: string
  postSignInWithCredentials: (
    username: string,
    password: string,
    setIsSubmitting: (isSubmitting: boolean) => void,
    redirectUrl?: string,
  ) => void
  setOktaErrorMessages: Dispatch<SetStateAction<string>>
  successMessages: string
  postForgotPassword: (
    email: string,
    authClient: {
      forgotPassword: (arg0: types.AuthClientForgotPasswordType) => Promise<T>
    },
    setLoading: (loading: boolean) => void,
  ) => void
  updateStateSetOktaErrorMessages: () => void
}

const useOktaRequest = (): Props => {
  const okta = oktaWrapper.useOktaAuth()
  const { oktaAuth } = okta
  const [oktaErrorMessages, setOktaErrorMessages] = useState<string>('')
  const [successMessages, setSuccessMessages] = useState<string>('')

  const postSignInWithCredentials = (
    username: string,
    password: string,
    setIsSubmitting: (isSubmitting: boolean) => void,
    redirectUrl?: string,
  ) => {
    oktaAuth
      .signInWithCredentials({
        username,
        password,
      })
      .then(function (transaction) {
        const { sessionToken } = transaction

        if (transaction.status === 'SUCCESS' && transaction.sessionToken) {
          oktaAuth.token
            .getWithoutPrompt({
              responseType: 'id_token',
              sessionToken,
            })
            .then((res) => {
              const { tokens } = res

              oktaAuth.tokenManager.setTokens(tokens)
              identifyHeapUser(oktaAuth)

              if (redirectUrl !== 'auto-login') {
                const URL = window.location.origin + (redirectUrl || paths.getDashboard())
                window.location.href = URL
              }
            })
            .catch((err) => {
              console.log('err', err)
            })
        } else {
          let errMessages = `We cannot handle the ${transaction.status} status`
          if (transaction.status === 'LOCKED_OUT') {
            errMessages = messages.statusAccountLocked
          }
          setOktaErrorMessages(errMessages)
          setIsSubmitting(false)
        }
      })
      .catch(function (err) {
        let errMessages: string
        if (err.errorCode === 'E0000001') {
          errMessages = messages.enterPassword
        } else if (err.errorCode === 'E0000004') {
          errMessages = messages.incorrectPassword
        } else if (err.errorCauses && err.errorCauses[0].errorSummary) {
          logging.logError('postSignInWithCredentials', err)
          errMessages = err.errorCauses[0].errorSummary
        } else {
          logging.logError('postSignInWithCredentials', err)
          errMessages = err.toString()
        }
        setOktaErrorMessages(errMessages)
        setIsSubmitting(false)
      })
  }

  const postForgotPassword = async (
    email: string,
    authClient: {
      forgotPassword: (arg0: types.AuthClientForgotPasswordType) => Promise<T>
    },
    setLoading: (loading: boolean) => void,
  ) => {
    authClient
      .forgotPassword({
        username: email,
        factorType: 'Email',
      })
      .then(function (transaction: { status: string }) {
        if (transaction.status === 'RECOVERY_CHALLENGE') {
          setSuccessMessages('Password recovery challenge sent.')
        } else {
          throw Error(`We cannot handle the ${transaction.status} status`)
        }
      })
      .catch(function (error: {
        name?: string | undefined
        statusCode?: number | undefined
        config?: { headers?: { Authorization?: string | undefined } | undefined } | undefined
      }) {
        logging.logError('postForgotPassword', error)
      })
      .finally(() => {
        if (helper.checkIsLoadingFunction(setLoading)) {
          setLoading(false)
        }
      })
  }

  const updateStateSetOktaErrorMessages = () => {
    setOktaErrorMessages('')
  }

  return {
    oktaErrorMessages,
    postSignInWithCredentials,
    setOktaErrorMessages,
    successMessages,
    postForgotPassword,
    updateStateSetOktaErrorMessages,
  }
}

export default useOktaRequest
