import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useState } from 'react'

function useSticky() {
  const [isSticky, setIsSticky] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const changeClass = () => {
    const scrollValue = document.documentElement.scrollTop
    const bannerHeight = isMobile ? 250 : 350

    if (scrollValue > bannerHeight) {
      setIsSticky(true)
    } else {
      setIsSticky(false)
    }
  }
  window.addEventListener('scroll', changeClass)

  return { isSticky }
}

export default useSticky
