import * as logging from 'utils/Logging'

import { borrowerPublicApiClient } from 'api/BorrowerPublicApiClient'
import { ReferenceDataItem } from 'api/BorrowerPublicApiSchemas'
import { useState } from 'react'

interface GetEmployment {
  employmentStatusesOptions: ReferenceDataItem[]
  employmentPositionsOptions: ReferenceDataItem[]
  employmentSectorsOptions: ReferenceDataItem[]
  incomeChangeReasonsOption: ReferenceDataItem[]
  fetchEmploymentStatuses: () => void
  fetchEmploymentPositions: () => void
  fetchEmploymentSectors: () => void
  fetchIncomeChangeReasons: () => void
}

const GetEmploymentApiRequest = (): GetEmployment => {
  const borrowerPublicApi = borrowerPublicApiClient()

  const [employmentStatusesOptions, setEmploymentStatusesOptions] = useState<ReferenceDataItem[]>([])
  const [employmentPositionsOptions, setEmploymentPositionsOptions] = useState<ReferenceDataItem[]>([])
  const [employmentSectorsOptions, setEmploymentSectorsOptions] = useState<ReferenceDataItem[]>([])
  const [incomeChangeReasonsOption, setIncomeChangeReasonsOptions] = useState<ReferenceDataItem[]>([])

  const fetchEmploymentStatuses = () => {
    borrowerPublicApi.getEmploymentStatuses().then(
      (data) => {
        setEmploymentStatusesOptions(data)
      },
      (error) => {
        logging.logError('fetchEmploymentStatuses', error)
      },
    )
  }

  const fetchEmploymentPositions = () => {
    borrowerPublicApi.getEmploymentPositions().then(
      (data) => {
        setEmploymentPositionsOptions(data)
      },
      (error) => {
        logging.logError('fetchEmploymentPositions', error)
      },
    )
  }

  const fetchEmploymentSectors = () => {
    borrowerPublicApi.getEmploymentSectors().then(
      (data) => {
        setEmploymentSectorsOptions(data)
      },
      (error) => {
        logging.logError('fetchEmploymentSectors', error)
      },
    )
  }

  const fetchIncomeChangeReasons = () => {
    borrowerPublicApi.getIncomeChangeReasons().then(
      (data) => {
        setIncomeChangeReasonsOptions(data)
      },
      (error) => {
        logging.logError('fetchIncomeChangeReasons', error)
      },
    )
  }

  return {
    employmentStatusesOptions,
    employmentPositionsOptions,
    employmentSectorsOptions,
    incomeChangeReasonsOption,
    fetchEmploymentStatuses,
    fetchEmploymentPositions,
    fetchEmploymentSectors,
    fetchIncomeChangeReasons,
  }
}

export default GetEmploymentApiRequest
