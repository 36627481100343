import './_app.scss'
import './assets/styles/global.scss'

import * as logging from 'utils/Logging'
import * as paths from './utils/Links'

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { LoginCallback, Security } from '@okta/okta-react'
import { RestoreOriginalUriFunction } from '@okta/okta-react/bundles/types/OktaContext'
import { Suspense, lazy } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import PageNotFound from 'view/portal/common/NotFound/Home'
import { Login, customAuthHandler } from 'view/portal/login/Home'
import { LogoutView } from 'view/portal/logout/Home'
import { PasswordReset } from 'view/portal/passwordReset/Home'
import ResetPassword from 'view/portal/passwordReset/Reset'
import LoanIntroductionForm from 'view/public/loanIntroduction/Home'
import LoanApplicationFinalDetailsCreatePassword from 'view/public/loanIntroduction/finalDetails/Home'
import LoanQuote from 'view/public/loanQuote/Home'
import { RequiredAuth } from './RequiredAuth'
import { resetHeapUser } from './api/HeapConfig'
import { oktaConfiguration } from './api/OktaConfig'
import { SignUp } from './view/portal/signup/Home'
import UnauthenticatedOpenBanking from './view/public/openbanking/UnauthenticatedOpenBanking'
import UnauthenticatedOpenBankingProcessing from './view/public/openbanking/UnauthenticatedOpenBankingProcessing'

const Dashboard = lazy(() => import('./view/portal/dashboard/Home'))
const AccountDetails = lazy(() => import('view/portal/accountDetails/Home'))
const UploadDocuments = lazy(() => import('view/portal/uploadDocuments/Home'))
const ChangePaymentDay = lazy(() => import('view/portal/loanManagement/changePaymentDay/Home'))
const ConfirmNewPaymentDay = lazy(() => import('view/portal/loanManagement/confirmNewPaymentDay/Home'))
const Overpayment = lazy(() => import('view/portal/loanManagement/payments/makeOverpayment/Home'))
const PayArrears = lazy(() => import('view/portal/loanManagement/payments/payArrears/Home'))
const ReAged = lazy(() => import('view/portal/loanManagement/payments/reAged/Home'))
const SettleLoanWrapper = lazy(() => import('view/portal/loanManagement/payments/settleLoan/Home'))
const ManagePayment = lazy(() => import('view/portal/loanManagement/managePayments/Home'))

const LoanStatements = lazy(() => import('view/portal/loanManagement/loanStatements/Home'))
const DebitCardSuccess = lazy(
  () => import('view/portal/loanManagement/payments/paymentMethod/paymentResponse/DebitCardSuccess'),
)
const UnauthenticatedDebitCardSuccess = lazy(
  () => import('view/public/payments/paymentResponse/UnauthenticatedDebitCardSuccess'),
)
const DirectDebitAndDebitCardError = lazy(
  () => import('view/portal/loanManagement/payments/paymentMethod/paymentResponse/DirectDebitAndDebitCardError'),
)
const DirectDebitSuccess = lazy(
  () => import('view/portal/loanManagement/payments/paymentMethod/paymentResponse/DirectDebitSuccess'),
)
const IdentityDocuments = lazy(() => import('view/portal/loanApplication/referred/IdentityDocuments'))
const IncomeDocuments = lazy(() => import('view/portal/loanApplication/referred/IncomeDocuments'))
const LoanAcceptanceDirectDebitInstruction = lazy(() => import('view/portal/loanAcceptance/acceptCreditAgreement/Home'))
const LoanAcceptanceGenerateLoanDocument = lazy(() => import('view/portal/loanAcceptance/generateLoanDocument/Home'))
const CardPaymentRequest = lazy(() => import('view/public/payments/cardPaymentRequest/Home'))
const LoanApplicationApproval = lazy(() => import('view/portal/loanApplication/approval/Home'))
const LoanApplicationFinalDetails = lazy(() => import('view/portal/loanApplication/finalDetails/LoanApplicationForm'))
const OpenBanking = lazy(() => import('view/portal/loanApplication/referred/OpenBanking'))
const OpenBankingCallback = lazy(() => import('view/portal/loanApplication/referred/OpenBankingCallback'))

const RepaymentPlan = lazy(() => import('view/portal/loanManagement/repaymentPlan/Home'))
const CreditDocuments = lazy(() => import('view/portal/loanManagement/creditDocuments/Home'))
const Notifications = lazy(() => import('view/portal/notifications/Home'))

const App = (): JSX.Element => {
  const oktaAuth = new OktaAuth(oktaConfiguration)
  const navigate = useNavigate()

  const restoreOriginalUri: RestoreOriginalUriFunction = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin))
  }

  const Logout = () => {
    setTimeout(function () {
      resetHeapUser()

      oktaAuth.isAuthenticated().then((isAuthenticated) => {
        if (isAuthenticated) {
          logging.logInfo('logging out')
          oktaAuth.signOut({
            postLogoutRedirectUri: window.location.origin + '/',
          })

          // clear the sessionStorage
          window.sessionStorage.clear()
        } else {
          logging.logInfo('already logged out')
          paths.serverRedirect('/')
        }
      })
    }, 100)
    return <LogoutView />
  }

  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler(oktaAuth)} restoreOriginalUri={restoreOriginalUri}>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Navigate to="/borrowing-centre" replace />} />
          <Route path={paths.getSignInCallback()} element={<LoginCallback loadingElement={<div>Loading...</div>} />} />
          <Route path={paths.getCardPaymentRequest()} element={<CardPaymentRequest />} />
          <Route path={paths.getUnauthenticatedDebitCardSuccess()} element={<UnauthenticatedDebitCardSuccess />} />
          <Route path={paths.getUnauthenticatedDebitCardError()} element={<DirectDebitAndDebitCardError />} />
          <Route path={paths.getSignIn()} element={<Login />} />
          <Route path={paths.getSignUp()} element={<SignUp />} />
          <Route path={paths.getPasswordResetCallback()}>
            <Route path="" element={<PasswordReset name="password-reset" />} />
          </Route>
          <Route path={paths.getUserCreatePasswordCallback()}>
            <Route path="" element={<PasswordReset name="create-password" />} />
          </Route>
          <Route path={paths.getResetPassword()} element={<ResetPassword />} />
          <Route path={paths.getLogout()} element={<Logout />} />
          <Route path={paths.getPartnerIntroduction()} element={<LoanIntroductionForm />} />
          <Route path={paths.getLoanIntroduction()} element={<LoanIntroductionForm />} />
          <Route path={paths.getLoanIntroductionUUID()} element={<LoanIntroductionForm />} />
          <Route path={paths.getBrokerLoanIntroduction()} element={<LoanIntroductionForm />} />
          <Route path={paths.getBrokerLoanIntroductionUUID()} element={<LoanIntroductionForm />} />
          <Route path={paths.getPcsLoanIntroduction()} element={<LoanIntroductionForm />} />
          <Route path={paths.getPcsLoanIntroductionUUID()} element={<LoanIntroductionForm />} />
          <Route path={paths.getLoanApplicationCreateForm()} element={<LoanApplicationFinalDetailsCreatePassword />} />
          <Route path={paths.getLoanQuote()} element={<LoanQuote />} />
          <Route path={paths.getNotFound()} element={<PageNotFound />} />
          <Route path={paths.getUnauthenticatedOpenBanking()} element={<UnauthenticatedOpenBanking />} />
          <Route
            path={paths.getUnauthenticatedOpenBankingCallback()}
            element={<UnauthenticatedOpenBankingProcessing />}
          />

          <Route path="/" element={<RequiredAuth />}>
            <Route path={paths.getDashboard()} element={<Dashboard />} />
            <Route path={paths.getAllManagePayments()} element={<ManagePayment />} />
            <Route path={paths.getAllChangePaymentDay()} element={<ChangePaymentDay />} />
            <Route path={paths.getUploadDocuments()} element={<UploadDocuments />} />
            <Route path={paths.getMakeOverpayment()} element={<Overpayment />} />
            <Route path={paths.getSettleLoan()} element={<SettleLoanWrapper />} />
            <Route path={paths.getAllPayArrears()} element={<PayArrears />} />
            <Route path={paths.getPayArrears()} element={<PayArrears />} />
            <Route path={paths.getAllPayReAged()} element={<ReAged />} />
            <Route path={paths.getPayReAged()} element={<ReAged />} />
            <Route path={paths.getDirectDebitError()} element={<DirectDebitAndDebitCardError />} />
            <Route path={paths.getDirectDebitSuccess()} element={<DirectDebitSuccess />} />
            <Route path={paths.getDebitCardError()} element={<DirectDebitAndDebitCardError />} />
            <Route path={paths.getDebitCardSuccess()} element={<DebitCardSuccess />} />
            <Route path={paths.getConfirmNewPaymentDay()} element={<ConfirmNewPaymentDay />} />
            <Route path={paths.getAccounts()} element={<AccountDetails />} />
            <Route path={paths.getNotifications()} element={<Notifications />} />
            <Route path={paths.getAllViewLoanStatement()} element={<LoanStatements />} />
            <Route path={paths.getAllViewCreditDocument()} element={<CreditDocuments />} />
            <Route path={paths.getLoanApplicationForm()} element={<LoanApplicationFinalDetails />} />
            <Route path={paths.getLoanApplicationApproved()} element={<LoanApplicationApproval />} />
            <Route
              path={paths.getLoanApplicationRequiresOpenbanking()}
              element={<OpenBanking mandatoryOpenBanking={true} />}
            />
            <Route path={paths.confirmIncome()} element={<OpenBanking mandatoryOpenBanking={true} />} />
            <Route path={paths.confirmIncomeSuccess()} element={<OpenBankingCallback />} />
            <Route path={paths.getOpenBankingCallback()} element={<OpenBankingCallback />} />
            <Route path={paths.getLoanApplicationIncomeDocumentUpload()} element={<IncomeDocuments />} />
            <Route path={paths.getLoanApplicationIdentityDocumentUpload()} element={<IdentityDocuments />} />
            <Route path={paths.getLadeEmailLinkIdentityDocumentUpload()} element={<IdentityDocuments />} />
            <Route path={paths.getLoanAgreement()} element={<LoanAcceptanceGenerateLoanDocument />} />
            <Route path={paths.getDirectDebitInstruction()} element={<LoanAcceptanceDirectDebitInstruction />} />
            <Route path={paths.getRepaymentPlan()} element={<RepaymentPlan />} />
            <Route path={paths.getRepaymentPlanUrlConditions()} element={<RepaymentPlan />} />
            <Route path={paths.getStaticsPage()} element={<Navigate to="/borrowing-centre" />} />
            <Route path={paths.getRedundantDrupalPage()} element={<Navigate to="/borrowing-centre" />} />
            <Route path="*" element={<Navigate to={paths.getNotFound()} />} />
          </Route>
        </Routes>
      </Suspense>
    </Security>
  )
}

export default App
