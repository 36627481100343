import '../_openBankingOrDocUploadSuccess.scss'

import * as paths from 'utils/Links'

import { Box, Button, CssBaseline, Grid, Typography } from '@mui/material'

import { useEffect, useState } from 'react'
import { errorMessagesTitle } from 'utils/Messages'
import { customerServiceNumber } from 'utils/Variable'
import { heapTrack } from '../../../../../utils/Features'
import LayoutWrapper from '../../../../public/layout/LayoutWrapper'
import MandatoryOpenBankingApiRequest from './MandatoryOpenBankingApiRequest'

const handleClick = () => {
  window.location.href = paths.getDashboard()
}

interface MandatoryOpenBankingConnectionFailureProps {
  reference: string
}

const MandatoryOpenBankingConnectionFailure = (props: MandatoryOpenBankingConnectionFailureProps): JSX.Element => {
  const [flag, setFlag] = useState(true)
  const { requestOpenBankingCall } = MandatoryOpenBankingApiRequest()

  useEffect(() => {
    if (flag) {
      heapTrack('MandatoryOpenBankingConnectionFailure')
      requestOpenBankingCall(props.reference)
      setFlag(false)
    }
  }, [flag, props.reference, requestOpenBankingCall])

  return (
    <LayoutWrapper className="openbanking-or-doc-upload-container openbanking-wrapper">
      <CssBaseline />
      <Grid container className="openbanking-or-doc-upload-success-container">
        <Grid item xs={12}>
          <Box mb={6} mt={12} className="Card-shadow Headers lines">
            <div className="success-container-top">
              <Box display="flex">
                <Box className="icon-container">
                  <div className="lw-system-icons-info icon-bg-azurite icons-fs-64 icon-bold" />
                </Box>
              </Box>
              <Box display="flex">
                <Box flexGrow={2} className="title-box">
                  <Typography variant="h1">{errorMessagesTitle}</Typography>
                  <Typography variant="h4">We couldn&rsquo;t connect to your bank account.</Typography>
                </Box>
              </Box>
            </div>
            <div className="success-container-bottom open-banking-mandatory">
              <Box display="flex">
                <Box className="paragraph-box">
                  <p>
                    One of our underwriters will now review your application. We will aim to call you within one
                    business day with a final decision. Should we need to call you, we will do so from{' '}
                    {customerServiceNumber}.
                  </p>
                  <Button className="dashboard-button" variant="contained" color="primary" onClick={handleClick}>
                    Go to dashboard
                  </Button>
                  <div className="secure-process">
                    <span className="fluro-icon-lock icons-colorInkDark icons-fs-20" />
                    <span>Secure, encrypted process</span>
                  </div>
                </Box>
              </Box>
            </div>
          </Box>
        </Grid>
      </Grid>
    </LayoutWrapper>
  )
}

export default MandatoryOpenBankingConnectionFailure
