import { useOktaAuth } from '@okta/okta-react'
import { AppEnv, appEnv } from './AppEnv'

function oktaConfig() {
  const origin = window.location.origin
  const callbackPath = '/login/callback'
  const scopes = 'openid profile groups'.split(/\s+/)

  if (appEnv === AppEnv.Prod) {
    return {
      issuer: `https://idp.fluro.co.uk/oauth2/aus14w311tF4AUAOT417`,
      clientId: '0oa14w4rhzS2m4UcI417',
      redirectUri: `${origin}${callbackPath}`,
      scopes: scopes,
      disableHttpsCheck: false,
      useInteractionCode: false,
      // AT-224 customer system clock varies and invalidates okta token. Disabling maxClockSkew.
      ignoreLifetime: true,
    }
  } else {
    return {
      issuer: `https://idp-sandbox.fluro.co.uk/oauth2/aus31y8a4sFLIiItg0x7`,
      clientId: '0oa31y8ygerJeUErt0x7',
      redirectUri: `${origin}${callbackPath}`,
      scopes: scopes,
      disableHttpsCheck: true,
      useInteractionCode: false,
      ignoreLifetime: true,
    }
  }
}

export const oktaWrapper = {
  useOktaAuth: useOktaAuth,
}
export const oktaConfiguration = oktaConfig()
