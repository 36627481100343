import Accepty from 'assets/images/logo/partners/accepty.svg'
import AsdaMoney from 'assets/images/logo/partners/asda-money.svg'
import Bud from 'assets/images/logo/partners/bud.svg'
import BudWithIcon from 'assets/images/logo/partners/bud-with-icon.svg'
import Churchill from 'assets/images/logo/partners/churchill.svg'
import ClearScore from 'assets/images/logo/partners/clearscore.svg'
import CompareTheMarket from 'assets/images/logo/partners/compare-the-market.svg'
import Confused from 'assets/images/logo/partners/confused.svg'
import CredAbility from 'assets/images/logo/partners/credability.svg'
import DirectLine from 'assets/images/logo/partners/direct-line.svg'
import ESMoney from 'assets/images/logo/partners/es-money.svg'
import Experian from 'assets/images/logo/partners/experian.svg'
import Finder from 'assets/images/logo/partners/finder.svg'
import FluentLoans from 'assets/images/logo/partners/fluent-loans.svg'
import ForbesAdvisor from 'assets/images/logo/partners/forbes-advisor.svg'
import FreedomFinance from 'assets/images/logo/partners/freedom-finance.svg'
import GoCompare from 'assets/images/logo/partners/go-compare.svg'
import Hitched from 'assets/images/logo/partners/hitched.svg'
import Fluro from 'assets/images/logo/partners/fluro-logo.svg'
import LoansWarehouse from 'assets/images/logo/partners/loans-warehouse.svg'
import MSM from 'assets/images/logo/partners/msm.svg'
import Monevo from 'assets/images/logo/partners/monevo.svg'
import Money from 'assets/images/logo/partners/money-co-uk.svg'
import MoneyGuru from 'assets/images/logo/partners/money-guru.svg'
import MoneySavingExpert from 'assets/images/logo/partners/money-saving-expert.svg'
import NortonFinance from 'assets/images/logo/partners/norton-finance.svg'
import OceanFinance from 'assets/images/logo/partners/ocean-finance.svg'
import Revolut from 'assets/images/logo/partners/revolut.svg'
import Runpath from 'assets/images/logo/partners/runpath.svg'
import SimplyMoney from 'assets/images/logo/partners/simply-money.svg'
import SmarterSavings from 'assets/images/logo/partners/smarter-savings.svg'
import TotallyMoney from 'assets/images/logo/partners/totally-money.svg'

const PartnersSVG = {
  Accepty,
  AsdaMoney,
  Bud,
  BudWithIcon,
  Churchill,
  ClearScore,
  CompareTheMarket,
  Confused,
  CredAbility,
  DirectLine,
  ESMoney,
  Experian,
  Finder,
  FluentLoans,
  ForbesAdvisor,
  FreedomFinance,
  GoCompare,
  Hitched,
  Fluro,
  LoansWarehouse,
  MSM,
  Monevo,
  Money,
  MoneyGuru,
  MoneySavingExpert,
  NortonFinance,
  OceanFinance,
  Revolut,
  Runpath,
  SimplyMoney,
  SmarterSavings,
  TotallyMoney,
}

export default PartnersSVG
