import { IconButton, Tooltip } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  tooltipFixedWidth: {
    maxWidth: '100px',
    width: '100px',
    padding: 10,
  },
}))

export interface FileDeleteProps {
  file: File
  onDelete: (file: File) => void
  className: string
}

export function FileDelete({ file, onDelete, className }: FileDeleteProps): JSX.Element {
  const classes = useStyles()

  const iconColor = className === 'success' ? 'icons-colorPrimary' : ''

  return (
    <Tooltip title="Remove file" arrow classes={{ tooltip: classes.tooltipFixedWidth }}>
      <IconButton
        role="iconbutton"
        className={`IconButton-system-close IconButton-system-closeBackground-${className}`}
        aria-label="close"
        color="inherit"
        onClick={() => onDelete(file)}
        size="large"
      >
        <span className={`lw-system-icons-close-x icons-fs-16 ${iconColor}`}></span>
      </IconButton>
    </Tooltip>
  )
}
