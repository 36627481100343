import './../_loanQuote.scss'

import * as loanStatus from 'utils/LoanStatus'

import { Box, Button, CssBaseline, Grid } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import YourFinances, { employmentStatusesWithoutTimeInRole } from 'view/public/loanQuote/yourFinances/YourFinances'

import { CreateQuoteAuthenticatedRequest } from 'api/BorrowerApiSchemas'
import Loader from 'components/Loader/Loader'
import Stepper from 'components/Stepper/Stepper'
import LoanApplicationDeclined from 'view/portal/components/declined/Home'
import QuoteDetails from 'view/portal/components/quoteDetails/Home'
import HeaderNavigationWithLogin from 'view/portal/layout/HeaderNavigationWithLogin'
import UseQuoteApiRequestAuthenticated from 'view/public/common/api/UseQuoteApiRequestAuthenticated'
import TermsAndConditions from 'view/public/common/shared/TermsAndConditions'
import LayoutWrapper from 'view/public/layout/LayoutWrapper'
import RightContentWrapper from 'view/public/layout/RightContentWrapper'
import AddressDetails from 'view/public/loanQuote/addresses/addressDetails/Addresses'
import LoanDetail from 'view/public/loanQuote/loanDetails/Home'
import FeedbackIndicatorsComponent from '../../../../components/FeedbackIndicators/FeedbackIndicators'

const LoggedIn = (): JSX.Element => {
  const [apiError, setApiError] = useState(false)
  const { submitQuoteApplicationLogged, responseQuoteRequestAuthenticated, quoteDetails } =
    UseQuoteApiRequestAuthenticated()

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [showQuoteDetails, setShowQuoteDetails] = useState<boolean>(false)

  const [loanDetails, setLoanDetails] = useState({
    loanAmount: 10000,
    loanDuration: '',
    loanPurpose: '',
    purposeReason: '',
  })

  const [loanDetailsError, setLoanDetailsError] = useState({
    loanAmount: false,
    loanDuration: false,
    loanPurpose: false,
    purposeReason: false,
  })

  const [yourFinancesErrors, setYourFinancesErrors] = useState({
    employmentStatus: false,
    timeInRole: false,
    monthlyIncome: false,
  })

  const [monthlyPayment, setMonthlyPayment] = useState<string>('')
  const [monthlyPaymentErrors, setMonthlyPaymentErrors] = useState<boolean>(false)
  const [residentialStatus, setResidentialStatus] = useState('')

  const handleSubmit = () => {
    setIsSubmitting(true)

    if (!formError) {
      const quoteRequest: CreateQuoteAuthenticatedRequest = {
        rentMortgage: Number.parseInt(monthlyPayment),
        loanAmount: loanDetails.loanAmount,
        loanDuration: Number.parseInt(loanDetails.loanDuration),
        loanPurpose: loanDetails.loanPurpose,
        loanPurposeSpecified: loanDetails.purposeReason,
        employmentStatus: employmentStatus,
        timeInRole: Number.parseInt(timeInRole),
        income: Number.parseInt(monthlyIncome),
      }

      submitQuoteApplicationLogged(quoteRequest, setIsSubmitting, setApiError)
    }
  }

  const formError =
    Object.values(loanDetailsError).some((x) => x) ||
    Object.values(yourFinancesErrors).some((x) => x) ||
    monthlyPaymentErrors

  const refLoanDetail = useRef<null | HTMLDivElement>(null)
  const refAddressDetails = useRef<null | HTMLDivElement>(null)
  const refYourFinances = useRef<null | HTMLDivElement>(null)

  const handleFormSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault()

    if (!formError) {
      handleSubmit()
    } else {
      const isFalseRefLoanDetail = Object.values(loanDetailsError).every((value) => value === false)
      const isFalseRefYourFinances = Object.values(yourFinancesErrors).every((value) => value === false)

      if (!isFalseRefLoanDetail) {
        refLoanDetail.current?.scrollIntoView({ behavior: 'smooth' })
      } else if (monthlyPaymentErrors) {
        refAddressDetails.current?.scrollIntoView({ behavior: 'smooth' })
      } else if (!isFalseRefYourFinances) {
        refYourFinances.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  const formValidation = () => {
    setLoanDetailsError({
      loanAmount: loanDetails.loanAmount === 0,
      loanDuration: loanDetails.loanDuration === '' || isNaN(Number.parseInt(loanDetails.loanDuration)),
      loanPurpose: loanDetails.loanPurpose === '',
      purposeReason: loanDetails.loanPurpose === 'Other' && loanDetails.purposeReason === '',
    })

    setYourFinancesErrors({
      employmentStatus: employmentStatus === '',
      timeInRole: timeInRole === '' && !employmentStatusesWithoutTimeInRole.includes(employmentStatus),
      monthlyIncome: monthlyIncome === '' || isNaN(Number.parseInt(monthlyIncome)),
    })

    setMonthlyPaymentErrors(
      (Number.parseInt(monthlyPayment) === 0 || monthlyPayment === '' || isNaN(Number.parseInt(monthlyPayment))) &&
        ['Homeowner - With Mortgage', 'Tenant - Paying Rent'].includes(residentialStatus),
    )
  }

  const [employmentStatus, setEmploymentStatus] = useState('')
  const [monthlyIncome, setMonthlyIncome] = useState('')
  const [timeInRole, setTimeInRole] = useState('')

  const callbackLoanDetails = useCallback(
    (loanAmount: number, loanDuration: string, loanPurpose: string, purposeReason: string) => {
      setLoanDetails({
        loanAmount: loanAmount,
        loanDuration: loanDuration,
        loanPurpose: loanPurpose,
        purposeReason: purposeReason,
      })
    },
    [],
  )

  const monthlyPaymentCallback = useCallback((monthlyAmount: string) => {
    setMonthlyPayment(monthlyAmount)
  }, [])

  const residentialStatusCallback = useCallback((residentialStatus: string) => {
    setResidentialStatus(residentialStatus)
  }, [])

  const callbackMarketingCheckbox = useCallback((checked: boolean) => {
    return
  }, [])

  useEffect(() => {
    if (responseQuoteRequestAuthenticated?.quote.status === loanStatus.StatusCreated) {
      setShowQuoteDetails(true)
      window.scrollTo(0, 0)
    }
  }, [responseQuoteRequestAuthenticated?.quote])

  if (responseQuoteRequestAuthenticated?.quote.status === loanStatus.StatusDeclined) {
    return (
      <div className="loan-status-declined">
        <HeaderNavigationWithLogin logged={true} />
        <LayoutWrapper>
          <CssBaseline />
          <LoanApplicationDeclined />
        </LayoutWrapper>
      </div>
    )
  }

  return (
    <div className={`loan-status-apply${!showQuoteDetails ? ' quick-quote' : ''}`}>
      <HeaderNavigationWithLogin logged={true} isQuickQuote={!showQuoteDetails ? true : false} />

      <LayoutWrapper>
        <CssBaseline />
        <Grid container className="quote-container loggedIn-container" data-test="quote-container">
          {!showQuoteDetails ? (
            <>
              <Grid item xs={12} className="quote-container-header">
                <h1>Get a quick quote</h1>
                <div>This will take you 2 minutes and it won't affect your credit score.</div>
              </Grid>

              <Grid item xs={12}>
                <form onSubmit={handleFormSubmit}>
                  <div ref={refLoanDetail}></div>
                  <LoanDetail loanDetailsError={loanDetailsError} parentCallbackLoanDetails={callbackLoanDetails} />

                  <div ref={refAddressDetails}></div>
                  <AddressDetails
                    parentCallbackMonthlyPayment={monthlyPaymentCallback}
                    parentCallbackResidentialStatus={residentialStatusCallback}
                    errors={monthlyPaymentErrors}
                    setErrors={setMonthlyPaymentErrors}
                  />

                  <div ref={refYourFinances}></div>
                  <YourFinances
                    setEmploymentStatusCallback={setEmploymentStatus}
                    setMonthlyIncomeCallback={setMonthlyIncome}
                    setTimeInRoleCallback={setTimeInRole}
                    errors={yourFinancesErrors}
                    setErrors={setYourFinancesErrors}
                    step={3}
                  />
                  <TermsAndConditions
                    loggedIn={true}
                    step={4}
                    parentCallbackMarketingCheckbox={callbackMarketingCheckbox}
                  />
                  {apiError && (
                    <Box mt={3} mb={2}>
                      <FeedbackIndicatorsComponent
                        messages="An error occurred while submitting your quote. Please try again later."
                        className="feedback-indicator"
                        severity="error"
                        icon="lw-system-icons-close icon-bg-error"
                        closeAction={false}
                        isOpen={apiError}
                      ></FeedbackIndicatorsComponent>
                    </Box>
                  )}
                  <Grid item xs={12} className="get-my-quote-container">
                    <Box mt={6} mb={2}>
                      <Button
                        type="submit"
                        className="complete-application-submit-button"
                        data-test="button-complete-application-submit"
                        size="large"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={formValidation}
                      >
                        <>{isSubmitting ? <Loader size="small" color="white" /> : 'Get my quote'}</>
                      </Button>
                    </Box>
                    <Box mb={3}>
                      <div className="credit-score-container">
                        <span className="lw-system-icons-credit-search icons-fs-16-normal icons-colorBlack icon-bold" />
                        <span> This won't affect your credit score</span>
                      </div>
                    </Box>
                  </Grid>
                </form>
              </Grid>
            </>
          ) : (
            <div className="quoteDetails-container">
              <Grid item xs={12} className="application-steps">
                <Stepper step1="active" />
              </Grid>

              <Box mt={3}>
                <QuoteDetails
                  quote={quoteDetails?.quote}
                  message={responseQuoteRequestAuthenticated?.message}
                  reference={responseQuoteRequestAuthenticated?.quote.reference}
                  loggedIn={true}
                />
              </Box>

              <RightContentWrapper useCSEmail={true} />
            </div>
          )}
        </Grid>
      </LayoutWrapper>
    </div>
  )
}

export default LoggedIn
