import './_declined.scss'

import { Box, CssBaseline, Divider, Grid, Typography } from '@mui/material'

const Declined = (): JSX.Element => {
  return (
    <>
      <CssBaseline />
      <Grid container className="declined-container" data-test="declined-container">
        <Grid item xs={12}>
          <Box mb={6} className="Card-shadow Headers lines">
            <Typography variant="h3" className="Header-content top-shadow">
              Sorry, we are unable to approve your loan.
            </Typography>
            <Divider className="dashed-divider" />
            <Typography className="why-declined">Why was my application declined?</Typography>
            <Box className="reasons">
              <Typography className="cant-offer">
                Sorry we couldn&apos;t offer you a loan right now. For security reasons, we can&apos;t tell you exactly
                why but here are some reasons we consider when we make a decision:
              </Typography>
              <ul className="decline-reason-list">
                <li>You failed to meet our internal credit scoring</li>
                <li>
                  Other information we have received from{' '}
                  <a
                    target="_blank"
                    href="https://www.experian.co.uk/"
                    rel="noreferrer"
                    aria-label="declined - click Experian link"
                  >
                    Experian
                  </a>{' '}
                  - you may wish to check your credit report
                </li>
                <li>Your loan request is outside of our criteria</li>
                <li>
                  We have also searched fraud prevention databases including{' '}
                  <a
                    target="_blank"
                    href="https://www.cifas.org.uk/"
                    rel="noreferrer"
                    aria-label="declined - click CIFAS link"
                  >
                    CIFAS
                  </a>
                </li>
              </ul>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Declined
