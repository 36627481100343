import { version } from '../api/config'

interface CustomAttributes {
  version: string
}

declare global {
  interface Window {
    newrelic: { noticeError: (error: Error, customAttributes: CustomAttributes) => void }
    Cypress: object
  }
}

export function logInfo(value: string): void {
  console.log(value)
}

export function logError(
  message: string,
  data: {
    name?: string
    statusCode?: number
    config?: { headers?: { Authorization?: string } }
  },
): void {
  if (!window.Cypress) {
    if (data?.config?.headers?.Authorization) {
      delete data.config.headers.Authorization
    }

    const error = `${message} : ${JSON.stringify(data)}`
    const attributes = { version: version }

    if (window.newrelic) {
      window.newrelic.noticeError(new Error(error), attributes)
    } else {
      console.log(error)
    }
  }
}
