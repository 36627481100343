import '../../../../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'
import './_login.scss'

import * as paths from 'utils/Links'
import * as logging from 'utils/Logging'

import { OktaAuth, Tokens } from '@okta/okta-auth-js'
import { LegacyRef, useEffect, useRef } from 'react'

import { useOktaAuth } from '@okta/okta-react'
import { OnAuthRequiredFunction } from '@okta/okta-react/bundles/types/OktaContext'
import FluroLogo from 'assets/images/logo/fluro-logo.svg'
import { identifyHeapUser } from '../../../api/HeapConfig'
import { oktaConfiguration } from '../../../api/OktaConfig'

function logAndRedirectToLoginPage(cause: string): void {
  logging.logInfo(cause)
  paths.serverRedirect('/sign-in')
}

export function customAuthHandler(oktaAuth: OktaAuth): OnAuthRequiredFunction {
  return () => {
    oktaAuth.token.getWithoutPrompt().then(
      (value) => {
        oktaAuth.handleLoginRedirect(value.tokens).then(() => {
          oktaAuth.authStateManager.updateAuthState().then(
            (value) => {
              if (value.isAuthenticated) {
                identifyHeapUser(oktaAuth)
                logging.logInfo('authenticated')
              } else {
                logAndRedirectToLoginPage('sign in required')
              }
            },
            (error) => {
              logAndRedirectToLoginPage(error)
            },
          )
        })
      },
      (error) => {
        logAndRedirectToLoginPage(error.errorCode)
      },
    )
  }
}

export const Login = (): JSX.Element => {
  const { oktaAuth } = useOktaAuth()
  const widgetRef = useRef<LegacyRef<HTMLDivElement>>(null)

  useEffect(() => {
    const setupWidget = async () => {
      if (!widgetRef.current) {
        return
      }

      const { issuer, clientId, redirectUri, scopes, useInteractionCode, ignoreLifetime } = oktaConfiguration
      const referrerPath = window.sessionStorage.getItem('referrerPath') || ''
      // Setting a referrer path of /static causes a (nearly) infinite loop
      if (!referrerPath.includes('static')) {
        localStorage.setItem('referrerPath', referrerPath)
      }
      const widget = new (await import('@okta/okta-signin-widget')).default({
        /**
         * Note: when using the Sign-In Widget for an OIDC flow, it still
         * needs to be configured with the base URL for your Okta Org. Here
         * we derive it from the given issuer for convenience.
         */
        baseUrl: issuer.split('/oauth2')[0],
        clientId,
        redirectUri,
        originalUri: '/borrowing-centre',
        logo: FluroLogo,
        logoText: 'Fluro',
        i18n: {
          en: {
            'primaryauth.title': 'Log in to your account',
            'primaryauth.username.placeholder': 'Email',
            'primaryauth.submit': 'Log in',
            needhelp: 'Need help Log in?',
            forgotpassword: 'Forgot your password?',
            'errors.E0000004': 'Incorrect email or password',
            'error.auth.lockedOut':
              'Sorry, your account is temporarily locked. There have been too many failed login attempts. After 2 hours, you may wish to try again or reset your password.',
            'password.forgot.email.or.username.placeholder':
              'Enter the email address you used to create your Fluro account below and we will email you a password reset link.',
            'password.forgot.email.or.username.tooltip': 'Email',
            'password.forgot.title': 'Reset your password',
            'password.forgot.sendEmail': 'Reset password',
            'password.reset.title': 'Reset your password',
            'password.forgot.emailSent.title': 'Thanks, a password reset email has been sent to your inbox.',
            'password.forgot.emailSent.desc': `Didn't get the email on {0}? Please check your spam folder or return to login and try again.`,
            'password.forgot.code.notReceived': `Didn't receive a code? Reset via email`,
            goback: 'Return to log in',
            'error.username.required': 'Please enter your email address',
            'error.password.required': 'Please enter your password',
            'model.validation.field.blank': 'Please enter your email address',
            'model.validation.field.invalid.format.email': 'Please enter a valid email address',
            'errors.E0000095': 'Please enter a valid email address',
          },
        },
        features: {
          rememberMe: false, // Setting to false will remove the checkbox to save username
          registration: false,
          showPasswordToggleOnSignInPage: true,
          router: false,
          scrollOnError: true,
        },
        helpLinks: {
          forgotPassword: '/reset-password',
          custom: [
            {
              text: 'Not a member yet? Sign up',
              href: '/sign-up',
            },
          ],
        },
        authParams: {
          // To avoid redirect do not set "pkce" or "display" here. OKTA-335945
          issuer,
          scopes,
          ignoreLifetime,
        },
        useInteractionCodeFlow: useInteractionCode, // Set to true, if your org is OIE enabled
        useClassicEngine: true,
      })

      widget.renderEl(
        { el: widgetRef.current },
        (res: { tokens: Tokens | undefined }) => {
          oktaAuth.handleLoginRedirect(res.tokens).then(() => {
            identifyHeapUser(oktaAuth)
          })
        },
        (err: []) => {
          throw err
        },
      )

      // Note: Can't distinguish CORS error from other network errors
      const isCorsError = (err: { name: string; statusCode: number }) => err.name === 'AuthApiError' && !err.statusCode

      widget.on('afterError', (_context: [], error: { name: string; statusCode: number }) => {
        if (isCorsError(error)) {
          logging.logError('Login widget afterError isCorsError', error)
        }
      })

      return () => widget.remove()
    }
    setupWidget()
  }, [oktaAuth])

  return <div id="signInWidget" ref={widgetRef as LegacyRef<HTMLDivElement>} />
}
