import * as messages from 'utils/Messages'

import FeedbackIndicatorsModelComponent from 'components/FeedbackIndicators/FeedbackIndicatorsModel'
import { FileError } from 'react-dropzone'
import { IconButton } from '@mui/material'

export interface UploadErrorProps {
  file: File
  onDelete: (file: File) => void
  errors: FileError[]
}

export function UploadError({ file, onDelete, errors }: UploadErrorProps): JSX.Element {
  let message = ''
  if (errors.length > 1) {
    message =
      'The file you added is not an accepted format and is too large. Please add a file with a JPG, PNG or PDF format and a size smaller than 10MB.'
  } else if (errors[0].code === 'file-invalid-type') {
    message = 'The file you added is not an accepted format. Please add a file with a JPG, PNG or PDF format.'
  } else if (errors[0].code === 'file-too-large') {
    message = 'The file you added is too large. Please add a smaller file (less than 10MB).'
  }

  return (
    <>
      <div className="UploadErrorComponent" data-test="upload-error-component">
        <FeedbackIndicatorsModelComponent
          severity="error"
          className="MuiAlert-standardNone"
          icon="lw-system-icons-close icon-bg-error"
          title={messages.errorMessagesTitle}
          messages={message}
          closeAction={true}
          isOpen={true}
        />
        <IconButton
          className="IconButton-system-close IconButton-system-closeBackground-error"
          aria-label="close"
          color="inherit"
          onClick={() => onDelete(file)}
          size="large"
        >
          <span className="lw-system-icons-close-x icons-fs-16"></span>
        </IconButton>
      </div>
    </>
  )
}
