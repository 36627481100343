import '../assets/styles/global.scss'

import { createTheme } from '@mui/material/styles'
import { colours } from '../utils/Colour'
import { heading } from '../utils/Variable'

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      light: colours.jacarandaLight,
      main: colours.jacaranda,
      dark: colours.jacarandaDark,
    },
    secondary: {
      light: colours.azuriteLight,
      main: colours.azurite,
      dark: colours.azuriteDarker,
    },
    error: {
      light: colours.crimsonLight,
      main: colours.crimson,
      dark: colours.crimsonDark,
    },
    background: {
      default: colours.skyLighter,
    },
    text: {
      primary: colours.black,
      secondary: colours.skyDark,
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: ['"NoirNo1", "Helvetica Neue"', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h2: {
      fontSize: heading.h3.fontSize,
    },
    h3: {
      fontSize: heading.h3.fontSize,
      fontWeight: heading.h3.fontWeight,
      lineHeight: 'unset',
    },
    h4: {
      fontSize: heading.h4.fontSize,
    },
    h5: {
      fontSize: heading.h5.fontSize,
      fontWeight: heading.h5.fontWeight,
    },
    body1: {
      fontSize: 16,
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px',
          color: colours.white,
          backgroundColor: colours.blackOpacityWithZeroEightFive,
          width: '168px',
          padding: '10px 20px',
          display: 'block',
        },
        arrow: {
          color: colours.blackOpacityWithZeroEightFive,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            background: 'red',
          },
          color: colours.inkLight,
        },
      },
    },
  },
})

export default theme
