import styled from 'styled-components'
import { colours } from 'utils/Colour'

export const StyledMenu = styled.nav<{ open: boolean }>`
  top: 0;
  right: -1px;
  height: 100vh;
  width: 300px;
  position: fixed;
  background-color: ${colours.white};
  z-index: 1;

  display: flex;
  flex-direction: column;
  padding: 0;

  transition: transform 0.35s ease-in-out;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
`
