import './Logo.scss'

import logoWhite from 'assets/images/logo/fluro-logo-white.svg'
import logo from 'assets/images/logo/fluro-logo.svg'

type Props = {
  isWhite?: boolean
  origin?: string
}

const FluroLogo = ({ isWhite, origin = window.origin }: Props): JSX.Element => {
  const url = () => {
    return origin ? origin.replace('portal', 'www') : 'https://www.fluro.co.uk'
  }
  return (
    <a href={url()} title="Fluro homepage" data-test="logo-cta">
      {isWhite ? (
        <img
          src={logoWhite}
          className="fluro-logo"
          alt="Fluro Logo"
          data-test="fluro-logo"
          width="100%"
          height="100%"
        />
      ) : (
        <img src={logo} className="fluro-logo" alt="Fluro Logo" data-test="fluro-logo" width="100%" height="100%" />
      )}
    </a>
  )
}

export default FluroLogo
