import './Stepper.scss'

import { Divider } from '@mui/material'

interface StepperProps {
  step1: string
  step2?: string
  step3?: string
  step4?: string
  fullwidth?: boolean
}

const Stepper = ({ fullwidth, step1, step2, step3, step4 }: StepperProps): JSX.Element => {
  return (
    <div
      className={`stepper-container${fullwidth ? ' stepper-container-fullwidth' : ''}`}
      data-test="stepper-container"
    >
      <div className={`step-container ${step1 === 'active' && 'active'} ${step1 === 'completed' && 'completed'}`}>
        {step1 === 'completed' ? (
          <span className="lw-system-icons-checkmark-background step-icon">
            <span className="path1" />
            <span className="path2" />
          </span>
        ) : (
          <span className="step" />
        )}
        <span className="step-label">Your Quote</span>
        <Divider className="StepsLine" />
      </div>

      <div className={`step-container ${step2 === 'active' && 'active'} ${step2 === 'completed' && 'completed'}`}>
        {step2 === 'completed' ? (
          <span className="lw-system-icons-checkmark-background step-icon">
            <span className="path1" />
            <span className="path2" />
          </span>
        ) : (
          <span className="step" />
        )}
        <span className="step-label">Final Details</span>
        <Divider className="StepsLine" />
      </div>
      <div className={`step-container ${step3 === 'active' && 'active'} ${step3 === 'completed' && 'completed'}`}>
        {step3 === 'completed' ? (
          <span className="lw-system-icons-checkmark-background step-icon">
            <span className="path1" />
            <span className="path2" />
          </span>
        ) : (
          <span className="step" />
        )}
        <span className="step-label">Accept Loan</span>
        <Divider className="StepsLine" />
      </div>
      <div
        className={`step-container last-step ${step4 === 'active' && 'active'} ${step4 === 'completed' && 'completed'}`}
      >
        {step4 === 'completed' ? (
          <span className="lw-system-icons-checkmark-background step-icon">
            <span className="path1" />
            <span className="path2" />
          </span>
        ) : (
          <span className="step" />
        )}
        <span className="step-label">Review Docs</span>
      </div>
    </div>
  )
}

export default Stepper
