import './../_addresses.scss'

import * as helper from 'utils/HelperFunction'

import { Box, Grid, Typography } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'

import TextfieldCurrencyBaseline from 'components/Textfield/Baseline/TextfieldCurrencyBaseline'
import { List } from 'react-content-loader'
import { NumericFormat } from 'react-number-format'
import UseAccountApiRequest from '../../../../portal/accountDetails/UseAccountApiRequest'

type Props = {
  parentCallbackMonthlyPayment: (monthlyAmount: string) => void
  parentCallbackResidentialStatus: (residentialStatus: string) => void
  errors: boolean
  setErrors: (errors: boolean) => void
}

const Addresses = ({
  parentCallbackMonthlyPayment,
  parentCallbackResidentialStatus,
  errors,
  setErrors,
}: Props): JSX.Element => {
  const { accountDetails, fetchAccountDetails } = UseAccountApiRequest()
  const [residentialStatus, setResidentialStatus] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [hasLoaded, setHasLoaded] = useState(true)
  const [monthlyPayment, setMonthlyPayment] = useState<string>('')

  useEffect(() => {
    if (hasLoaded) {
      fetchAccountDetails(setLoading)
      setHasLoaded(false)
    }
  }, [hasLoaded, accountDetails, fetchAccountDetails])

  useEffect(() => {
    if (accountDetails?.residentialStatus) {
      setResidentialStatus(accountDetails?.residentialStatus)
      parentCallbackResidentialStatus(accountDetails?.residentialStatus)
    }
  }, [accountDetails, parentCallbackResidentialStatus])

  const handleAmountChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value
    setMonthlyPayment(value)
    parentCallbackMonthlyPayment(value)

    setErrors(Number.parseInt(value) === 0 || value === '')
  }

  if (loading) {
    return <List />
  }

  return (
    <Grid container className="addresses-container" data-test="addresses-container">
      <Grid item xs={12}>
        <Box>
          <Box mb={3} className="Card-shadow Headers lines first-address-container">
            <Grid item xs={12} className="Account-content line-separate">
              <Box>
                <Typography variant="h4">2. Address details</Typography>
              </Box>
            </Grid>

            <Box mt={3} mb={3} className="blocklevel address-details-container">
              <span className="subHeader">Current address</span>
              {helper.checkIsEmptyObject(accountDetails.address) && (
                <>
                  {helper.stringIsDefinedAndNotEmpty(accountDetails?.address?.line1) && (
                    <span className="paragraph">{accountDetails?.address?.line1}</span>
                  )}
                  {helper.stringIsDefinedAndNotEmpty(accountDetails?.address?.line2) && (
                    <span className="paragraph">{accountDetails?.address?.line2}</span>
                  )}
                  {helper.stringIsDefinedAndNotEmpty(accountDetails?.address?.line3) && (
                    <span className="paragraph">{accountDetails?.address?.line3}</span>
                  )}
                  {helper.stringIsDefinedAndNotEmpty(accountDetails?.address?.line4) && (
                    <span className="paragraph">{accountDetails?.address?.line4}</span>
                  )}
                  {helper.stringIsDefinedAndNotEmpty(accountDetails?.address?.postcode) && (
                    <span className="paragraph">{accountDetails?.address?.postcode}</span>
                  )}
                </>
              )}
            </Box>

            <Box mt={3} mb={3} className="blocklevel address-details-container">
              <span className="subHeader">Residential status</span>
              {helper.checkIsEmptyObject(accountDetails.address) && (
                <>
                  {helper.stringIsDefinedAndNotEmpty(residentialStatus) && (
                    <span className="paragraph">{residentialStatus}</span>
                  )}
                </>
              )}

              <Box mt={3} className="paperInstruction" style={{ display: 'flex' }}>
                <Box>
                  <span className="lw-marketing-icons-light-bulb-on icon-bg-notice icon-bold"></span>
                </Box>
                <Box data-test="paper-instruction">If your details above have changed, please contact us.</Box>
              </Box>

              {helper.stringIsDefinedAndNotEmpty(residentialStatus) && (
                <Box mt={3}>
                  {['Homeowner - With Mortgage', 'Tenant - Paying Rent'].includes(residentialStatus) && (
                    <Box mb={2} data-test="monthly-mortgage-or-rent-payment">
                      <NumericFormat
                        id="homeowner-with-mortgage"
                        decimalScale={2}
                        thousandSeparator={','}
                        decimalSeparator={'.'}
                        fullWidth
                        customInput={TextfieldCurrencyBaseline}
                        label={
                          residentialStatus === 'Homeowner - With Mortgage'
                            ? 'What is your monthly mortgage payment?'
                            : 'What is your monthly rent payment?'
                        }
                        value={monthlyPayment}
                        onChange={handleAmountChange}
                        error={errors}
                        helperText={errors ? 'Please enter your monthly mortgage/rent payment' : null}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Addresses
