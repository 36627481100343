import * as logging from 'utils/Logging'
import * as messagesArea from 'utils/Messages'
import * as variable from 'utils/Variable'

import { borrowerApiClient } from 'api/BorrowerApiClient'
import { Loan } from 'api/BorrowerApiSchemas'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface FetchLoanDataProps {
  loans: Loan[]
  loanError: string
  sideMenu: { loanCount: number; missedArrears: string; missedReaged: string }
  isLoading: boolean
  fetchLoanData: () => void
}

const useLoansApiRequest = (): FetchLoanDataProps => {
  const borrowerApi = borrowerApiClient()
  const [sideMenu, setSideMenu] = useState({ loanCount: 0, missedArrears: '', missedReaged: '' })

  const [loans, setLoans] = useState<Loan[]>([])
  const [isLoading, setLoading] = useState<boolean>(true)

  const [loanError, setLoanError] = useState<string>('')

  const sessReference = window.sessionStorage.getItem(variable.defaultRef)

  const navigate = useNavigate()

  const fetchLoanData = () => {
    borrowerApi
      .getLoans()
      .then(
        (loans) => {
          if (loans.length > 0) {
            let arrears = 0
            let reaged = 0

            for (let i = 0; i < loans.length; i++) {
              arrears += Number(loans[i].plan.missedArrearsTotal)
              reaged += Number(loans[i].plan.missedReAgedTotal)
            }

            setSideMenu({
              loanCount: loans.length,
              missedArrears: arrears !== 0 ? 'yes' : 'no',
              missedReaged: reaged !== 0 ? 'yes' : 'no',
            })

            setLoans(loans)

            // add the first reference into cookie
            const firstRecord = loans[0]
            const reference = firstRecord.plan.reference
            window.sessionStorage.setItem(variable.defaultRef, reference)
            window.sessionStorage.setItem(variable.loanAmount, loans[0].plan.amount.toString())

            // redirect to dashboard if the reference is not exist
            const countLoan = loans.length
            let isRefExists = false
            if (countLoan === 1 && loans[0].plan.reference === sessReference) {
              isRefExists = true
            } else if (countLoan > 1) {
              // check if the reference is exit or not for multiloan
              loans.some((loan) => {
                if (loan.plan.reference === sessReference) {
                  return (isRefExists = true)
                }

                return (isRefExists = false)
              })

              if (!isRefExists) {
                navigate('/borrowing-centre')
              }
            } else {
              navigate('/borrowing-centre')
            }
          }
        },
        (error) => {
          logging.logError('fetchLoanData', error)
          if (
            error.code === variable.httpStatusCodes.notFound ||
            error.code === variable.httpStatusCodes.unauthorized
          ) {
            setLoanError(messagesArea.errorMessagesLoan)
          } else {
            if (error && error.message) {
              setLoanError(error.message)
            }
          }
        },
      )
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    loans,
    loanError,
    sideMenu,
    isLoading,
    fetchLoanData,
  }
}

export default useLoansApiRequest
