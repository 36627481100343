import './Tooltip.scss'

import { TooltipProps } from './Tooltip.types'
import icon from 'assets/images/icons/icons-tooltip.svg'

const Tooltip = (props: TooltipProps): JSX.Element => {
  const tooltipPosition = props.position ? `arrow-bottom tooltip-${props.position}` : 'tooltip-bottom'
  return (
    <div className="tooltip" data-test="tooltip-container">
      <img src={icon} alt="icon" className="tooltips-postion" />
      <span className={`tooltiptext ${tooltipPosition}`}>{props.title}</span>
    </div>
  )
}

export default Tooltip
