import { Feature } from '../api/BorrowerApiSchemas'

export const checkIsLoadingFunction = (func: (loading: boolean) => void): boolean => typeof func === 'function'

export const checkIsEmptyObject = (obj: unknown | null): boolean => {
  if (!obj) return false
  return Object.keys(obj).length !== 0 && obj.constructor === Object
}

export const stringIsDefinedAndNotEmpty = (str: string | null | undefined): boolean =>
  str !== null && str !== undefined && str.trim() !== ''

export const stringIsNotDefinedOrEmpty = (str: string | null | undefined): boolean => !stringIsDefinedAndNotEmpty(str)

export const setCookie = (name: string, value: string, days?: number) => {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = `; expires= ${date.toUTCString()}`
  }
  return (document.cookie = `${name} = ${value} ${expires}; path=/`)
}

export const setFeatureCookies = (features?: Feature[]) => {
  features?.forEach((feature: Feature) => {
    setCookie(feature.name, String(feature.enabled), 1)
  })
}

export const isFeatureEnabled = (featureName: string) => {
  return window.sessionStorage.getItem(featureName) !== null
    ? window.sessionStorage.getItem(featureName) === 'true'
    : getCookie(featureName) === 'true'
}

export const getCookie = (str: string) => {
  const nameEQ = str + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export const eraseCookie = (str: string) => {
  document.cookie = str + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}
